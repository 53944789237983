import axios from 'axios'
import {
	CANDIDAT_DATA_REQUEST,
	CANDIDAT_DATA_SUCCESS,
	CANDIDAT_DATA_FAIL,
	CANDIDAT_ADD_PROFILE_PHOTO_REQUEST,
	CANDIDAT_ADD_PROFILE_PHOTO_SUCCESS,
	CANDIDAT_ADD_PROFILE_PHOTO_FAIL,
	CANDIDAT_DELETE_PROFILE_PHOTO_REQUEST,
	CANDIDAT_DELETE_PROFILE_PHOTO_SUCCESS,
	CANDIDAT_DELETE_PROFILE_PHOTO_FAIL,
	CANDIDAT_ADD_ADRESSE_REQUEST,
	CANDIDAT_ADD_ADRESSE_SUCCESS,
	CANDIDAT_ADD_ADRESSE_FAIL,
	CANDIDAT_ADD_TITRE_REQUEST,
	CANDIDAT_ADD_TITRE_SUCCESS,
	CANDIDAT_ADD_TITRE_FAIL,
	CANDIDAT_UPDATE_TITRE_REQUEST,
	CANDIDAT_UPDATE_TITRE_SUCCESS,
	CANDIDAT_UPDATE_TITRE_FAIL,
	CANDIDAT_DELETE_TITRE_REQUEST,
	CANDIDAT_DELETE_TITRE_SUCCESS,
	CANDIDAT_DELETE_TITRE_FAIL,
	CANDIDAT_ADD_COMPETENCES_REQUEST,
	CANDIDAT_ADD_COMPETENCES_SUCCESS,
	CANDIDAT_ADD_COMPETENCES_FAIL,
	CANDIDAT_DELETE_COMPETENCES_REQUEST,
	CANDIDAT_DELETE_COMPETENCES_SUCCESS,
	CANDIDAT_DELETE_COMPETENCES_FAIL,
	CANDIDAT_ADD_DIPLOME_REQUEST,
	CANDIDAT_ADD_DIPLOME_SUCCESS,
	CANDIDAT_ADD_DIPLOME_FAIL,
	CANDIDAT_UPDATE_DIPLOME_REQUEST,
	CANDIDAT_UPDATE_DIPLOME_SUCCESS,
	CANDIDAT_UPDATE_DIPLOME_FAIL,
	CANDIDAT_DELETE_DIPLOME_REQUEST,
	CANDIDAT_DELETE_DIPLOME_SUCCESS,
	CANDIDAT_DELETE_DIPLOME_FAIL,
	CANDIDAT_ADD_STAGE_REQUEST,
	CANDIDAT_ADD_STAGE_SUCCESS,
	CANDIDAT_ADD_STAGE_FAIL,
	CANDIDAT_UPDATE_STAGE_REQUEST,
	CANDIDAT_UPDATE_STAGE_SUCCESS,
	CANDIDAT_UPDATE_STAGE_FAIL,
	CANDIDAT_DELETE_STAGE_REQUEST,
	CANDIDAT_DELETE_STAGE_SUCCESS,
	CANDIDAT_DELETE_STAGE_FAIL,
	CANDIDAT_ADD_EXPERIENCE_REQUEST,
	CANDIDAT_ADD_EXPERIENCE_SUCCESS,
	CANDIDAT_ADD_EXPERIENCE_FAIL,
	CANDIDAT_UPDATE_EXPERIENCE_REQUEST,
	CANDIDAT_UPDATE_EXPERIENCE_SUCCESS,
	CANDIDAT_UPDATE_EXPERIENCE_FAIL,
	CANDIDAT_DELETE_EXPERIENCE_REQUEST,
	CANDIDAT_DELETE_EXPERIENCE_SUCCESS,
	CANDIDAT_DELETE_EXPERIENCE_FAIL,
	CANDIDAT_ADD_LANGUE_REQUEST,
	CANDIDAT_ADD_LANGUE_SUCCESS,
	CANDIDAT_ADD_LANGUE_FAIL,
	CANDIDAT_UPDATE_LANGUE_REQUEST,
	CANDIDAT_UPDATE_LANGUE_SUCCESS,
	CANDIDAT_UPDATE_LANGUE_FAIL,
	CANDIDAT_DELETE_LANGUE_REQUEST,
	CANDIDAT_DELETE_LANGUE_SUCCESS,
	CANDIDAT_DELETE_LANGUE_FAIL,
	CANDIDAT_GET_ALL_REQUEST,
	CANDIDAT_GET_ALL_SUCCESS,
	CANDIDAT_GET_ALL_FAIL,
	CANDIDAT_SAVE_EMPLOI_GET_ALL_REQUEST,
	CANDIDAT_SAVE_EMPLOI_GET_ALL_SUCCESS,
	CANDIDAT_SAVE_EMPLOI_GET_ALL_FAIL,
	CANDIDAT_SAVE_EMPLOI_REQUEST,
	CANDIDAT_SAVE_EMPLOI_SUCCESS,
	CANDIDAT_SAVE_EMPLOI_FAIL,
	CANDIDAT_CANDIDATURES_GET_ALL_REQUEST,
	CANDIDAT_CANDIDATURES_GET_ALL_SUCCESS,
	CANDIDAT_CANDIDATURES_GET_ALL_FAIL,
	CANDIDAT_DELETE_REQUEST,
	CANDIDAT_DELETE_SUCCESS,
	CANDIDAT_DELETE_FAIL,
} from '../constants/candidatConstants'

export const getCandidatDataAction =
	(candidatId) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_DATA_REQUEST })
		try {
			// const {
			// 	user: { token },
			// } = getState().auth

			const { data } = await axios.get(
				`/candidats/${candidatId}/data`
				// {
				// 	headers: {
				// 		Authorization: `Bearer ${token}`,
				// 	},
				// }
			)
			dispatch({
				type: CANDIDAT_DATA_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_DATA_FAIL,
				payload: message,
			})
		}
	}

// Profile photo
export const addCandidatProfilePhotoAction =
	(candidatId, profilePhoto) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_ADD_PROFILE_PHOTO_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.post(
				`/candidats/${candidatId}/profile-photo`,
				profilePhoto,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_ADD_PROFILE_PHOTO_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_ADD_PROFILE_PHOTO_FAIL,
				payload: message,
			})
		}
	}

export const deleteCandidatProfilePhotoAction =
	(candidatId) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_DELETE_PROFILE_PHOTO_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.delete(
				`/candidats/${candidatId}/profile-photo`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_DELETE_PROFILE_PHOTO_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_DELETE_PROFILE_PHOTO_FAIL,
				payload: message,
			})
		}
	}

// Adresse
export const addCandidatAdresseAction =
	(candidatId, adresse) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_ADD_ADRESSE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.put(
				`/candidats/${candidatId}/address`,
				adresse,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_ADD_ADRESSE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_ADD_ADRESSE_FAIL,
				payload: message,
			})
		}
	}

// Titre
export const addCandidatTitreAction =
	(candidatId, titre, description) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_ADD_TITRE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.post(
				`/candidats/${candidatId}/jobtitle`,
				{ titre, description },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_ADD_TITRE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_ADD_TITRE_FAIL,
				payload: message,
			})
		}
	}

export const updateCandidatTitreAction =
	(candidatId, titre, description) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_UPDATE_TITRE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.put(
				`/candidats/${candidatId}/jobtitle`,
				{ titre, description },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_UPDATE_TITRE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_UPDATE_TITRE_FAIL,
				payload: message,
			})
		}
	}

export const deleteCandidatTitreAction =
	(candidatId) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_DELETE_TITRE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.delete(`/candidats/${candidatId}/jobtitle`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			dispatch({
				type: CANDIDAT_DELETE_TITRE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_DELETE_TITRE_FAIL,
				payload: message,
			})
		}
	}

// Competences
export const addCandidatCompetencesAction =
	(candidatId, competences) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_ADD_COMPETENCES_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.put(
				`/candidats/${candidatId}/skills`,
				{ competences },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_ADD_COMPETENCES_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_ADD_COMPETENCES_FAIL,
				payload: message,
			})
		}
	}

export const deleteCandidatCompetencesAction =
	(candidatId, competenceId) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_DELETE_COMPETENCES_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.delete(
				`/candidats/${candidatId}/skills/${competenceId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_DELETE_COMPETENCES_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_DELETE_COMPETENCES_FAIL,
				payload: message,
			})
		}
	}

// Diplomes
export const addCandidatDiplomeAction =
	(candidatId, diplome) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_ADD_DIPLOME_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.post(
				`/candidats/${candidatId}/diplome`,
				diplome,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_ADD_DIPLOME_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_ADD_DIPLOME_FAIL,
				payload: message,
			})
		}
	}

export const updateCandidatDiplomeAction =
	(candidatId, diplomeId, diplome) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_UPDATE_DIPLOME_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth
			const { data } = await axios.put(
				`/candidats/${candidatId}/diplome/${diplomeId}`,
				diplome,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_UPDATE_DIPLOME_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_UPDATE_DIPLOME_FAIL,
				payload: message,
			})
		}
	}

export const deleteCandidatDiplomeAction =
	(candidatId, diplomeId) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_DELETE_DIPLOME_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.delete(
				`/candidats/${candidatId}/diplome/${diplomeId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_DELETE_DIPLOME_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_DELETE_DIPLOME_FAIL,
				payload: message,
			})
		}
	}

// Stages
export const addCandidatStageAction =
	(candidatId, stage) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_ADD_STAGE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.post(
				`/candidats/${candidatId}/stage`,
				stage,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_ADD_STAGE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_ADD_STAGE_FAIL,
				payload: message,
			})
		}
	}

export const updateCandidatStageAction =
	(candidatId, stageId, stage) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_UPDATE_STAGE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth
			const { data } = await axios.put(
				`/candidats/${candidatId}/stage/${stageId}`,
				stage,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_UPDATE_STAGE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_UPDATE_STAGE_FAIL,
				payload: message,
			})
		}
	}

export const deleteCandidatStageAction =
	(candidatId, stageId) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_DELETE_STAGE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.delete(
				`/candidats/${candidatId}/stage/${stageId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_DELETE_STAGE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_DELETE_STAGE_FAIL,
				payload: message,
			})
		}
	}

// Experiences
export const addCandidatExperienceAction =
	(candidatId, experience) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_ADD_EXPERIENCE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.post(
				`/candidats/${candidatId}/experience`,
				experience,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_ADD_EXPERIENCE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_ADD_EXPERIENCE_FAIL,
				payload: message,
			})
		}
	}

export const updateCandidatExperienceAction =
	(candidatId, experienceId, experience) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_UPDATE_EXPERIENCE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth
			const { data } = await axios.put(
				`/candidats/${candidatId}/experience/${experienceId}`,
				experience,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_UPDATE_EXPERIENCE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_UPDATE_EXPERIENCE_FAIL,
				payload: message,
			})
		}
	}

export const deleteCandidatExperienceAction =
	(candidatId, experienceId) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_DELETE_EXPERIENCE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.delete(
				`/candidats/${candidatId}/experience/${experienceId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_DELETE_EXPERIENCE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_DELETE_EXPERIENCE_FAIL,
				payload: message,
			})
		}
	}

// Langues
export const addCandidatLangueAction =
	(candidatId, langue) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_ADD_LANGUE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.post(
				`/candidats/${candidatId}/langue`,
				langue,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_ADD_LANGUE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_ADD_LANGUE_FAIL,
				payload: message,
			})
		}
	}

export const updateCandidatLangueAction =
	(candidatId, langueId, langue) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_UPDATE_LANGUE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth
			const { data } = await axios.put(
				`/candidats/${candidatId}/langue/${langueId}`,
				langue,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_UPDATE_LANGUE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_UPDATE_LANGUE_FAIL,
				payload: message,
			})
		}
	}

export const deleteCandidatLangueAction =
	(candidatId, langueId) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_DELETE_LANGUE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.delete(
				`/candidats/${candidatId}/langue/${langueId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_DELETE_LANGUE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_DELETE_LANGUE_FAIL,
				payload: message,
			})
		}
	}

export const getCandidatAllAction =
	(page = '', name = '') =>
	async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_GET_ALL_REQUEST })
		try {
			// const {
			// 	user: { token },
			// } = getState().auth

			const url = name
				? `/candidats?page=${page}&name=${name}`
				: `/candidats?page=${page}`

			const { data } = await axios.get(
				url
				// 	{
				// 	headers: {
				// 		Authorization: `Bearer ${token}`,
				// 	},
				// }
			)
			dispatch({
				type: CANDIDAT_GET_ALL_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_GET_ALL_FAIL,
				payload: message,
			})
		}
	}

export const candidatGetSavedEmploiAction =
	(candidatId) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_SAVE_EMPLOI_GET_ALL_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.get(`/candidats/${candidatId}/emplois`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			dispatch({
				type: CANDIDAT_SAVE_EMPLOI_GET_ALL_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_SAVE_EMPLOI_GET_ALL_FAIL,
				payload: message,
			})
		}
	}

export const candidatSaveEmploiAction =
	(candidatId, emploiId, emploiTitre) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_SAVE_EMPLOI_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.post(
				`/candidats/${candidatId}/emplois/${emploiId}/save`,
				{ emploi_titre: emploiTitre },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: CANDIDAT_SAVE_EMPLOI_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_SAVE_EMPLOI_FAIL,
				payload: message,
			})
		}
	}

export const candidatCandidaturesAction =
	(candidatId) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_CANDIDATURES_GET_ALL_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.get(`/candidats/${candidatId}/candidature`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			dispatch({
				type: CANDIDAT_CANDIDATURES_GET_ALL_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_CANDIDATURES_GET_ALL_FAIL,
				payload: message,
			})
		}
	}

export const deleteCandidatAction =
	(candidatId) => async (dispatch, getState) => {
		dispatch({ type: CANDIDAT_DELETE_REQUEST })
		try {
			const {
				admin: { token },
			} = getState().admin

			const { data } = await axios.delete(`/candidats/${candidatId}/account`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			dispatch({
				type: CANDIDAT_DELETE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_DELETE_FAIL,
				payload: message,
			})
		}
	}
