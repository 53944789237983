import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Container } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import io from 'socket.io-client'

import Discussions from '../components/Discussions'
import Messages from '../components/Messages'
import Loading from '../components/Loading'
import { DISCUSSION_RESET } from '../constants/discussionConstants'
import { MESSAGE_RESET } from '../constants/messageConstants'
import {
	getMessagesAction2,
	sendMessageAction2,
	addMessageAction,
} from '../actions/messageActions'
import {
	accessDiscussionAction2,
	getDiscussionsAction,
} from '../actions/discussionActions'
import { removeNotificationMessageAction } from '../actions/notificationActions'

// const ENDPOINT = 'http://localhost:5000'
const ENDPOINT = 'https://fine-blue-dove-tux.cyclic.app'
let socket

function DiscussionPage() {
	const dispatch = useDispatch()

	const { discussionId } = useParams()

	const [text, setText] = useState('')
	const [socketConnected, setSocketConnected] = useState(false)
	const [typing, setTyping] = useState(false)
	const [isTyping, setIsTyping] = useState(false)

	const {
		loading,
		success: successDiscussion,
		error: errorDiscussion,
		message: messageDiscussion,
	} = useSelector((state) => state.discussion)
	const {
		success: successMessage,
		error: errorMessage,
		message: messageMessage,
	} = useSelector((state) => state.message)

	const { user } = useSelector((state) => state.auth)
	const { discussion } = useSelector((state) => state.discussion)
	const { notifications_messages } = useSelector((state) => state.notification)

	useEffect(() => {
		socket = io(ENDPOINT)
		socket.emit('setup', user)
		socket.on('connected', () => setSocketConnected(true))
		socket.on('typing', () => setIsTyping(true))
		socket.on('stop typing', () => setIsTyping(false))
	}, [user])

	useEffect(() => {
		dispatch(getDiscussionsAction())
		if (discussionId !== undefined) {
			dispatch(accessDiscussionAction2(discussionId))
			dispatch(getMessagesAction2(discussionId))
		}
		socket.emit('join discussion', discussionId)
	}, [dispatch, discussionId, user._id])

	useEffect(() => {
		if (user) {
			notifications_messages.length > 0 &&
				notifications_messages.forEach((nm) => {
					if (nm.discussion === discussion._id) {
						dispatch(removeNotificationMessageAction(user._id, nm._id))
					}
				})
		}
	}, [dispatch, notifications_messages, discussion, user])

	const onChange = (e) => {
		setText(e)
		if (!socketConnected) return
		if (!typing) {
			setTyping(true)
			socket.emit('typing', discussionId)
		}
		let lastTypingTime = new Date().getTime()
		let timerLength = 3000
		setTimeout(() => {
			let timeNow = new Date().getTime()
			let timeDiff = timeNow - lastTypingTime

			if (timeDiff >= timerLength && typing) {
				socket.emit('stop typing', discussionId)
				setTyping(false)
			}
		}, timerLength)
	}

	const onSubmit = (e) => {
		e.preventDefault()
		if (text) {
			socket.emit('stop typing', discussionId)
			setText('')
			dispatch(sendMessageAction2(discussionId, text, user, socket))
		}
	}

	useEffect(() => {
		socket.on('message recieved', (newMessageRecieved) => {
			if (discussionId === newMessageRecieved?.discussion?._id) {
				dispatch(addMessageAction(newMessageRecieved))
			}
		})
	}, [dispatch, discussionId])

	useEffect(() => {
		if (errorDiscussion && messageDiscussion) {
			toast.error(messageDiscussion)
		}
		if (successDiscussion && messageDiscussion) {
			toast.success(messageDiscussion)
		}
		if (successDiscussion || errorDiscussion) {
			dispatch({ type: DISCUSSION_RESET })
		}
		if (errorMessage && messageMessage) {
			toast.error(messageMessage)
		}
		if (successMessage && messageMessage) {
			toast.success(messageMessage)
		}
		if (successMessage || errorMessage) {
			dispatch({ type: MESSAGE_RESET })
		}
	}, [
		dispatch,
		successDiscussion,
		errorDiscussion,
		messageDiscussion,
		successMessage,
		errorMessage,
		messageMessage,
	])
	const getWidth = () => {
		return window.innerWidth
	}
	const [width, setWidth] = useState(getWidth())
	useEffect(() => {
		function handleResize() {
			setWidth(getWidth())
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	if (loading) {
		return <Loading />
	}

	return (
		<Container style={{ color: '#000' }}>
			{width <= 768 && discussionId !== undefined ? (
				<div className='row my-3 discussion-page' style={{ height: '75vh' }}>
					<Messages
						discussion={discussion}
						text={text}
						setText={setText}
						onChange={onChange}
						onSubmit={onSubmit}
						isTyping={isTyping}
					/>
				</div>
			) : width <= 768 && discussionId === undefined ? (
				<div className='row my-3 discussion-page' style={{ height: '75vh' }}>
					<Discussions discussion={discussion} discussionId={discussionId} />
				</div>
			) : width > 768 ? (
				<div className='row my-3 discussion-page' style={{ height: '75vh' }}>
					<Discussions discussion={discussion} discussionId={discussionId} />
					<Messages
						discussion={discussion}
						text={text}
						setText={setText}
						onChange={onChange}
						onSubmit={onSubmit}
						isTyping={isTyping}
					/>
				</div>
			) : (
				''
			)}
		</Container>
	)
}

export default DiscussionPage
