import { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import io from 'socket.io-client'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/Header'
import Footer from './components/Footer'
import PrivateRoute from './components/PrivateRoute'
import AdminRoute from './components/AdminRoute'
import HomePage from './pages/HomePage'
import RegisterCandidatPage from './pages/RegisterCandidatPage'
import RegisterRecruteurPage from './pages/RegisterRecruteurPage'
import SigninPage from './pages/SigninPage'
import RegisterPage from './pages/RegisterPage'
import Dashboard from './pages/Dashboard'
import CandidatsPage from './pages/CandidatsPage'
import RecruteursPage from './pages/RecruteursPage'
import CandidatProfilePage from './pages/CandidatProfilePage'
import RecruteurProfilePage from './pages/RecruteurProfilePage'
import AccountPage from './pages/AccountPage'
import EmploiPage from './pages/EmploiPage'
import EmploisSavedPage from './pages/EmploisSavedPage'
import CandidaturesPage from './pages/CandidaturesPage'
import DiscussionPage from './pages/DiscussionPage'
import ContactPage from './pages/ContactPage'
import AdminSigninPage from './pages/AdminSigninPage'
import AdminCandidatsPage from './pages/AdminCandidatsPage'
import AdminRecruteursPage from './pages/AdminRecruteursPage'
import AdminEmploisPage from './pages/AdminEmploisPage'
import AdminCandidatPage from './pages/AdminCandidatPage'
import AdminRecruteurPage from './pages/AdminRecruteurPage'
import AdminEmploiPage from './pages/AdminEmploiPage'
import AdminDashboardPage from './pages/AdminDashboardPage'
import NoMatchPage from './pages/NoMatchPage'

import {
	addNotificationMessageToStoreAction,
	addNotificationToStoreAction,
} from './actions/notificationActions'

// const ENDPOINT = 'http://localhost:5000'
const ENDPOINT = 'https://fine-blue-dove-tux.cyclic.app'
let socket

function App() {
	const { user } = useSelector((state) => state.auth)

	const dispatch = useDispatch()

	useEffect(() => {
		socket = io(ENDPOINT)
		socket.emit('setup', user)
		socket.on('connected', () => {})
	}, [user])

	useEffect(() => {
		socket.on('message notification recieved', (notification, u) => {
			if (notification && u !== user._id) {
				dispatch(addNotificationMessageToStoreAction(notification))
			}
		})
	}, [dispatch, user])

	useEffect(() => {
		socket.on('candidature recieved', (notification, u) => {
			if (notification && u !== user._id) {
				dispatch(addNotificationToStoreAction(notification))
			}
		})
	}, [dispatch, user])

	useEffect(() => {
		socket.on('accepter candidature recieved', (notification, u) => {
			if (notification && u !== user._id) {
				dispatch(addNotificationToStoreAction(notification))
			}
		})
	}, [dispatch, user])

	useEffect(() => {
		socket.on('refuser candidature recieved', (notification, u) => {
			if (notification && u !== user._id) {
				dispatch(addNotificationToStoreAction(notification))
			}
		})
	}, [dispatch, user])

	// useEffect(() => {
	// 	socket.on('emploi delete recieved', (notification) => {
	// 		if (notification) {
	// 			dispatch(addNotificationToStoreAction(notification))
	// 		}
	// 	})
	// }, [dispatch])

	return (
		<>
			<Router>
				<Header />

				<Routes>
					<Route path='/' exact element={<HomePage />} />
					<Route path='/signin' element={<SigninPage />} />
					<Route path='/register' exact element={<RegisterPage />} />
					<Route path='/register/candidat' element={<RegisterCandidatPage />} />
					<Route
						path='/register/recruteur'
						element={<RegisterRecruteurPage />}
					/>
					<Route
						path='/dashboard'
						exact
						element={
							// <PrivateRoute>
							<Dashboard />
							// </PrivateRoute>
						}
					/>
					<Route
						path='/dashboard/page/:page'
						exact
						element={
							// <PrivateRoute>
							<Dashboard />
							// </PrivateRoute>
						}
					/>
					<Route
						path='/dashboard/name/:name'
						exact
						element={
							// <PrivateRoute>
							<Dashboard />
							// </PrivateRoute>
						}
					/>
					<Route
						path='/dashboard/page/:page/name/:name'
						exact
						element={
							// <PrivateRoute>
							<Dashboard />
							// </PrivateRoute>
						}
					/>
					<Route
						path='/dashboard/candidats'
						exact
						element={
							<PrivateRoute>
								<CandidatsPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/dashboard/candidats/page/:page'
						exact
						element={
							<PrivateRoute>
								<CandidatsPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/dashboard/candidats/name/:name'
						exact
						element={
							<PrivateRoute>
								<CandidatsPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/dashboard/candidats/page/:page/name/:name'
						exact
						element={
							<PrivateRoute>
								<CandidatsPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/dashboard/recruteurs'
						exact
						element={
							<PrivateRoute>
								<RecruteursPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/dashboard/recruteurs/page/:page'
						exact
						element={
							<PrivateRoute>
								<RecruteursPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/dashboard/recruteurs/name/:name'
						exact
						element={
							<PrivateRoute>
								<RecruteursPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/dashboard/recruteurs/page/:page/name/:name'
						exact
						element={
							<PrivateRoute>
								<RecruteursPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/candidat/:candidatId'
						element={
							// <PrivateRoute>
							<CandidatProfilePage />
							// </PrivateRoute>
						}
					/>
					<Route
						path='/recruteur/:recruteurId'
						element={
							// <PrivateRoute>
							<RecruteurProfilePage />
							// </PrivateRoute>
						}
					/>
					<Route
						path='/user/:userId'
						exact
						element={
							<PrivateRoute>
								<AccountPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/emploi/:emploiId'
						element={
							// <PrivateRoute>
							<EmploiPage />
							// </PrivateRoute>
						}
					/>
					<Route
						path='/candidat/:candidatId/emplois'
						element={
							<PrivateRoute>
								<EmploisSavedPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/candidat/:candidatId/candidature'
						element={
							<PrivateRoute>
								<CandidaturesPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/user/:userId/discussions'
						exact
						element={
							<PrivateRoute>
								<DiscussionPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/user/:userId/discussions/:discussionId'
						exact
						element={
							<PrivateRoute>
								<DiscussionPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/contact/candidat/:userId/discussions'
						exact
						element={
							<PrivateRoute>
								<ContactPage />
							</PrivateRoute>
						}
					/>
					<Route
						path='/contact/recruteur/:userId/discussions'
						exact
						element={
							<PrivateRoute>
								<ContactPage />
							</PrivateRoute>
						}
					/>
					<Route path='/admin' exact element={<AdminSigninPage />} />
					<Route
						path='/admin-dashboard'
						exact
						element={
							<AdminRoute>
								<AdminDashboardPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/candidats'
						exact
						element={
							<AdminRoute>
								<AdminCandidatsPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/candidats/page/:page'
						exact
						element={
							<AdminRoute>
								<AdminCandidatsPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/candidats/name/:name'
						exact
						element={
							<AdminRoute>
								<AdminCandidatsPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/candidats/page/:page/name/:name'
						exact
						element={
							<AdminRoute>
								<AdminCandidatsPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/recruteurs'
						exact
						element={
							<AdminRoute>
								<AdminRecruteursPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/recruteurs/page/:page'
						exact
						element={
							<AdminRoute>
								<AdminRecruteursPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/recruteurs/name/:name'
						exact
						element={
							<AdminRoute>
								<AdminRecruteursPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/recruteurs/page/:page/name/:name'
						exact
						element={
							<AdminRoute>
								<AdminRecruteursPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/emplois'
						exact
						element={
							<AdminRoute>
								<AdminEmploisPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/emplois/page/:page'
						exact
						element={
							<AdminRoute>
								<AdminEmploisPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/emplois/name/:name'
						exact
						element={
							<AdminRoute>
								<AdminEmploisPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/emplois/page/:page/name/:name'
						exact
						element={
							<AdminRoute>
								<AdminEmploisPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/candidats/:candidatId'
						exact
						element={
							<AdminRoute>
								<AdminCandidatPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/recruteurs/:recruteurId'
						exact
						element={
							<AdminRoute>
								<AdminRecruteurPage />
							</AdminRoute>
						}
					/>
					<Route
						path='/admin-dashboard/emplois/:emploiId'
						exact
						element={
							<AdminRoute>
								<AdminEmploiPage />
							</AdminRoute>
						}
					/>
					<Route path='*' element={<NoMatchPage />} />
				</Routes>

				<Footer />
			</Router>
			<ToastContainer />
		</>
	)
}

export default App
