import { useEffect, useCallback } from 'react'
import {
	Form,
	Button,
	InputGroup,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap'
import { IoMdSend } from 'react-icons/io'
import { BiArrowBack } from 'react-icons/bi'
import Lottie from 'react-lottie'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/fr'

import { resetSelectedDiscussion } from '../actions/discussionActions'
import { getReciever } from '../utils/discussion'
import VideoCall from '../modals/VideoCall'
import messaging from '../svgs/messaging.svg'
import messaging2 from '../svgs/messaging2.svg'
import messaging3 from '../svgs/messaging3.svg'
import animationData from '../animations/typing.json'

function Messages({ discussion, text, onChange, onSubmit, isTyping }) {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { user } = useSelector((state) => state.auth)
	const { messages } = useSelector((state) => state.message)

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	}

	const setRef = useCallback((node) => {
		if (node) {
			node.scrollIntoView({ smooth: true })
		}
	}, [])

	useEffect(() => {
		setRef()
	}, [setRef])

	return (
		<div className='col-md-8'>
			{Object.keys(discussion).length > 0 ? (
				<>
					{/* <div className='d-flex justify-content-between align-items-center bg-light'> */}
					<div className='d-flex justify-content-start align-items-center border-top border-start border-end p-2 bg-light'>
						<Button
							variant='secondary'
							className='me-2'
							onClick={() => {
								dispatch(resetSelectedDiscussion())
								navigate(`/user/${user._id}/discussions`)
							}}
						>
							<BiArrowBack />
						</Button>
						<img
							src={getReciever(user, discussion?.users).profilePhoto}
							alt={`${getReciever(user, discussion?.users).prenom} ${
								getReciever(user, discussion?.users).nom
							}`}
							className='photo me-2'
							style={{ width: '50px', height: '50px' }}
						/>
						<Link
							to={`/${getReciever(user, discussion?.users).__t.toLowerCase()}/${
								getReciever(user, discussion?.users)._id
							}`}
							className='link'
						>
							<h6>{`${getReciever(user, discussion?.users).prenom} ${
								getReciever(user, discussion?.users).nom
							}`}</h6>
						</Link>
					</div>
					{/* <div className='me-2'>
						<VideoCall
							caller={user}
							callReciever={getReciever(user, discussion?.users)}
						/>
					</div> */}
					{/* </div> */}
					<div
						className='d-flex flex-column justify-content-end border bg-dark'
						style={{
							height: '55vh',
							//  backgroundColor: '#d9e2ef'
						}}
					>
						<div className='overflow-auto p-3'>
							<div className='d-flex flex-column'>
								{messages.length > 0 &&
									messages.map((message, index) => (
										<div
											className={`my-1 d-flex flex-column w-75 ${
												message.sender._id === user._id
													? 'align-self-end align-items-end'
													: 'align-items-start'
											}`}
											ref={messages.length - 1 === index ? setRef : null}
											key={message._id}
										>
											{message.sender._id === user._id ? (
												<OverlayTrigger
													key='top'
													placement='top'
													overlay={
														<Tooltip id='tooltip-top'>
															{`${moment(message.createdAt)
																.locale('fr')
																.format('LL')} ${moment(
																message.createdAt
															).format('LT')}`}
														</Tooltip>
													}
												>
													<div className='rounded px-2 py-1 text-white bg-primary'>
														{message.text}
													</div>
												</OverlayTrigger>
											) : (
												<div className='d-flex align-items-center'>
													<img
														src={
															getReciever(user, discussion?.users).profilePhoto
														}
														alt={`${
															getReciever(user, discussion?.users).prenom
														} ${getReciever(user, discussion?.users).nom}`}
														className='dropdown-image me-1'
													/>
													<OverlayTrigger
														key='top'
														placement='top'
														overlay={
															<Tooltip id='tooltip-top'>
																{`${moment(message.createdAt)
																	.locale('fr')
																	.format('LL')} ${moment(
																	message.createdAt
																).format('LT')}`}
															</Tooltip>
														}
													>
														<div className='rounded px-2 py-1 text-white bg-secondary'>
															{message.text}
														</div>
													</OverlayTrigger>
												</div>
											)}
										</div>
									))}
							</div>
						</div>
						{isTyping ? (
							<div>
								<Lottie
									options={defaultOptions}
									width={50}
									style={{ marginLeft: 0 }}
								/>
							</div>
						) : (
							''
						)}
					</div>
					<Form onSubmit={onSubmit} className='border-top'>
						<InputGroup>
							<Form.Control
								as='textarea'
								placeholder='Saisir un message...'
								className='rounded-0'
								value={text}
								onChange={(e) => onChange(e.target.value)}
							/>
							<Button type='submit' className='rounded-0'>
								<IoMdSend />
							</Button>
						</InputGroup>
					</Form>
				</>
			) : (
				<div className='d-flex justify-content-center align-items-center'>
					<img src={messaging3} width={500} alt='messaging' className='my-4' />
				</div>
			)}
		</div>
	)
}

export default Messages
