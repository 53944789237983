import { useEffect } from 'react'
import { Container, Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/fr'

import Loading from '../components/Loading'
import {
	getCandidatsCountAction,
	getEmploisCountAction,
	getRecruteursCountAction,
} from '../actions/adminActions'
import { ADMIN_RESET } from '../constants/adminConstants'

function AdminDashboardPage() {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { candidats, recruteurs, emplois, success, error, loading, message } =
		useSelector((state) => state.admin)
	// const {
	// 	recruteurs,
	// 	success: successRecruteur,
	// 	error: errorRecruteur,
	// 	loading: loadingRecruteur,
	// 	message: messageRecruteur,
	// } = useSelector((state) => state.recruteur)
	// const {
	// 	emplois,
	// 	success: successEmploi,
	// 	error: errorEmploi,
	// 	loading: loadingEmploi,
	// 	message: messageEmploi,
	// } = useSelector((state) => state.emploi)

	useEffect(() => {
		dispatch(getCandidatsCountAction())
		dispatch(getRecruteursCountAction())
		dispatch(getEmploisCountAction())
	}, [dispatch])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
		}
		if (success || error) {
			dispatch({ type: ADMIN_RESET })
		}
	}, [dispatch, success, error, message])

	if (loading) {
		return <Loading />
	}

	return (
		<Container>
			<div className='w-75 mx-auto width-100'>
				<Card className='my-3'>
					<Card.Body>
						<Card.Title>Le {moment().locale('fr').format('LL')}</Card.Title>
						<Card.Text className='fs-2'>
							Bienvenue sur{' '}
							<span className='save'>l'espace administrateur</span>
						</Card.Text>
					</Card.Body>
				</Card>
				<h5>Toutes les ressources:</h5>
				<div className='row'>
					<div className='col-md-4 mb-2'>
						<Card
							className='p-3 admin-cards'
							onClick={() => navigate('/admin-dashboard/candidats')}
						>
							<h4 className='text-center'>Candidats</h4>
							<h4 className='text-center save'>{candidats}</h4>
						</Card>
					</div>
					<div className='col-md-4 mb-2'>
						<Card
							className='p-3 admin-cards'
							onClick={() => navigate('/admin-dashboard/recruteurs')}
						>
							<h4 className='text-center'>Recruteurs</h4>
							<h4 className='text-center save'>{recruteurs}</h4>
						</Card>
					</div>
					<div className='col-md-4 mb-2'>
						<Card
							className='p-3 admin-cards'
							onClick={() => navigate('/admin-dashboard/emplois')}
						>
							<h4 className='text-center'>Offres d'emplois</h4>
							<h4 className='text-center save'>{emplois}</h4>
						</Card>
					</div>
				</div>
			</div>
		</Container>
	)
}

export default AdminDashboardPage
