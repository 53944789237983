import { Card } from 'react-bootstrap'
// import { FaBlackTie } from 'react-icons/fa'
import { BsBriefcaseFill } from 'react-icons/bs'
import { useSelector } from 'react-redux'

import Experience from './Experience'
import AddExperience from '../modals/AddExperience'

function Experiences({ experiences }) {
	const { user } = useSelector((state) => state.auth)
	const { _id } = useSelector((state) => state.candidat)

	if (user && user._id !== _id && experiences.length === 0) return
	if (!user && experiences.length === 0) return

	return (
		<>
			{user && user._id !== _id && experiences.length === 0 && ''}
			{!user && experiences.length === 0 && ''}
			<div className='col-md-6'>
				<Card className='mb-3'>
					<Card.Body>
						<Card.Title>
							<BsBriefcaseFill /> Expériences:{' '}
							{user && user._id === _id && <AddExperience />}
						</Card.Title>
						{experiences.length > 0 &&
							experiences.map((experience) => (
								<Experience key={experience._id} experience={experience} />
							))}
					</Card.Body>
				</Card>
			</div>
		</>
	)
}

export default Experiences
