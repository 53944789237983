import { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { BsPlusLg } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'

import { addEmploiAction } from '../actions/emploiActions'
import Loading from '../components/Loading'

function AddEmploi() {
	const [values, setValues] = useState({
		titre: '',
		description: '',
		adresse: '',
		salaire: '',
		nombre_heure: '',
	})
	const [etat, setEtat] = useState('disponible')
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const dispatch = useDispatch()

	const { success, loading, message } = useSelector((state) => state.emploi)

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const onClick = () => {
		const emploi = {
			titre: values.titre,
			description: values.description,
			etat,
			adresse: values.adresse,
			salaire: values.salaire,
			nombre_heure: values.nombre_heure,
		}
		dispatch(addEmploiAction(emploi))
	}

	useEffect(() => {
		if (success && message) {
			setValues({
				titre: '',
				description: '',
				adresse: '',
				salaire: '',
				nombre_heure: '',
			})
			setEtat('disponible')
			handleClose()
		}
	}, [dispatch, success, message])

	return (
		<>
			<Button className='rounded-circle m-0 ms-1' onClick={handleShow}>
				<BsPlusLg />
			</Button>{' '}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Ajouter une offre d'emploi</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Titre'
							name='titre'
							value={values.titre}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Control
							as='textarea'
							placeholder='Description'
							rows={10}
							name='description'
							value={values.description}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Select onChange={(e) => setEtat(e.target.value)} value={etat}>
							<option value='disponible'>Disponible</option>
							<option value='non disponible'>Non disponible</option>
						</Form.Select>
					</Form.Group>
					<Form.Group className='mb-3'>
						<small className='text-muted'>(Optionnel)</small>
						<Form.Control
							type='text'
							placeholder='Adresse'
							name='adresse'
							value={values.adresse}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<small className='text-muted'>(Optionnel)</small>
						<Form.Control
							type='text'
							placeholder='Salaire'
							name='salaire'
							value={values.salaire}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<small className='text-muted'>(Optionnel)</small>
						<Form.Control
							type='text'
							placeholder="Nombre d'heures"
							name='nombre_heure'
							value={values.nombre_heure}
							onChange={onChange}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleClose}>
						Annuler
					</Button>
					<Button type='submit' onClick={onClick} disabled={loading && true}>
						Ajouter {loading && <Loading sm='sm' />}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default AddEmploi
