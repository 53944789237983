import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { MdLocationOn } from 'react-icons/md'

function Recruteur({ recruteur }) {
	return (
		<Card className='my-4'>
			<Card.Body>
				<div className='d-flex align-items-center mb-3'>
					<img
						src={recruteur.profilePhoto}
						alt={`${recruteur.prenom} ${recruteur.nom}`}
						className='candidat-image me-2'
					/>
					<div className='d-flex justify-content-center flex-column'>
						<Link to={`/recruteur/${recruteur._id}`} className='link'>
							<h4>{`${recruteur.prenom} ${recruteur.nom}`}</h4>
						</Link>
						{recruteur.adresse && (
							<p className='text-muted small-fs'>
								<MdLocationOn /> {recruteur.adresse}
							</p>
						)}
					</div>
				</div>
			</Card.Body>
		</Card>
	)
}

export default Recruteur
