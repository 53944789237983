import { useState } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { MdLocationOn } from 'react-icons/md'
import { BiCheck } from 'react-icons/bi'

function Candidat({ candidat }) {
	// const [truncated, setTruncated] = useState(true)

	// const truncatedDescription =
	// 	candidat?.description &&
	// 	(truncated ? candidat?.description.slice(0, 250) : candidat?.description)

	return (
		<Card className='my-4'>
			<Card.Body>
				<div className='d-flex align-items-center'>
					<img
						src={candidat.profilePhoto}
						alt={`${candidat.prenom} ${candidat.nom}`}
						className='candidat-image me-2'
					/>
					<div className='d-flex justify-content-center flex-column'>
						<Link to={`/candidat/${candidat._id}`} className='link'>
							<h4>{`${candidat.prenom} ${candidat.nom}`}</h4>
						</Link>
						{candidat.adresse && (
							<p className='text-muted small-fs'>
								<MdLocationOn /> {candidat.adresse}
							</p>
						)}
					</div>
				</div>
				{candidat.titre && <h5 className='mt-3'>{candidat.titre}</h5>}
				{/* {truncatedDescription && ( */}
				<p>
					{candidat.description}
					{/* {truncatedDescription}
						{truncated && '...'}{' '}
						<span
							onClick={() => setTruncated(!truncated)}
							className='text-muted small-text read-more'
						>
							{truncated ? 'Read more' : 'Read less'}
						</span> */}
				</p>
				{/* )} */}
				{candidat?.competences.length > 0 && (
					<div className='d-flex flex-wrap mt-4'>
						{candidat.competences.map((competence, index) => (
							<div
								className='rounded-pill py-1 px-3 mx-1 mb-2 competence'
								key={index}
							>
								<BiCheck /> {competence}
							</div>
						))}
					</div>
				)}
			</Card.Body>
		</Card>
	)
}

export default Candidat
