import { useState, useEffect } from 'react'
import { Form, Button, Container, InputGroup, Card } from 'react-bootstrap'
import { FaSignInAlt } from 'react-icons/fa'
import { MdAlternateEmail } from 'react-icons/md'
import { RiLockPasswordFill } from 'react-icons/ri'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Loading from '../components/Loading'
import { userSigninAction } from '../actions/authActions'
import { AUTH_RESET } from '../constants/authConstants'
import login from '../svgs/login.svg'

function SigninPage() {
	const [values, setValues] = useState({
		email: '',
		password: '',
	})

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { user, success, error, loading, message } = useSelector(
		(state) => state.auth
	)
	const { admin } = useSelector((state) => state.admin)

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const onSubmit = (e) => {
		e.preventDefault()
		dispatch(userSigninAction(values.email, values.password))
	}

	useEffect(() => {
		if (admin) {
			navigate('/admin-dashboard')
		}
	}, [navigate, admin])

	useEffect(() => {
		if (error) {
			toast.error(message)
		}
		if (success || user) {
			navigate('/dashboard')
		}
		if (success || error) {
			dispatch({ type: AUTH_RESET })
		}
	}, [navigate, dispatch, user, success, error, message])

	return (
		<Container>
			<div className='mt-5 d-flex justify-content-center'>
				<div className='d-none d-lg-block d-flex justify-content-center align-items-center me-3'>
					<img src={login} width={500} alt='login' className='my-4' />
				</div>
				<Card className='w-50 mx-auto width-100 p-3'>
					<Form onSubmit={onSubmit}>
						<h2 className='mb-3'>
							<FaSignInAlt /> S'authentifier
						</h2>
						<p className='lead mb-3'>Se connecter à votre compte</p>
						<InputGroup className='mb-3'>
							<Button variant='secondary'>
								<MdAlternateEmail className='icon' />
							</Button>
							<Form.Control
								type='email'
								placeholder='Adresse e-mail'
								name='email'
								value={values.email}
								onChange={onChange}
							/>
						</InputGroup>
						<InputGroup className='mb-3'>
							<Button variant='secondary'>
								<RiLockPasswordFill className='icon' />
							</Button>
							<Form.Control
								type='password'
								placeholder='Mot de passe'
								name='password'
								value={values.password}
								onChange={onChange}
							/>
						</InputGroup>
						<Button
							variant='primary'
							type='submit'
							className='mb-3 btn w-100'
							disabled={loading && true}
						>
							Se connecter {loading && <Loading sm='sm' />}
						</Button>
						<Form.Group className='mb-3'>
							Vous n'avez pas de compte ? <Link to='/register'>S'inscrire</Link>
							{/* <Button onClick={() => navigate('/register')}>Register</Button> */}
						</Form.Group>
					</Form>
				</Card>
			</div>
		</Container>
	)
}

export default SigninPage
