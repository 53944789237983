import {
	MESSAGE_SEND_REQUEST,
	MESSAGE_SEND_SUCCESS,
	MESSAGE_SEND_FAIL,
	MESSAGE_GET_REQUEST,
	MESSAGE_GET_SUCCESS,
	MESSAGE_GET_FAIL,
	MESSAGE_ADD,
	MESSAGE_RESET,
} from '../constants/messageConstants'

export const messageReducer = (state = { messages: [], msg: {} }, action) => {
	switch (action.type) {
		case MESSAGE_SEND_REQUEST:
		case MESSAGE_GET_REQUEST:
			return {
				...state,
				loading: true,
			}
		case MESSAGE_SEND_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				msg: action.payload,
				messages: [...state.messages, action.payload],
			}
		case MESSAGE_GET_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				messages: action.payload,
			}
		case MESSAGE_SEND_FAIL:
		case MESSAGE_GET_FAIL:
			return {
				...state,
				success: false,
				error: true,
				loading: false,
				message: action.payload.message,
			}
		case MESSAGE_ADD:
			return {
				...state,
				messages: [...state.messages, action.payload],
			}
		case MESSAGE_RESET:
			return {
				...state,
				success: false,
				error: false,
				loading: false,
				message: '',
			}
		default:
			return state
	}
}
