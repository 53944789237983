import { useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/fr'

import UpdateStage from '../modals/UpdateStage'
import Delete from '../modals/Delete'

function Stage({ stage }) {
	const { user } = useSelector((state) => state.auth)
	const { _id } = useSelector((state) => state.candidat)

	return (
		<div className='dse mb-4 p-4 position-relative' key={stage._id}>
			{/* <div className='d-flex justify-content-between align-items-center'> */}
			<div className='d-flex justify-content-between align-items-center'>
				<h5>{stage.stage_titre}</h5>
				<div className='position-absolute top-0 end-0 translate-middle float-right'>
					{user && user._id === _id && (
						<>
							<UpdateStage stage={stage} />
							<Delete stageId={stage._id} />
						</>
					)}
				</div>
			</div>
			{/* </div> */}
			<p>
				<strong>Etablissement:</strong> {stage.etablissement}
			</p>
			<p>
				<strong>Durée:</strong>{' '}
				{`${moment(stage.duree.from).format('L')} - ${moment(
					stage.duree.to
				).format('L')}`}
			</p>
			{stage.description && (
				<>
					<hr />
					<p>{stage.description}</p>
				</>
			)}
		</div>
	)
}

export default Stage
