import {
	RECRUTEUR_DATA_REQUEST,
	RECRUTEUR_DATA_SUCCESS,
	RECRUTEUR_DATA_FAIL,
	RECRUTEUR_ADD_PROFILE_PHOTO_REQUEST,
	RECRUTEUR_ADD_PROFILE_PHOTO_SUCCESS,
	RECRUTEUR_ADD_PROFILE_PHOTO_FAIL,
	RECRUTEUR_DELETE_PROFILE_PHOTO_REQUEST,
	RECRUTEUR_DELETE_PROFILE_PHOTO_SUCCESS,
	RECRUTEUR_DELETE_PROFILE_PHOTO_FAIL,
	RECRUTEUR_ADD_ADRESSE_REQUEST,
	RECRUTEUR_ADD_ADRESSE_SUCCESS,
	RECRUTEUR_ADD_ADRESSE_FAIL,
	RECRUTEUR_GET_ALL_REQUEST,
	RECRUTEUR_GET_ALL_SUCCESS,
	RECRUTEUR_GET_ALL_FAIL,
	RECRUTEUR_EMPLOIS_REQUEST,
	RECRUTEUR_EMPLOIS_SUCCESS,
	RECRUTEUR_EMPLOIS_FAIL,
	RECRUTEUR_DELETE_REQUEST,
	RECRUTEUR_DELETE_SUCCESS,
	RECRUTEUR_DELETE_FAIL,
	RECRUTEUR_ADD_ENTREPRISE_REQUEST,
	RECRUTEUR_ADD_ENTREPRISE_SUCCESS,
	RECRUTEUR_ADD_ENTREPRISE_FAIL,
	RECRUTEUR_UPDATE_ENTREPRISE_REQUEST,
	RECRUTEUR_UPDATE_ENTREPRISE_SUCCESS,
	RECRUTEUR_UPDATE_ENTREPRISE_FAIL,
	RECRUTEUR_DELETE_ENTREPRISE_REQUEST,
	RECRUTEUR_DELETE_ENTREPRISE_SUCCESS,
	RECRUTEUR_DELETE_ENTREPRISE_FAIL,
	RECRUTEUR_RESET,
} from '../constants/recruteurConstants'

export const recruteurReducer = (
	state = {
		_id: '',
		nom: '',
		prenom: '',
		profilePhoto: '',
		adresse: '',
		socialMedia: {},
		recruteurs: [],
		emplois: [],
	},
	action
) => {
	switch (action.type) {
		case RECRUTEUR_DATA_REQUEST:
			return {
				...state,
				loadingData: true,
			}
		case RECRUTEUR_DATA_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loadingData: false,
				_id: action.payload._id,
				nom: action.payload.nom,
				prenom: action.payload.prenom,
				profilePhoto: action.payload.profilePhoto,
				adresse: action.payload.adresse,
				socialMedia: action.payload.socialMedia,
				entreprise: action.payload.entreprise,
			}
		case RECRUTEUR_DATA_FAIL:
			return {
				...state,
				success: false,
				error: true,
				loadingData: false,
				message: action.payload,
			}
		case RECRUTEUR_ADD_PROFILE_PHOTO_REQUEST:
		case RECRUTEUR_DELETE_PROFILE_PHOTO_REQUEST:
		case RECRUTEUR_ADD_ADRESSE_REQUEST:
		case RECRUTEUR_GET_ALL_REQUEST:
		case RECRUTEUR_EMPLOIS_REQUEST:
		case RECRUTEUR_DELETE_REQUEST:
		case RECRUTEUR_ADD_ENTREPRISE_REQUEST:
		case RECRUTEUR_UPDATE_ENTREPRISE_REQUEST:
		case RECRUTEUR_DELETE_ENTREPRISE_REQUEST:
			return {
				...state,
				loading: true,
			}
		case RECRUTEUR_ADD_PROFILE_PHOTO_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				profilePhoto: action.payload.profilePhoto,
			}
		case RECRUTEUR_ADD_PROFILE_PHOTO_FAIL:
		case RECRUTEUR_DELETE_PROFILE_PHOTO_FAIL:
		case RECRUTEUR_ADD_ADRESSE_FAIL:
		case RECRUTEUR_GET_ALL_FAIL:
		case RECRUTEUR_EMPLOIS_FAIL:
		case RECRUTEUR_DELETE_FAIL:
		case RECRUTEUR_ADD_ENTREPRISE_FAIL:
		case RECRUTEUR_UPDATE_ENTREPRISE_FAIL:
		case RECRUTEUR_DELETE_ENTREPRISE_FAIL:
			return {
				...state,
				success: false,
				error: true,
				loading: false,
				message: action.payload,
			}
		case RECRUTEUR_DELETE_PROFILE_PHOTO_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				profilePhoto: action.payload.profilePhoto,
			}
		case RECRUTEUR_ADD_ADRESSE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				nom: action.payload.nom,
				prenom: action.payload.prenom,
				adresse: action.payload.adresse,
				socialMedia: action.payload.socialMedia,
			}
		case RECRUTEUR_GET_ALL_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				recruteurs: action.payload.recruteurs,
				pagesCount: action.payload.pagesCount,
				currentPage: action.payload.currentPage,
				itemsPerPage: action.payload.itemsPerPage,
			}
		case RECRUTEUR_EMPLOIS_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				emplois: action.payload,
			}
		case RECRUTEUR_DELETE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
			}
		case RECRUTEUR_ADD_ENTREPRISE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				entreprise: action.payload.entreprise,
			}
		case RECRUTEUR_UPDATE_ENTREPRISE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				entreprise: action.payload.entreprise,
			}
		case RECRUTEUR_DELETE_ENTREPRISE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				entreprise: '',
			}
		case RECRUTEUR_RESET:
			return {
				...state,
				success: false,
				error: false,
				loading: false,
				loadingData: false,
				message: '',
			}
		default:
			return state
	}
}
