import { useState } from 'react'
import { Card, Button, ListGroup, Alert } from 'react-bootstrap'
import { FiHeart } from 'react-icons/fi'
import { FaHeart } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/fr'

import UpdateEmploi from '../modals/UpdateEmploi'
import Delete from '../modals/Delete'
import { candidatSaveEmploiAction } from '../actions/candidatActions'

function Emploi({ emploi, emplois_saved }) {
	const dispatch = useDispatch()

	const { user } = useSelector((state) => state.auth)

	const onClick = (emploiId, emploiTitre) => {
		dispatch(candidatSaveEmploiAction(user._id, emploiId, emploiTitre))
	}

	// const [truncated, setTruncated] = useState(true)

	// const truncatedDescription =
	// 	emploi?.description &&
	// 	(truncated ? emploi?.description.slice(0, 250) : emploi?.description)

	return (
		<Card className='my-4'>
			<Card.Body>
				{emploi.etat !== 'disponible' && (
					<Alert variant='warning' className='mt-2'>
						Cette offre d'emploi n'est plus disponible
					</Alert>
				)}
				<div className='d-flex justify-content-between align-items-center'>
					<Card.Title>
						<Link to={`/emploi/${emploi._id}`} className='link'>
							{emploi.titre}
						</Link>
					</Card.Title>
					{user && (
						<div
							className={`position-absolute top-0 end-0 translate-middle ${
								user.user === 'Recruteur' && 'float-right'
							}`}
						>
							{emploi.recruteur_id === user._id ? (
								<>
									<UpdateEmploi emploi={emploi} />
									<Delete emploiId={emploi._id} />
								</>
							) : user.user === 'Candidat' ? (
								<Button
									className='rounded-circle m-0 ms-1'
									variant='secondary'
									onClick={() => onClick(emploi._id, emploi.titre)}
								>
									{emplois_saved.some((e) => e.emploi_id === emploi._id) ? (
										<FaHeart className='save' />
									) : (
										<FiHeart className='save' />
									)}
								</Button>
							) : (
								''
							)}
						</div>
					)}
				</div>
				<Card.Subtitle className='mb-2 text-muted'>
					{moment(emploi.createdAt).startOf('minute').fromNow()}
				</Card.Subtitle>
				<Card.Text className='mb-3'>
					{emploi.description}
					{/* {truncatedDescription}
					{truncated && '...'}{' '}
					<span
						onClick={() => setTruncated(!truncated)}
						className='text-muted small-text read-more'
					>
						{truncated ? 'Read more' : 'Read less'}
					</span> */}
				</Card.Text>
				{emploi.adresse || emploi.salaire || emploi.nombre_heure ? (
					<ListGroup className='mb-3'>
						{emploi.adresse && (
							<ListGroup.Item>
								<strong>Adresse: </strong>
								{emploi.adresse}
							</ListGroup.Item>
						)}
						{emploi.salaire && (
							<ListGroup.Item>
								<strong>Salaire: </strong>
								{emploi.salaire} DA
							</ListGroup.Item>
						)}
						{emploi.nombre_heure && (
							<ListGroup.Item>
								<strong>Nombre d'heure: </strong>
								{emploi.nombre_heure}
							</ListGroup.Item>
						)}
					</ListGroup>
				) : (
					''
				)}
				<p className='small-fs'>
					Publié par{' '}
					<Link
						to={`/recruteur/${emploi.recruteur_id}`}
						className='link recruteur'
					>{`${emploi.recruteur_prenom} ${emploi.recruteur_nom}`}</Link>
				</p>
			</Card.Body>
		</Card>
	)
}

export default Emploi
