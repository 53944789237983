import { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { BsPlusLg } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { addCandidatStageAction } from '../actions/candidatActions'
import Loading from '../components/Loading'

function AddStage() {
	const { candidatId } = useParams()

	const [values, setValues] = useState({
		stage_titre: '',
		etablissement: '',
		from: '',
		to: '',
		description: '',
	})
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const dispatch = useDispatch()

	const { success, loading, message } = useSelector((state) => state.candidat)

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const onClick = () => {
		const stage = {
			stage_titre: values.stage_titre,
			etablissement: values.etablissement,
			from: values.from,
			to: values.to,
			description: values.description,
		}

		dispatch(addCandidatStageAction(candidatId, stage))
	}

	useEffect(() => {
		if (success && message) {
			setValues({
				stage_titre: '',
				etablissement: '',
				from: '',
				to: '',
				description: '',
			})
			handleClose()
		}
	}, [success, message])

	return (
		<>
			<Button className='rounded-circle m-0 ms-1' onClick={handleShow}>
				<BsPlusLg />
			</Button>{' '}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Ajouter un stage</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Titre'
							name='stage_titre'
							value={values.stage_titre}
							onChange={onChange}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Etablissement'
							name='etablissement'
							value={values.etablissement}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Control
							type='date'
							name='from'
							value={values.from}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Control
							type='date'
							name='to'
							value={values.to}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<small className='text-muted'>(optionnel)</small>
						<Form.Control
							as='textarea'
							placeholder='Description'
							rows={10}
							name='description'
							value={values.description}
							onChange={onChange}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleClose}>
						Annuler
					</Button>
					<Button type='submit' onClick={onClick} disabled={loading && true}>
						Ajouter {loading && <Loading sm='sm' />}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default AddStage
