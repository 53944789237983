import { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { FaPen } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { updateCandidatLangueAction } from '../actions/candidatActions'
import Loading from '../components/Loading'

function UpdateLangue({ langue }) {
	const { candidatId } = useParams()

	const [values, setValues] = useState({
		nom: langue.nom,
	})
	const [niveau, setNiveau] = useState(langue.niveau)
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const dispatch = useDispatch()

	const { success, loading, message } = useSelector((state) => state.candidat)

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const onClick = () => {
		const updatedLangue = {
			nom: values.nom,
			niveau,
		}
		dispatch(updateCandidatLangueAction(candidatId, langue._id, updatedLangue))
	}

	useEffect(() => {
		if (success && message) {
			handleClose()
		}
	}, [dispatch, success, message])

	return (
		<>
			<Button className='rounded-circle m-0 ms-1' onClick={handleShow}>
				<FaPen />
			</Button>{' '}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Mettre à jour</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Nom de la langue'
							name='nom'
							value={values.nom}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Select
							onChange={(e) => setNiveau(e.target.value)}
							value={niveau}
						>
							<option value='debutant'>Débutant</option>
							<option value='intermediaire'>Intermédiaire</option>
							<option value='avance'>Avancé</option>
							<option value='maternelle ou bilingue'>
								Maternelle ou Bilingue
							</option>
						</Form.Select>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleClose}>
						Annuler
					</Button>
					<Button type='submit' onClick={onClick} disabled={loading && true}>
						Enregistrer {loading && <Loading sm='sm' />}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default UpdateLangue
