import { useEffect } from 'react'
import { Table, Button, Container } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Loading from '../components/Loading'
import { getRecruteurAllAction } from '../actions/recruteurActions'
import { RECRUTEUR_RESET } from '../constants/recruteurConstants'
import Search from '../components/Search'
import PaginationComponent from '../components/PaginationComponent'

function AdminRecruteursPage() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { page = 1, name } = useParams()

	const {
		recruteurs,
		success,
		error,
		loading,
		message,
		pagesCount,
		currentPage,
		itemsPerPage,
	} = useSelector((state) => state.recruteur)

	const searchRecruteurs = (value) => {
		if (value !== '') {
			navigate(`/admin-dashboard/recruteurs/name/${value}`)
		} else {
			navigate('/admin-dashboard/recruteurs')
			dispatch(getRecruteurAllAction(page))
		}
	}

	useEffect(() => {
		if (name !== undefined) {
			dispatch(getRecruteurAllAction(page, name))
		} else {
			dispatch(getRecruteurAllAction(page))
		}
	}, [dispatch, page, name])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
			navigate('/admin-dashboard/recruteurs')
		}
		if (success && message) {
			toast.success(message)
		}
		if (success || error) {
			dispatch({ type: RECRUTEUR_RESET })
		}
	}, [dispatch, navigate, success, error, message])

	return (
		<Container>
			<h5 className='mt-3'>Tous les recruteurs</h5>
			<Search searchRecruteurs={searchRecruteurs} />
			{loading ? (
				<Loading />
			) : (
				<>
					<Table striped bordered hover variant='dark'>
						<thead>
							<tr>
								<th>ID</th>
								<th>Photo</th>
								<th>Prénom</th>
								<th>Nom</th>
								<th>Email</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{recruteurs.map((recruteur) => (
								<tr key={recruteur._id}>
									<td>{recruteur._id}</td>
									<td>
										<img
											src={recruteur.profilePhoto}
											alt=''
											className='dropdown-image'
										/>
									</td>
									<td>{recruteur.prenom}</td>
									<td>{recruteur.nom}</td>
									<td>{recruteur.email}</td>
									<td>
										<Button
											onClick={() => {
												navigate(`/admin-dashboard/recruteurs/${recruteur._id}`)
											}}
										>
											Détail
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					<PaginationComponent
						pagesCount={pagesCount}
						currentPage={currentPage}
						itemsPerPage={itemsPerPage}
						name={name}
						adminDashboardRecruteurs='adminDashboardRecruteurs'
					/>
				</>
			)}
		</Container>
	)
}

export default AdminRecruteursPage
