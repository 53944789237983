import { Card } from 'react-bootstrap'
import moment from 'moment'
import 'moment/locale/fr'

function WelcomeMessage({ user }) {
	return (
		<Card className='mb-3'>
			<Card.Body>
				<Card.Title>Le {moment().locale('fr').format('LL')}</Card.Title>
				<Card.Text className='fs-2'>
					Bienvenue <span className='save'>{`${user.prenom} ${user.nom}`}</span>
				</Card.Text>
			</Card.Body>
		</Card>
	)
}

export default WelcomeMessage
