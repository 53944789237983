import { useState, useEffect } from 'react'
import { ProgressBar, Accordion } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { getCandidatDataAction } from '../actions/candidatActions'

function RightSide({ user }) {
	const [percentage, setPercentage] = useState(0)

	const dispatch = useDispatch()

	const {
		profilePhoto,
		adresse,
		socialMedia,
		titre,
		description,
		competences,
		diplomes,
		stages,
		experiences,
		langues,
	} = useSelector((state) => state.candidat)

	useEffect(() => {
		let score = 0
		if (
			profilePhoto !==
			'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/271deea8-e28c-41a3-aaf5-2913f5f48be6/de7834s-6515bd40-8b2c-4dc6-a843-5ac1a95a8b55.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzI3MWRlZWE4LWUyOGMtNDFhMy1hYWY1LTI5MTNmNWY0OGJlNlwvZGU3ODM0cy02NTE1YmQ0MC04YjJjLTRkYzYtYTg0My01YWMxYTk1YThiNTUuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.BopkDn1ptIwbmcKHdAOlYHyAOOACXW0Zfgbs0-6BY-E'
		) {
			score += 15
		}
		if (adresse) {
			score += 5
		}
		if (socialMedia) {
			score += 10
		}
		if (titre && description) {
			score += 15
		}
		if (competences.length > 0) {
			score += 15
		}
		if (diplomes.length > 0) {
			score += 10
		}
		if (stages.length > 0) {
			score += 10
		}
		if (experiences.length > 0) {
			score += 10
		}
		if (langues.length > 0) {
			score += 10
		}

		setPercentage(score)
	}, [
		profilePhoto,
		adresse,
		socialMedia,
		titre,
		description,
		competences,
		diplomes,
		stages,
		experiences,
		langues,
	])

	useEffect(() => {
		if (user.user === 'Candidat') {
			dispatch(getCandidatDataAction(user._id))
		}
	}, [dispatch, user])

	return (
		<div className='col-md-3 order-md-last mt-3 mb-md-3'>
			<Accordion defaultActiveKey={['0']} alwaysOpen>
				<Accordion.Item eventKey='0'>
					<Accordion.Header>Profil</Accordion.Header>
					<Accordion.Body>
						{/* <Card className='mb-3'>
							<Card.Body>
								<Card.Title className='text-center'> */}
						<div className='text-center'>
							<img
								src={user.profilePhoto}
								alt={`${user.prenom} ${user.nom}`}
								className='candidat-image'
							/>
							{user.user === 'Candidat' ? (
								<Link to={`/candidat/${user._id}`} className='link'>
									<h4>{`${user.prenom} ${user.nom}`}</h4>
								</Link>
							) : (
								<Link to={`/recruteur/${user._id}`} className='link'>
									<h4>{`${user.prenom} ${user.nom}`}</h4>
								</Link>
							)}
						</div>
						{/* </Card.Title> */}
						<div>
							{user.user === 'Candidat' && (
								<>
									<ProgressBar now={percentage} />
									<p className='lead text-center'>{`${percentage}%`}</p>
									<p className='mt-2'>
										{percentage < 100
											? "Complétez votre profil pour attirer l'attention des recruteurs."
											: 'Votre profil est complet.'}
									</p>
								</>
							)}
						</div>
						{/* </Card.Body>
						</Card> */}
					</Accordion.Body>
				</Accordion.Item>
				{user.user === 'Candidat' && (
					<>
						<Accordion.Item eventKey='1'>
							<Accordion.Header>Candidatures</Accordion.Header>
							<Accordion.Body>
								{/* <Card className='mb-3'>
								<Card.Body>
									<Card.Title> */}
								{/* Candidatures */}
								<Link to={`/candidat/${user._id}/candidature`} className='link'>
									<h5 className='mt-2'>Vos candidatures</h5>
								</Link>
								{/* </Card.Title>
									<Card.Text> */}
								Postuler aux offres d'emplois pour avoir plus de chance.
								{/* </Card.Text>
								</Card.Body>
							</Card> */}
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey='2'>
							<Accordion.Header>Enregistrés</Accordion.Header>
							<Accordion.Body>
								<Link to={`/candidat/${user._id}/emplois`} className='link'>
									<h5 className='mt-2'>Offres sauvegardés</h5>
								</Link>
							</Accordion.Body>
						</Accordion.Item>
					</>
				)}
			</Accordion>
		</div>
	)
}

export default RightSide
