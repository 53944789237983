import { useState, useEffect, useCallback } from 'react'
import {
	Container,
	Form,
	Button,
	InputGroup,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap'
import { toast } from 'react-toastify'
import { IoMdSend } from 'react-icons/io'
import { BiArrowBack } from 'react-icons/bi'
import Lottie from 'react-lottie'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import io from 'socket.io-client'
import moment from 'moment'
import 'moment/locale/fr'

import { getRecruteurDataAction } from '../actions/recruteurActions'
import { getCandidatDataAction } from '../actions/candidatActions'
import {
	sendMessageAction,
	getMessagesAction,
	addMessageAction,
} from '../actions/messageActions'
import { accessDiscussionAction } from '../actions/discussionActions'
import { CANDIDAT_RESET } from '../constants/candidatConstants'
import { RECRUTEUR_RESET } from '../constants/recruteurConstants'
import Loading from '../components/Loading'
import animationData from '../animations/typing.json'
import { getReciever } from '../utils/discussion'
import { removeNotificationMessageAction } from '../actions/notificationActions'

// const ENDPOINT = 'http://localhost:5000'
const ENDPOINT = 'https://fine-blue-dove-tux.cyclic.app'
let socket

function ContactPage() {
	const [text, setText] = useState('')
	const [socketConnected, setSocketConnected] = useState(false)
	const [typing, setTyping] = useState(false)
	const [isTyping, setIsTyping] = useState(false)

	const { userId } = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { user } = useSelector((state) => state.auth)
	const {
		nom: nomCandidat,
		prenom: prenomCandidat,
		profilePhoto: profilePhotoCandidat,
		success: successCandidat,
		error: errorCandidat,
		loading: loadingCandidat,
		message: messageCandidat,
	} = useSelector((state) => state.candidat)
	const {
		nom: nomRecruteur,
		prenom: prenomRecruteur,
		profilePhoto: profilePhotoRecruteur,
		success: successRecruteur,
		error: errorRecruteur,
		loading: loadingRecruteur,
		message: messageRecruteur,
	} = useSelector((state) => state.recruteur)
	const { discussion } = useSelector((state) => state.discussion)
	const { messages } = useSelector((state) => state.message)
	const { notifications_messages } = useSelector((state) => state.notification)

	useEffect(() => {
		socket = io(ENDPOINT)
		socket.emit('setup', user)
		socket.on('connected', () => setSocketConnected(true))
		socket.on('typing', () => setIsTyping(true))
		socket.on('stop typing', () => setIsTyping(false))
	}, [user])

	const onChange = (e) => {
		setText(e)
		if (discussion) {
			if (!socketConnected) return
			if (!typing) {
				setTyping(true)
				socket.emit('typing', discussion._id)
			}
			let lastTypingTime = new Date().getTime()
			let timerLength = 3000
			setTimeout(() => {
				let timeNow = new Date().getTime()
				let timeDiff = timeNow - lastTypingTime

				if (timeDiff >= timerLength && typing) {
					socket.emit('stop typing', discussion._id)
					setTyping(false)
				}
			}, timerLength)
		}
	}

	const onSubmit = (e) => {
		e.preventDefault()
		if (text) {
			socket.emit('stop typing', discussion._id)
			setText('')
			dispatch(sendMessageAction(userId, text, user, socket))
			// if (Object.keys(msg).length > 0) {
			// 	dispatch(
			// 		addNotificationMessageAction(
			// 			getReciever(user, msg?.discussion?.users)._id,
			// 			`Nouveau message de ${msg.sender?.prenom} ${msg.sender?.nom}`,
			// 			msg.discussion?._id,
			// 			socket
			// 		)
			// 	)
			// }
		}
	}

	useEffect(() => {
		if (userId) {
			if (window.location.pathname.split('/').includes('candidat')) {
				dispatch(getCandidatDataAction(userId))
			} else {
				dispatch(getRecruteurDataAction(userId))
			}
			dispatch(accessDiscussionAction(userId))
			dispatch(getMessagesAction(userId))
		}
	}, [dispatch, userId])

	useEffect(() => {
		if (user) {
			notifications_messages.length > 0 &&
				notifications_messages.forEach((nm) => {
					if (nm.discussion === discussion._id) {
						dispatch(removeNotificationMessageAction(user._id, nm._id))
					}
				})
		}
	}, [dispatch, notifications_messages, discussion, user])

	useEffect(() => {
		if (discussion) {
			socket.emit('join discussion', discussion._id)
		}
	}, [discussion])

	useEffect(() => {
		socket.on('message recieved', (newMessageRecieved) => {
			if (discussion._id === newMessageRecieved?.discussion?._id) {
				dispatch(addMessageAction(newMessageRecieved))
			}
		})
	}, [dispatch, discussion])

	useEffect(() => {
		if (errorCandidat && messageCandidat) {
			toast.error(messageCandidat)
		}
		if (successCandidat && messageCandidat) {
			toast.success(messageCandidat)
		}
		if (successCandidat || errorCandidat) {
			dispatch({ type: CANDIDAT_RESET })
		}

		if (errorRecruteur && messageRecruteur) {
			toast.error(messageRecruteur)
		}
		if (successRecruteur && messageRecruteur) {
			toast.success(messageRecruteur)
		}
		if (successRecruteur || errorRecruteur) {
			dispatch({ type: RECRUTEUR_RESET })
		}
	}, [
		dispatch,
		successCandidat,
		errorCandidat,
		messageCandidat,
		successRecruteur,
		errorRecruteur,
		messageRecruteur,
	])

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	}

	const setRef = useCallback((node) => {
		if (node) {
			node.scrollIntoView({ smooth: true })
		}
	}, [])

	useEffect(() => {
		setRef()
	}, [setRef])

	if (loadingCandidat || loadingRecruteur) {
		return <Loading />
	}

	return (
		<Container>
			<div
				className='my-3 discussion-page w-50 mx-auto width-100'
				style={{ height: '75vh' }}
			>
				<>
					<div className='d-flex justify-content-start align-items-center border-top border-start border-end p-2 bg-light'>
						<Button
							variant='secondary'
							className='me-2'
							onClick={() =>
								window.location.pathname.split('/').includes('candidat')
									? navigate(`/candidat/${userId}`)
									: navigate(`/recruteur/${userId}`)
							}
						>
							<BiArrowBack />
						</Button>
						<img
							src={
								window.location.pathname.split('/').includes('candidat')
									? profilePhotoCandidat
									: profilePhotoRecruteur
							}
							alt={
								window.location.pathname.split('/').includes('candidat')
									? `${prenomCandidat} ${nomCandidat}`
									: `${prenomRecruteur} ${nomRecruteur}`
							}
							className='photo me-2'
							style={{ width: '50px', height: '50px' }}
						/>
						<Link
							to={
								window.location.pathname.split('/').includes('candidat')
									? `/candidat/${userId}`
									: `/recruteur/${userId}`
							}
							className='link'
						>
							<h6>
								{window.location.pathname.split('/').includes('candidat')
									? `${prenomCandidat} ${nomCandidat}`
									: `${prenomRecruteur} ${nomRecruteur}`}
							</h6>
						</Link>
					</div>
					<div
						className='d-flex flex-column justify-content-end border bg-dark'
						style={{
							height: '55vh',
							// backgroundColor: '#d9e2ef',
						}}
					>
						<div className='overflow-auto p-3'>
							<div className='d-flex flex-column'>
								{messages.length > 0 &&
									messages.map((message, index) => (
										<div
											className={`my-1 d-flex flex-column w-75 ${
												message.sender._id === user._id
													? 'align-self-end align-items-end'
													: 'align-items-start'
											}`}
											ref={messages.length - 1 === index ? setRef : null}
											key={message._id}
										>
											{message.sender._id === user._id ? (
												<OverlayTrigger
													key='top'
													placement='top'
													overlay={
														<Tooltip id='tooltip-top'>
															{`${moment(message.createdAt)
																.locale('fr')
																.format('LL')} ${moment(
																message.createdAt
															).format('LT')}`}
														</Tooltip>
													}
												>
													<div className='rounded px-2 py-1 text-white bg-primary'>
														{message.text}
													</div>
												</OverlayTrigger>
											) : (
												<div className='d-flex align-items-center'>
													<img
														src={
															getReciever(user, discussion?.users).profilePhoto
														}
														alt={`${
															getReciever(user, discussion?.users).prenom
														} ${getReciever(user, discussion?.users).nom}`}
														className='dropdown-image me-1'
													/>
													<OverlayTrigger
														key='top'
														placement='top'
														overlay={
															<Tooltip id='tooltip-top'>
																{`${moment(message.createdAt)
																	.locale('fr')
																	.format('LL')} ${moment(
																	message.createdAt
																).format('LT')}`}
															</Tooltip>
														}
													>
														<div className='rounded px-2 py-1 text-white bg-secondary'>
															{message.text}
														</div>
													</OverlayTrigger>
												</div>
											)}
										</div>
									))}
							</div>
						</div>
						{isTyping ? (
							<div>
								<Lottie
									options={defaultOptions}
									width={50}
									style={{ marginLeft: 0 }}
								/>
							</div>
						) : (
							''
						)}
					</div>
					<Form onSubmit={onSubmit} className='border-top'>
						<InputGroup>
							<Form.Control
								as='textarea'
								placeholder='Saisir un message...'
								className='rounded-0'
								value={text}
								onChange={(e) => onChange(e.target.value)}
							/>
							<Button type='submit' className='rounded-0'>
								<IoMdSend />
							</Button>
						</InputGroup>
					</Form>
				</>
			</div>
		</Container>
	)
}

export default ContactPage
