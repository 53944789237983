export const MESSAGE_SEND_REQUEST = 'MESSAGE_SEND_REQUEST'
export const MESSAGE_SEND_SUCCESS = 'MESSAGE_SEND_SUCCESS'
export const MESSAGE_SEND_FAIL = 'MESSAGE_SEND_FAIL'

export const MESSAGE_GET_REQUEST = 'MESSAGE_GET_REQUEST'
export const MESSAGE_GET_SUCCESS = 'MESSAGE_GET_SUCCESS'
export const MESSAGE_GET_FAIL = 'MESSAGE_GET_FAIL'

export const MESSAGE_ADD = 'MESSAGE_ADD'

export const MESSAGE_RESET = 'MESSAGE_RESET'
