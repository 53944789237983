import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'react-bootstrap'

import AddEntreprise from '../modals/AddEntreprise'
import UpdateEntreprise from '../modals/UpdateEntreprise'
import Delete from '../modals/Delete'

function Entreprise({ entreprise }) {
	// const [truncated, setTruncated] = useState(true)

	const { user } = useSelector((state) => state.auth)
	const { _id } = useSelector((state) => state.recruteur)

	// const truncatedDescription =
	// 	description && (truncated ? description.slice(0, 250) : description)

	if (user && user._id !== _id && entreprise === undefined) return
	if (!user && entreprise === undefined) return

	return (
		<>
			<Card className='mb-4'>
				<Card.Body>
					{entreprise ? (
						<>
							<div className='d-flex justify-content-between align-items-center'>
								<p>
									Entreprise: <strong>{entreprise}</strong>
								</p>
								{/* <div className='position-absolute top-0 end-0 translate-middle'> */}
								{user && user._id === _id && (
									<>
										<div className='position-absolute top-0 end-0 translate-middle float-right'>
											<UpdateEntreprise entreprise={entreprise} />
											<Delete entreprise={entreprise} />
										</div>
									</>
								)}
								{/* </div> */}
							</div>
						</>
					) : user && user._id === _id ? (
						<div className='text-center'>
							<AddEntreprise />
							<p className='lead'>Ajouter votre entreprise</p>
						</div>
					) : (
						''
					)}
				</Card.Body>
			</Card>
		</>
	)
}

export default Entreprise
