import { useState, useEffect } from 'react'
import { Form, Button, Container, InputGroup, Card } from 'react-bootstrap'
import { FaSignInAlt } from 'react-icons/fa'
import { MdAlternateEmail } from 'react-icons/md'
import { RiLockPasswordFill } from 'react-icons/ri'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Loading from '../components/Loading'
import { adminSigninAction } from '../actions/adminActions'
import { ADMIN_RESET } from '../constants/adminConstants'

function AdminSigninPage() {
	const [values, setValues] = useState({
		email: '',
		password: '',
	})

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { admin, success, error, loading, message } = useSelector(
		(state) => state.admin
	)
	const { user } = useSelector((state) => state.auth)

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const onSubmit = (e) => {
		e.preventDefault()
		dispatch(adminSigninAction(values.email, values.password))
	}

	useEffect(() => {
		if (user) {
			navigate('/dashboard')
		}
	}, [navigate, user])

	useEffect(() => {
		if (error) {
			toast.error(message)
		}
		if (success || admin) {
			navigate('/admin-dashboard')
		}
		if (success || error) {
			dispatch({ type: ADMIN_RESET })
		}
	}, [navigate, dispatch, admin, success, error, message])

	return (
		<Container>
			<div className='mt-5 d-flex justify-content-center'>
				<Card className='w-50 mx-auto width-100 p-3'>
					<Form onSubmit={onSubmit}>
						<h2 className='mb-3'>
							<FaSignInAlt /> S'authentifier
						</h2>
						<p className='lead mb-3'>Se connecter en tant qu'administrateur</p>
						<InputGroup className='mb-3'>
							<Button variant='secondary'>
								<MdAlternateEmail className='icon' />
							</Button>
							<Form.Control
								type='email'
								placeholder='Adresse e-mail'
								name='email'
								value={values.email}
								onChange={onChange}
							/>
						</InputGroup>
						<InputGroup className='mb-3'>
							<Button variant='secondary'>
								<RiLockPasswordFill className='icon' />
							</Button>
							<Form.Control
								type='password'
								placeholder='Mot de passe'
								name='password'
								value={values.password}
								onChange={onChange}
							/>
						</InputGroup>
						<Button
							variant='primary'
							type='submit'
							className='mb-3 btn w-100'
							disabled={loading && true}
						>
							Se connecter {loading && <Loading sm='sm' />}
						</Button>
					</Form>
				</Card>
			</div>
		</Container>
	)
}

export default AdminSigninPage
