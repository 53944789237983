import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import EmploiSaved from '../components/EmploiSaved'
import Loading from '../components/Loading'
import { candidatGetSavedEmploiAction } from '../actions/candidatActions'
import { CANDIDAT_RESET } from '../constants/candidatConstants'

function EmploisSavedPage() {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { emplois_saved, success, error, loading, message } = useSelector(
		(state) => state.candidat
	)
	const { user } = useSelector((state) => state.auth)

	useEffect(() => {
		dispatch(candidatGetSavedEmploiAction(user._id))
	}, [dispatch, user._id])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
			navigate('/dashboard/candidats')
		}
		if (success && message) {
			toast.success(message)
		}
		if (success || error) {
			dispatch({ type: CANDIDAT_RESET })
		}
	}, [dispatch, navigate, success, error, message])

	if (loading) {
		return <Loading />
	}

	return (
		<Container className='w-75 mx-auto width-100'>
			<h5 className='mt-3'>Emplois sauvegardés:</h5>
			{emplois_saved.length > 0 ? (
				emplois_saved.map((emploi) => (
					<EmploiSaved
						key={emploi._id}
						emploi={emploi}
						emplois_saved={emplois_saved}
					/>
				))
			) : (
				<p className='lead mt-3'>Vos emplois sauvegardé vont s'afficher ici.</p>
			)}
		</Container>
	)
}

export default EmploisSavedPage
