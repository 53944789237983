import { useState, useEffect } from 'react'
import { Container, Form, FormLabel, Button, Card } from 'react-bootstrap'
import { BiRightArrowAlt, BiArrowBack } from 'react-icons/bi'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import candidatRegister from '../svgs/candidatRegister.svg'
import recruteurRegister from '../svgs/recruteurRegister.svg'

function RegisterPage() {
	const [checkedValue, setCheckedValue] = useState('')

	const navigate = useNavigate()

	const onSubmit = (e) => {
		e.preventDefault()

		navigate(`/register/${checkedValue}`)
	}
	const { admin } = useSelector((state) => state.admin)

	useEffect(() => {
		if (admin) {
			navigate('/admin-dashboard')
		}
	}, [navigate, admin])

	return (
		<Container>
			<Card className='w-75 mx-auto width-100 p-3 my-4'>
				<Form onSubmit={onSubmit}>
					{/* <Button
						variant='secondary'
						className='me-2'
						onClick={() => navigate(`/register`)}
					>
						<BiArrowBack />
					</Button> */}
					{/* <h3 className='mt-2'>S'inscrire en tant que recruteur ou candidat</h3> */}
					<h3 className='text-center'>
						S'inscrire en tant que recruteur ou candidat
					</h3>
					<div className='row justify-content-center my-2 p-3'>
						<div
							className='col-md-5 d-flex flex-column justify-content-center align-items-center m-3'
							style={{
								borderRadius: '20px',
								border: '1px solid #05929c',
								backgroundImage: 'linear-gradient(to left, #79e4d4, #f2f7f6)',
							}}
						>
							<img
								src={candidatRegister}
								style={{
									width: '200px',
									height: '200px',
								}}
								alt=''
							/>
							<Form.Check
								type='checkbox'
								label='Vous êtes candidat, vous cherchez un travail.'
								checked={checkedValue === 'candidat' && true}
								onChange={() => setCheckedValue('candidat')}
							/>
						</div>
						<div
							className='col-md-5 d-flex flex-column justify-content-center align-items-center p-3 m-3'
							style={{
								borderRadius: '20px',
								border: '1px solid #05929c',
								backgroundImage: 'linear-gradient(to left, #79e4d4, #f2f7f6)',
							}}
						>
							<img
								src={recruteurRegister}
								style={{
									width: '200px',
									height: '200px',
								}}
								alt=''
							/>
							<Form.Check
								type='checkbox'
								label='Vous êtes recruteur, vous cherchez un employé.'
								checked={checkedValue === 'recruteur' && true}
								onChange={() => setCheckedValue('recruteur')}
							/>
						</div>
					</div>
					<div className='text-center m-0 p-0'>
						<Button
							variant='primary'
							type='submit'
							className='mb-3 btn w-100'
							disabled={checkedValue === '' && true}
						>
							Inscription {checkedValue} <BiRightArrowAlt size={28} />
						</Button>
					</div>
					<Form.Group className='mb-3'>
						Vous avez déjà un compte ? <Link to='/signin'>Se connecter</Link>
					</Form.Group>
				</Form>
			</Card>
		</Container>
	)
}

export default RegisterPage
