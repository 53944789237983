import { useEffect } from 'react'
import { Container, Card, Button, Alert, ListGroup } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { FiHeart } from 'react-icons/fi'
import { FaHeart } from 'react-icons/fa'
import { useParams, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import 'moment/locale/fr'

import Loading from '../components/Loading'
import Postuler from '../modals/Postuler'
import { emploiDetailsAction } from '../actions/emploiActions'
import {
	candidatGetSavedEmploiAction,
	candidatSaveEmploiAction,
} from '../actions/candidatActions'
import { EMPLOI_RESET } from '../constants/emploiConstants'
import { CANDIDAT_RESET } from '../constants/candidatConstants'
import { removeNotificationAction } from '../actions/notificationActions'
import Confirm from '../modals/Confirm'

function EmploiPage() {
	const { emploiId } = useParams()

	const dispatch = useDispatch()

	const { emploi, success, error, loading, message } = useSelector(
		(state) => state.emploi
	)
	const { user } = useSelector((state) => state.auth)
	const {
		emplois_saved,
		success: successCandidat,
		error: errorCandidat,
		message: messageCandidat,
	} = useSelector((state) => state.candidat)
	const { notifications } = useSelector((state) => state.notification)

	const onClick = (emploiId, emploiTitre) => {
		dispatch(candidatSaveEmploiAction(user._id, emploiId, emploiTitre))
	}

	useEffect(() => {
		dispatch(emploiDetailsAction(emploiId))
	}, [dispatch, emploiId])

	useEffect(() => {
		if (user && user.user === 'Candidat') {
			dispatch(candidatGetSavedEmploiAction(user._id))
		}
	}, [dispatch, user])

	useEffect(() => {
		if (user) {
			notifications.length > 0 &&
				notifications.forEach((n) => {
					if (n.emploi === emploi._id) {
						dispatch(removeNotificationAction(user._id, n._id))
					}
				})
		}
	}, [dispatch, notifications, emploi, user])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
		}
		if (success && message) {
			toast.success(message)
		}
		if (success || error) {
			dispatch({ type: EMPLOI_RESET })
		}
		if (errorCandidat && messageCandidat) {
			toast.error(messageCandidat)
		}
		if (successCandidat && messageCandidat) {
			toast.success(messageCandidat)
		}
		if (successCandidat || errorCandidat) {
			dispatch({ type: CANDIDAT_RESET })
		}
	}, [
		dispatch,
		success,
		error,
		message,
		successCandidat,
		errorCandidat,
		messageCandidat,
	])

	let dejaPostule
	if (user && Object.keys(emploi).length > 0) {
		dejaPostule = emploi.candidatures.find(
			(candidature) => candidature.candidat_id === user._id
		)
	}

	if (loading) {
		return <Loading />
	}

	return (
		<Container>
			<Card className='w-75 mx-auto width-100 p-3 my-3'>
				{emploi.etat !== 'disponible' && (
					<Alert variant='warning' className='mt-2'>
						Cette offre d'emploi n'est plus disponible
					</Alert>
				)}
				<div className='d-flex justify-content-between align-items-center'>
					<h4>{emploi.titre}</h4>
					{user && user.user === 'Candidat' && (
						<div>
							{dejaPostule ? (
								<Button disabled>Déja postulé</Button>
							) : (
								<Postuler emploi={emploi} />
							)}
							<Button
								className='rounded-circle m-0 ms-1'
								variant='secondary'
								onClick={() => onClick(emploi._id, emploi.titre)}
							>
								{emplois_saved.some((e) => e.emploi_id === emploi._id) ? (
									<FaHeart className='save' />
								) : (
									<FiHeart className='save' />
								)}
							</Button>
						</div>
					)}
				</div>
				<h6 className='text-muted mb-3'>
					{moment(emploi.createdAt).startOf('minute').fromNow()}
				</h6>
				<p className='mb-3'>{emploi.description}</p>
				{emploi.adresse || emploi.salaire || emploi.nombre_heure ? (
					<ListGroup className='mb-3'>
						{emploi.adresse && (
							<ListGroup.Item>
								<strong>Adresse: </strong>
								{emploi.adresse}
							</ListGroup.Item>
						)}
						{emploi.salaire && (
							<ListGroup.Item>
								<strong>Salaire: </strong>
								{emploi.salaire} DA
							</ListGroup.Item>
						)}
						{emploi.nombre_heure && (
							<ListGroup.Item>
								<strong>Nombre d'heure: </strong>
								{emploi.nombre_heure}
							</ListGroup.Item>
						)}
					</ListGroup>
				) : (
					''
				)}
				<p className='small-fs'>
					Publié par{' '}
					<Link
						to={`/recruteur/${emploi.recruteur_id}`}
						className='link recruteur'
					>{`${emploi.recruteur_prenom} ${emploi.recruteur_nom}`}</Link>
				</p>
				{user &&
					emploi.recruteur_id === user._id &&
					emploi.candidatures.length > 0 && (
						<>
							<hr />
							<h6 className='mt-3'>Candidatures:</h6>
							{emploi.candidatures.map((candidature) => (
								<div key={candidature._id}>
									<Card className='mb-3'>
										<Card.Body>
											<Card.Title>
												<Link
													to={`/candidat/${candidature.candidat_id}`}
													className='link'
												>{`${candidature.candidat_prenom} ${candidature.candidat_nom}`}</Link>
											</Card.Title>
											<Card.Subtitle className='mb-2 text-muted'>
												{moment(candidature.createdAt)
													.startOf('minute')
													.fromNow()}
											</Card.Subtitle>
											<Card.Text>{candidature.lettre_motivation}</Card.Text>
											<div className='mt-2'>
												<Confirm accepter='accepter' cand={candidature} />
												<Confirm refuser='refuser' cand={candidature} />
											</div>
										</Card.Body>
									</Card>
								</div>
							))}
						</>
					)}
				{Object.keys(emploi).length > 0 &&
					emploi.candidatures.map((candidature) =>
						user && candidature.candidat_id === user._id ? (
							<div key={candidature._id}>
								<hr />
								<h6 className='mt-3'>Votre candidature:</h6>
								<Card>
									<Card.Body>
										<Card.Title>
											<Link
												to={`/candidat/${candidature.candidat_id}`}
												className='link'
											>{`${candidature.candidat_prenom} ${candidature.candidat_nom}`}</Link>
										</Card.Title>
										<Card.Subtitle className='mb-2 text-muted'>
											{moment(candidature.createdAt)
												.startOf('minute')
												.fromNow()}
										</Card.Subtitle>
										<Card.Text>{candidature.lettre_motivation}</Card.Text>
										{candidature.etat === 'Accepté' && (
											<Alert variant='success' className='mt-2'>
												Votre candidature a été accepté.
											</Alert>
										)}
										{candidature.etat === 'Refusé' && (
											<Alert variant='danger' className='mt-2'>
												Votre candidature a été refusé.
											</Alert>
										)}
									</Card.Body>
								</Card>
							</div>
						) : (
							''
						)
					)}
			</Card>
		</Container>
	)
}

export default EmploiPage
