export const EMPLOI_LIST_REQUEST = 'EMPLOI_LIST_REQUEST'
export const EMPLOI_LIST_SUCCESS = 'EMPLOI_LIST_SUCCESS'
export const EMPLOI_LIST_FAIL = 'EMPLOI_LIST_FAIL'

export const EMPLOI_ADD_REQUEST = 'EMPLOI_ADD_REQUEST'
export const EMPLOI_ADD_SUCCESS = 'EMPLOI_ADD_SUCCESS'
export const EMPLOI_ADD_FAIL = 'EMPLOI_ADD_FAIL'

export const EMPLOI_UPDATE_REQUEST = 'EMPLOI_UPDATE_REQUEST'
export const EMPLOI_UPDATE_SUCCESS = 'EMPLOI_UPDATE_SUCCESS'
export const EMPLOI_UPDATE_FAIL = 'EMPLOI_UPDATE_FAIL'

export const EMPLOI_DELETE_REQUEST = 'EMPLOI_DELETE_REQUEST'
export const EMPLOI_DELETE_SUCCESS = 'EMPLOI_DELETE_SUCCESS'
export const EMPLOI_DELETE_FAIL = 'EMPLOI_DELETE_FAIL'

export const EMPLOI_DETAILS_REQUEST = 'EMPLOI_DETAILS_REQUEST'
export const EMPLOI_DETAILS_SUCCESS = 'EMPLOI_DETAILS_SUCCESS'
export const EMPLOI_DETAILS_FAIL = 'EMPLOI_DETAILS_FAIL'

export const EMPLOI_POSTULER_REQUEST = 'EMPLOI_POSTULER_REQUEST'
export const EMPLOI_POSTULER_SUCCESS = 'EMPLOI_POSTULER_SUCCESS'
export const EMPLOI_POSTULER_FAIL = 'EMPLOI_POSTULER_FAIL'

export const EMPLOI_CANDIDATURE_ACCEPTER_REQUEST =
	'EMPLOI_CANDIDATURE_ACCEPTER_REQUEST'
export const EMPLOI_CANDIDATURE_ACCEPTER_SUCCESS =
	'EMPLOI_CANDIDATURE_ACCEPTER_SUCCESS'
export const EMPLOI_CANDIDATURE_ACCEPTER_FAIL =
	'EMPLOI_CANDIDATURE_ACCEPTER_FAIL'

export const EMPLOI_CANDIDATURE_REFUSER_REQUEST =
	'EMPLOI_CANDIDATURE_REFUSER_REQUEST'
export const EMPLOI_CANDIDATURE_REFUSER_SUCCESS =
	'EMPLOI_CANDIDATURE_REFUSER_SUCCESS'
export const EMPLOI_CANDIDATURE_REFUSER_FAIL = 'EMPLOI_CANDIDATURE_REFUSER_FAIL'

export const EMPLOI_RESET = 'EMPLOI_RESET'
