import { useEffect } from 'react'
import { Table, Button, Container } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Loading from '../components/Loading'
import { emploiListAction } from '../actions/emploiActions'
import { EMPLOI_RESET } from '../constants/emploiConstants'
import Search from '../components/Search'
import PaginationComponent from '../components/PaginationComponent'

function AdminEmploisPage() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { page = 1, name } = useParams()

	const {
		emplois,
		success,
		error,
		loadingEmplois,
		message,
		pagesCount,
		currentPage,
		itemsPerPage,
	} = useSelector((state) => state.emploi)

	const searchEmplois = (value) => {
		if (value !== '') {
			navigate(`/admin-dashboard/emplois/name/${value}`)
		} else {
			navigate('/admin-dashboard/emplois')
			dispatch(emploiListAction(page))
		}
	}

	useEffect(() => {
		if (name !== undefined) {
			dispatch(emploiListAction(page, name))
		} else {
			dispatch(emploiListAction(page))
		}
	}, [dispatch, page, name])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
			navigate('/admin-dashboard/emplois')
		}
		if (success && message) {
			toast.success(message)
		}
		if (success || error) {
			dispatch({ type: EMPLOI_RESET })
		}
	}, [dispatch, navigate, success, error, message])

	return (
		<Container>
			<h5 className='mt-3'>Tous les emplois</h5>
			<Search searchEmplois={searchEmplois} />
			{loadingEmplois ? (
				<Loading />
			) : (
				<>
					<Table striped bordered hover variant='dark'>
						<thead>
							<tr>
								<th>ID</th>
								<th>Titre</th>
								<th>Publié par</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{emplois.map((emploi) => (
								<tr key={emploi._id}>
									<td>{emploi._id}</td>
									<td>{emploi.titre}</td>
									<td>{`${emploi.recruteur_prenom} ${emploi.recruteur_nom}`}</td>
									<td>
										<Button
											onClick={() => {
												navigate(`/admin-dashboard/emplois/${emploi._id}`)
											}}
										>
											Détail
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					<PaginationComponent
						pagesCount={pagesCount}
						currentPage={currentPage}
						itemsPerPage={itemsPerPage}
						name={name}
						adminDashboardEmplois='adminDashboardEmplois'
					/>
				</>
			)}
		</Container>
	)
}

export default AdminEmploisPage
