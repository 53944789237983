import { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import Confirm from './Confirm'

function Postuler({ emploi }) {
	const [values, setValues] = useState({
		lettre_motivation: '',
	})
	const [show, setShow] = useState(false)

	const { success, message } = useSelector((state) => state.candidat)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	useEffect(() => {
		if (success && message) {
			handleClose()
		}
	}, [success, message])

	return (
		<>
			<Button
				onClick={handleShow}
				disabled={emploi.etat !== 'disponible' && true}
			>
				Postuler
			</Button>{' '}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Postuler pour cette offre d'emploi</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<Form.Control
							as='textarea'
							placeholder='Lettre de motivation...'
							rows={10}
							name='lettre_motivation'
							value={values.lettre_motivation}
							onChange={onChange}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleClose}>
						Annuler
					</Button>
					<Confirm values={values} />
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default Postuler
