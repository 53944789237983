import {
	NOTIFICATION_MESSAGE_GET_REQUEST,
	NOTIFICATION_MESSAGE_GET_SUCCESS,
	NOTIFICATION_MESSAGE_GET_FAIL,
	NOTIFICATION_MESSAGE_ADD_REQUEST,
	NOTIFICATION_MESSAGE_ADD_SUCCESS,
	NOTIFICATION_MESSAGE_ADD_FAIL,
	NOTIFICATION_MESSAGE_REMOVE_REQUEST,
	NOTIFICATION_MESSAGE_REMOVE_SUCCESS,
	NOTIFICATION_MESSAGE_REMOVE_FAIL,
	NOTIFICATION_GET_REQUEST,
	NOTIFICATION_GET_SUCCESS,
	NOTIFICATION_GET_FAIL,
	NOTIFICATION_ADD_REQUEST,
	NOTIFICATION_ADD_SUCCESS,
	NOTIFICATION_ADD_FAIL,
	NOTIFICATION_REMOVE_REQUEST,
	NOTIFICATION_REMOVE_SUCCESS,
	NOTIFICATION_REMOVE_FAIL,
	NOTIFICATION_MESSAGE_ADD_TO_STORE,
	NOTIFICATION_ADD_TO_STORE,
	NOTIFICATION_RESET,
} from '../constants/notificationConstants'

export const notificationReducer = (
	state = { notifications_messages: [], notifications: [], notification: {} },
	action
) => {
	switch (action.type) {
		case NOTIFICATION_MESSAGE_GET_REQUEST:
		case NOTIFICATION_MESSAGE_ADD_REQUEST:
		case NOTIFICATION_MESSAGE_REMOVE_REQUEST:
		case NOTIFICATION_GET_REQUEST:
		case NOTIFICATION_ADD_REQUEST:
		case NOTIFICATION_REMOVE_REQUEST:
			return {
				...state,
				loading: true,
			}
		case NOTIFICATION_MESSAGE_GET_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				notifications_messages: action.payload,
			}
		case NOTIFICATION_MESSAGE_ADD_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				notifications_messages: [
					action.payload,
					...state.notifications_messages,
				],
			}
		case NOTIFICATION_MESSAGE_REMOVE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				notifications_messages: state.notifications_messages.filter(
					(nm) => nm._id !== action.payload
				),
			}
		case NOTIFICATION_GET_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				notifications: action.payload,
			}
		case NOTIFICATION_ADD_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				notification: action.payload,
				notifications: [action.payload, ...state.notifications],
			}
		case NOTIFICATION_REMOVE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				notifications: state.notifications.filter(
					(n) => n._id !== action.payload
				),
			}
		case NOTIFICATION_MESSAGE_GET_FAIL:
		case NOTIFICATION_MESSAGE_ADD_FAIL:
		case NOTIFICATION_MESSAGE_REMOVE_FAIL:
		case NOTIFICATION_GET_FAIL:
		case NOTIFICATION_ADD_FAIL:
		case NOTIFICATION_REMOVE_FAIL:
			return {
				...state,
				success: false,
				error: true,
				loading: false,
				message: action.payload,
			}
		case NOTIFICATION_MESSAGE_ADD_TO_STORE:
			return {
				...state,
				notifications_messages: [
					action.payload,
					...state.notifications_messages,
				],
			}
		case NOTIFICATION_ADD_TO_STORE:
			return {
				...state,
				notifications: [action.payload, ...state.notifications],
			}
		case NOTIFICATION_RESET:
			return {
				...state,
				success: false,
				error: false,
				loading: false,
				message: '',
			}
		default:
			return state
	}
}
