import { Card } from 'react-bootstrap'
import { FaGraduationCap } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import Diplome from './Diplome'
import AddDiplome from '../modals/AddDiplome'

function Diplomes({ diplomes }) {
	const { user } = useSelector((state) => state.auth)
	const { _id } = useSelector((state) => state.candidat)

	if (user && user._id !== _id && diplomes.length === 0) return
	if (!user && diplomes.length === 0) return

	return (
		<>
			<div className='col-md-6'>
				<Card className='mb-3'>
					<Card.Body>
						<Card.Title>
							<FaGraduationCap /> Diplômes:{' '}
							{user && user._id === _id && <AddDiplome />}
						</Card.Title>
						{diplomes.length > 0 &&
							diplomes.map((diplome) => (
								<Diplome key={diplome._id} diplome={diplome} />
							))}
					</Card.Body>
				</Card>
			</div>
		</>
	)
}

export default Diplomes
