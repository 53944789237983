export const CANDIDAT_REGISTER_REQUEST = 'CANDIDAT_REGISTER_REQUEST'
export const CANDIDAT_REGISTER_SUCCESS = 'CANDIDAT_REGISTER_SUCCESS'
export const CANDIDAT_REGISTER_FAIL = 'CANDIDAT_REGISTER_FAIL'

export const RECRUTEUR_REGISTER_REQUEST = 'RECRUTEUR_REGISTER_REQUEST'
export const RECRUTEUR_REGISTER_SUCCESS = 'RECRUTEUR_REGISTER_SUCCESS'
export const RECRUTEUR_REGISTER_FAIL = 'RECRUTEUR_REGISTER_FAIL'

export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST'
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS'
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL'
export const USER_SIGNOUT = 'USER_SIGNOUT'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'

export const USER_UPDATE_INFO = 'USER_UPDATE_INFO'

export const AUTH_RESET = 'AUTH_RESET'
