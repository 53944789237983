import { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { BsPlusLg } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { addRecruteurEntrepriseAction } from '../actions/recruteurActions'
import Loading from '../components/Loading'

function AddEntreprise() {
	const { recruteurId } = useParams()

	const [values, setValues] = useState({
		entreprise: '',
	})
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const dispatch = useDispatch()

	const { success, loading, message } = useSelector((state) => state.recruteur)

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const onClick = () => {
		dispatch(addRecruteurEntrepriseAction(recruteurId, values.entreprise))
	}

	useEffect(() => {
		if (success && message) {
			setValues({
				entreprise: '',
			})
			handleClose()
		}
	}, [success, message])

	return (
		<>
			<Button className='rounded-circle m-0 ms-1' onClick={handleShow}>
				<BsPlusLg />
			</Button>{' '}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Ajouter votre entreprise</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Entreprise'
							name='entreprise'
							value={values.entreprise}
							onChange={onChange}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleClose}>
						Annuler
					</Button>
					<Button type='submit' onClick={onClick} disabled={loading && true}>
						Ajouter {loading && <Loading sm='sm' />}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default AddEntreprise
