import { Pagination } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function PaginationComponent({
	pagesCount,
	currentPage,
	itemsPerPage,
	name,
	dashboard,
	dashboardCandidats,
	dashboardRecruteurs,
	adminDashboardCandidats,
	adminDashboardRecruteurs,
	adminDashboardEmplois,
}) {
	const navigate = useNavigate()

	// let interval = []

	// const itemsPerRange = 2
	// const ranges = Math.ceil(pagesCount / itemsPerRange)

	// const [currentRange, setCurrentRange] = useState(1)
	// const [firstItemOfCurrentRange, setFirstItemOfCurrentRange] = useState(1)

	// const aa = () => {
	// 	for (
	// 		var i = firstItemOfCurrentRange;
	// 		i < firstItemOfCurrentRange + itemsPerRange;
	// 		i++
	// 	) {
	// 		interval.push(i)
	// 	}
	// 	return interval
	// }
	// aa()

	return (
		<>
			{pagesCount > 1 && (
				<Pagination>
					{currentPage > 1 && (
						<Pagination.Prev
							onClick={() => {
								if (dashboard) {
									if (name !== undefined) {
										navigate(`/dashboard/page/${currentPage - 1}/name/${name}`)
									} else {
										navigate(`/dashboard/page/${currentPage - 1}`)
									}
								} else if (dashboardCandidats) {
									if (name !== undefined) {
										navigate(
											`/dashboard/candidats/page/${
												currentPage - 1
											}/name/${name}`
										)
									} else {
										navigate(`/dashboard/candidats/page/${currentPage - 1}`)
									}
								} else if (adminDashboardCandidats) {
									if (name !== undefined) {
										navigate(
											`/admin-dashboard/candidats/page/${
												currentPage - 1
											}/name/${name}`
										)
									} else {
										navigate(
											`/admin-dashboard/candidats/page/${currentPage - 1}`
										)
									}
								} else if (adminDashboardRecruteurs) {
									if (name !== undefined) {
										navigate(
											`/admin-dashboard/recruteurs/page/${
												currentPage - 1
											}/name/${name}`
										)
									} else {
										navigate(
											`/admin-dashboard/recruteurs/page/${currentPage - 1}`
										)
									}
								} else if (adminDashboardEmplois) {
									if (name !== undefined) {
										navigate(
											`/admin-dashboard/emplois/page/${
												currentPage - 1
											}/name/${name}`
										)
									} else {
										navigate(`/admin-dashboard/emplois/page/${currentPage - 1}`)
									}
								} else {
									if (name !== undefined) {
										navigate(
											`/dashboard/recruteurs/page/${
												currentPage - 1
											}/name/${name}`
										)
									} else {
										navigate(`/dashboard/recruteurs/page/${currentPage - 1}`)
									}
								}
							}}
						/>
					)}

					{/* {ranges > 1 && (
						<Pagination.Ellipsis onClick={() => {}} />
					)} */}

					{/* {interval.map((p) => { */}
					{[...Array(pagesCount).keys()].map((p) => {
						return (
							<Pagination.Item
								key={p + 1}
								// key={p}
								active={p + 1 === currentPage}
								// active={p === currentPage}
								onClick={() => {
									if (dashboard) {
										if (name !== undefined) {
											navigate(`/dashboard/page/${p + 1}/name/${name}`)
											// navigate(`/dashboard/page/${p}/name/${name}`)
										} else {
											navigate(`/dashboard/page/${p + 1}`)
											// navigate(`/dashboard/page/${p}`)
										}
									} else if (dashboardCandidats) {
										if (name !== undefined) {
											navigate(
												`/dashboard/candidats/page/${p + 1}/name/${name}`
											)
										} else {
											navigate(`/dashboard/candidats/page/${p + 1}`)
										}
									} else if (adminDashboardCandidats) {
										if (name !== undefined) {
											navigate(
												`/admin-dashboard/candidats/page/${p + 1}/name/${name}`
											)
										} else {
											navigate(`/admin-dashboard/candidats/page/${p + 1}`)
										}
									} else if (adminDashboardRecruteurs) {
										if (name !== undefined) {
											navigate(
												`/admin-dashboard/recruteurs/page/${p + 1}/name/${name}`
											)
										} else {
											navigate(`/admin-dashboard/recruteurs/page/${p + 1}`)
										}
									} else if (adminDashboardEmplois) {
										if (name !== undefined) {
											navigate(
												`/admin-dashboard/emplois/page/${p + 1}/name/${name}`
											)
										} else {
											navigate(`/admin-dashboard/emplois/page/${p + 1}`)
										}
									} else {
										if (name !== undefined) {
											navigate(
												`/dashboard/recruteurs/page/${p + 1}/name/${name}`
											)
										} else {
											navigate(`/dashboard/recruteurs/page/${p + 1}`)
										}
									}
								}}
							>
								{p + 1}
								{/* {p} */}
							</Pagination.Item>
						)
					})}

					{/* {ranges > 1 && (
						<Pagination.Ellipsis
							onClick={() => {
								// currentRange += 1
								// firstItemOfCurrentRange += itemsPerRange
								setCurrentRange(currentRange + 1)
								setFirstItemOfCurrentRange(
									firstItemOfCurrentRange + itemsPerRange
								)
								navigate(`/dashboard/page/${currentRange + itemsPerRange}`)
							}}
						/>
					)} */}

					{currentPage < pagesCount && (
						<Pagination.Next
							onClick={() => {
								if (dashboard) {
									if (name !== undefined) {
										navigate(`/dashboard/page/${currentPage + 1}/name/${name}`)
									} else {
										navigate(`/dashboard/page/${currentPage + 1}`)
									}
								} else if (dashboardCandidats) {
									if (name !== undefined) {
										navigate(
											`/dashboard/candidats/page/${
												currentPage + 1
											}/name/${name}`
										)
									} else {
										navigate(`/dashboard/candidats/page/${currentPage + 1}`)
									}
								} else if (adminDashboardCandidats) {
									if (name !== undefined) {
										navigate(
											`/admin-dashboard/candidats/page/${
												currentPage + 1
											}/name/${name}`
										)
									} else {
										navigate(
											`/admin-dashboard/candidats/page/${currentPage + 1}`
										)
									}
								} else if (adminDashboardRecruteurs) {
									if (name !== undefined) {
										navigate(
											`/admin-dashboard/recruteurs/page/${
												currentPage + 1
											}/name/${name}`
										)
									} else {
										navigate(
											`/admin-dashboard/recruteurs/page/${currentPage + 1}`
										)
									}
								} else if (adminDashboardEmplois) {
									if (name !== undefined) {
										navigate(
											`/admin-dashboard/emplois/page/${
												currentPage + 1
											}/name/${name}`
										)
									} else {
										navigate(`/admin-dashboard/emplois/page/${currentPage + 1}`)
									}
								} else {
									if (name !== undefined) {
										navigate(
											`/dashboard/recruteurs/page/${
												currentPage + 1
											}/name/${name}`
										)
									} else {
										navigate(`/dashboard/recruteurs/page/${currentPage + 1}`)
									}
								}
							}}
						/>
					)}
				</Pagination>
			)}
		</>
	)
}

export default PaginationComponent
