import axios from 'axios'
import {
	RECRUTEUR_DATA_REQUEST,
	RECRUTEUR_DATA_SUCCESS,
	RECRUTEUR_DATA_FAIL,
	RECRUTEUR_ADD_PROFILE_PHOTO_REQUEST,
	RECRUTEUR_ADD_PROFILE_PHOTO_SUCCESS,
	RECRUTEUR_ADD_PROFILE_PHOTO_FAIL,
	RECRUTEUR_DELETE_PROFILE_PHOTO_REQUEST,
	RECRUTEUR_DELETE_PROFILE_PHOTO_SUCCESS,
	RECRUTEUR_DELETE_PROFILE_PHOTO_FAIL,
	RECRUTEUR_ADD_ADRESSE_REQUEST,
	RECRUTEUR_ADD_ADRESSE_SUCCESS,
	RECRUTEUR_ADD_ADRESSE_FAIL,
	RECRUTEUR_GET_ALL_REQUEST,
	RECRUTEUR_GET_ALL_SUCCESS,
	RECRUTEUR_GET_ALL_FAIL,
	RECRUTEUR_EMPLOIS_REQUEST,
	RECRUTEUR_EMPLOIS_SUCCESS,
	RECRUTEUR_EMPLOIS_FAIL,
	RECRUTEUR_DELETE_REQUEST,
	RECRUTEUR_DELETE_SUCCESS,
	RECRUTEUR_DELETE_FAIL,
	RECRUTEUR_ADD_ENTREPRISE_REQUEST,
	RECRUTEUR_ADD_ENTREPRISE_SUCCESS,
	RECRUTEUR_ADD_ENTREPRISE_FAIL,
	RECRUTEUR_UPDATE_ENTREPRISE_REQUEST,
	RECRUTEUR_UPDATE_ENTREPRISE_SUCCESS,
	RECRUTEUR_UPDATE_ENTREPRISE_FAIL,
	RECRUTEUR_DELETE_ENTREPRISE_REQUEST,
	RECRUTEUR_DELETE_ENTREPRISE_SUCCESS,
	RECRUTEUR_DELETE_ENTREPRISE_FAIL,
} from '../constants/recruteurConstants'

export const getRecruteurDataAction =
	(recruteurId) => async (dispatch, getState) => {
		dispatch({ type: RECRUTEUR_DATA_REQUEST })
		try {
			// const {
			// 	user: { token },
			// } = getState().auth

			const { data } = await axios.get(
				`/recruteurs/${recruteurId}/data`
				// {
				// 	headers: {
				// 		Authorization: `Bearer ${token}`,
				// 	},
				// }
			)
			dispatch({
				type: RECRUTEUR_DATA_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: RECRUTEUR_DATA_FAIL,
				payload: message,
			})
		}
	}

// Profile photo
export const addRecruteurProfilePhotoAction =
	(recruteurId, profilePhoto) => async (dispatch, getState) => {
		dispatch({ type: RECRUTEUR_ADD_PROFILE_PHOTO_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.post(
				`/recruteurs/${recruteurId}/profile-photo`,
				profilePhoto,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: RECRUTEUR_ADD_PROFILE_PHOTO_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: RECRUTEUR_ADD_PROFILE_PHOTO_FAIL,
				payload: message,
			})
		}
	}

export const deleteRecruteurProfilePhotoAction =
	(recruteurId) => async (dispatch, getState) => {
		dispatch({ type: RECRUTEUR_DELETE_PROFILE_PHOTO_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.delete(
				`/recruteurs/${recruteurId}/profile-photo`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: RECRUTEUR_DELETE_PROFILE_PHOTO_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: RECRUTEUR_DELETE_PROFILE_PHOTO_FAIL,
				payload: message,
			})
		}
	}

// Adresse
export const addRecruteurAdresseAction =
	(recruteurId, adresse) => async (dispatch, getState) => {
		dispatch({ type: RECRUTEUR_ADD_ADRESSE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.put(
				`/recruteurs/${recruteurId}/address`,
				adresse,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: RECRUTEUR_ADD_ADRESSE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: RECRUTEUR_ADD_ADRESSE_FAIL,
				payload: message,
			})
		}
	}

export const getRecruteurAllAction =
	(page = '', name = '') =>
	async (dispatch, getState) => {
		dispatch({ type: RECRUTEUR_GET_ALL_REQUEST })
		try {
			const {
				admin: { token },
			} = getState().admin

			const url = name
				? `/recruteurs?page=${page}&name=${name}`
				: `/recruteurs?page=${page}`

			const { data } = await axios.get(url, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			dispatch({
				type: RECRUTEUR_GET_ALL_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: RECRUTEUR_GET_ALL_FAIL,
				payload: message,
			})
		}
	}

export const getRecruteurEmploisAction =
	(recruteurId) => async (dispatch, getState) => {
		dispatch({ type: RECRUTEUR_EMPLOIS_REQUEST })
		try {
			// const {
			// 	user: { token },
			// } = getState().auth

			const { data } = await axios.get(
				`/recruteurs/${recruteurId}/emplois`
				// {
				// 	headers: {
				// 		Authorization: `Bearer ${token}`,
				// 	},
				// }
			)
			dispatch({
				type: RECRUTEUR_EMPLOIS_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: RECRUTEUR_EMPLOIS_FAIL,
				payload: message,
			})
		}
	}

export const deleteRecruteurAction =
	(recruteurId) => async (dispatch, getState) => {
		dispatch({ type: RECRUTEUR_DELETE_REQUEST })
		try {
			const {
				admin: { token },
			} = getState().admin

			const { data } = await axios.delete(
				`/recruteurs/${recruteurId}/account`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: RECRUTEUR_DELETE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: RECRUTEUR_DELETE_FAIL,
				payload: message,
			})
		}
	}

export const addRecruteurEntrepriseAction =
	(recruteurId, entreprise) => async (dispatch, getState) => {
		dispatch({ type: RECRUTEUR_ADD_ENTREPRISE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.post(
				`/recruteurs/${recruteurId}/entreprise`,
				{ entreprise },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: RECRUTEUR_ADD_ENTREPRISE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: RECRUTEUR_ADD_ENTREPRISE_FAIL,
				payload: message,
			})
		}
	}

export const updateRecruteurEntrepriseAction =
	(recruteurId, entreprise) => async (dispatch, getState) => {
		dispatch({ type: RECRUTEUR_UPDATE_ENTREPRISE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.put(
				`/recruteurs/${recruteurId}/entreprise`,
				{ entreprise },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: RECRUTEUR_UPDATE_ENTREPRISE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: RECRUTEUR_UPDATE_ENTREPRISE_FAIL,
				payload: message,
			})
		}
	}

export const deleteRecruteurEntrepriseAction =
	(recruteurId) => async (dispatch, getState) => {
		dispatch({ type: RECRUTEUR_DELETE_ENTREPRISE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.delete(
				`/recruteurs/${recruteurId}/entreprise`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: RECRUTEUR_DELETE_ENTREPRISE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: RECRUTEUR_DELETE_ENTREPRISE_FAIL,
				payload: message,
			})
		}
	}
