import {
	DISCUSSION_ACCESS_REQUEST,
	DISCUSSION_ACCESS_SUCCESS,
	DISCUSSION_ACCESS_FAIL,
	DISCUSSION_GET_REQUEST,
	DISCUSSION_GET_SUCCESS,
	DISCUSSION_GET_FAIL,
	RESET_SELECTED_DISCUSSION,
	DISCUSSION_RESET,
} from '../constants/discussionConstants'

export const discussionReducer = (
	state = { discussions: [], discussion: {} },
	action
) => {
	switch (action.type) {
		case DISCUSSION_ACCESS_REQUEST:
		case DISCUSSION_GET_REQUEST:
			return {
				...state,
				loading: true,
			}
		case DISCUSSION_ACCESS_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				discussion: action.payload,
			}
		case DISCUSSION_GET_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				discussions: action.payload,
			}
		case DISCUSSION_ACCESS_FAIL:
		case DISCUSSION_GET_FAIL:
			return {
				...state,
				success: false,
				error: true,
				loading: false,
				message: action.payload.message,
			}
		case RESET_SELECTED_DISCUSSION:
			return {
				...state,
				discussion: {},
			}
		case DISCUSSION_RESET:
			return {
				...state,
				success: false,
				error: false,
				loading: false,
				message: '',
			}
		default:
			return state
	}
}
