import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import Candidature from '../components/Candidature'
import Loading from '../components/Loading'
import { candidatCandidaturesAction } from '../actions/candidatActions'
import { CANDIDAT_RESET } from '../constants/candidatConstants'

function CandidaturesPage() {
	const { candidatId } = useParams()

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { emplois, success, error, loading, message } = useSelector(
		(state) => state.candidat
	)

	useEffect(() => {
		dispatch(candidatCandidaturesAction(candidatId))
	}, [dispatch, candidatId])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
			navigate('/dashboard/candidats')
		}
		if (success && message) {
			toast.success(message)
		}
		if (success || error) {
			dispatch({ type: CANDIDAT_RESET })
		}
	}, [dispatch, navigate, success, error, message])

	if (loading) {
		return <Loading />
	}

	return (
		<Container className='w-75 mx-auto width-100'>
			<h5 className='mt-3'>Candidatures:</h5>
			{emplois.length > 0 ? (
				emplois.map((emploi) => (
					<Candidature key={emploi._id} emploi={emploi} />
				))
			) : (
				<p className='lead mt-3'>Vos candidatures vont s'afficher ici.</p>
			)}
		</Container>
	)
}

export default CandidaturesPage
