import axios from 'axios'
import {
	CANDIDAT_REGISTER_REQUEST,
	CANDIDAT_REGISTER_SUCCESS,
	CANDIDAT_REGISTER_FAIL,
	RECRUTEUR_REGISTER_REQUEST,
	RECRUTEUR_REGISTER_SUCCESS,
	RECRUTEUR_REGISTER_FAIL,
	USER_SIGNIN_REQUEST,
	USER_SIGNIN_SUCCESS,
	USER_SIGNIN_FAIL,
	USER_SIGNOUT,
	USER_UPDATE_REQUEST,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_FAIL,
	USER_UPDATE_INFO,
} from '../constants/authConstants'

export const candidatRegisterAction =
	(nom, prenom, email, password, passwordConfirm) => async (dispatch) => {
		dispatch({ type: CANDIDAT_REGISTER_REQUEST })
		try {
			const { data } = await axios.post('/candidats/register', {
				nom,
				prenom,
				email,
				password,
				passwordConfirm,
			})

			dispatch({
				type: CANDIDAT_REGISTER_SUCCESS,
				payload: data,
			})

			localStorage.setItem('user', JSON.stringify(data))
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: CANDIDAT_REGISTER_FAIL,
				payload: message,
			})
		}
	}

export const recruteurRegisterAction =
	(nom, prenom, email, password, passwordConfirm) => async (dispatch) => {
		dispatch({ type: RECRUTEUR_REGISTER_REQUEST })
		try {
			const { data } = await axios.post('/recruteurs/register', {
				nom,
				prenom,
				email,
				password,
				passwordConfirm,
			})

			dispatch({
				type: RECRUTEUR_REGISTER_SUCCESS,
				payload: data,
			})

			localStorage.setItem('user', JSON.stringify(data))
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: RECRUTEUR_REGISTER_FAIL,
				payload: message,
			})
		}
	}

export const userSigninAction = (email, password) => async (dispatch) => {
	dispatch({ type: USER_SIGNIN_REQUEST })
	try {
		const { data } = await axios.post('/auth/signin', {
			email,
			password,
		})

		dispatch({
			type: USER_SIGNIN_SUCCESS,
			payload: data,
		})

		localStorage.setItem('user', JSON.stringify(data))
	} catch (error) {
		const message = error.response.data.message
			? error.response.data.message
			: error.message
		dispatch({
			type: USER_SIGNIN_FAIL,
			payload: message,
		})
	}
}

export const userSignoutAction = () => async (dispatch) => {
	try {
		dispatch({
			type: USER_SIGNOUT,
		})

		localStorage.removeItem('user')
	} catch (error) {
		console.log(error)
	}
}

export const userUpdateAction =
	(userId, email, password, passwordConfirm) => async (dispatch, getState) => {
		dispatch({ type: USER_UPDATE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.put(
				`/auth/${userId}`,
				{
					email,
					password,
					passwordConfirm,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			dispatch({
				type: USER_UPDATE_SUCCESS,
				payload: data,
			})

			dispatch({
				type: USER_SIGNIN_SUCCESS,
				payload: data,
			})

			localStorage.setItem('user', JSON.stringify(data))
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: USER_UPDATE_FAIL,
				payload: message,
			})
		}
	}

export const userUpdateInfoAction =
	(nom, prenom, profilePhoto) => async (dispatch) => {
		const data = { nom, prenom, profilePhoto }
		dispatch({
			type: USER_UPDATE_INFO,
			payload: data,
		})

		const userFromLocalStorage = JSON.parse(localStorage.getItem('user'))
		userFromLocalStorage.nom = nom
		userFromLocalStorage.prenom = prenom
		userFromLocalStorage.profilePhoto = profilePhoto
		localStorage.setItem('user', JSON.stringify(userFromLocalStorage))
	}
