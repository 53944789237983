import axios from 'axios'
import {
	DISCUSSION_ACCESS_REQUEST,
	DISCUSSION_ACCESS_SUCCESS,
	DISCUSSION_ACCESS_FAIL,
	DISCUSSION_GET_REQUEST,
	DISCUSSION_GET_SUCCESS,
	DISCUSSION_GET_FAIL,
	RESET_SELECTED_DISCUSSION,
} from '../constants/discussionConstants'

export const accessDiscussionAction =
	(userId) => async (dispatch, getState) => {
		dispatch({ type: DISCUSSION_ACCESS_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.post(
				'/discussions/discussion',
				{ userId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: DISCUSSION_ACCESS_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: DISCUSSION_ACCESS_FAIL,
				payload: message,
			})
		}
	}

export const accessDiscussionAction2 =
	(discussionId) => async (dispatch, getState) => {
		dispatch({ type: DISCUSSION_ACCESS_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.get(`/discussions/${discussionId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			dispatch({
				type: DISCUSSION_ACCESS_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: DISCUSSION_ACCESS_FAIL,
				payload: message,
			})
		}
	}

export const getDiscussionsAction = () => async (dispatch, getState) => {
	dispatch({ type: DISCUSSION_GET_REQUEST })
	try {
		const {
			user: { token },
		} = getState().auth

		const { data } = await axios.get('/discussions', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		dispatch({
			type: DISCUSSION_GET_SUCCESS,
			payload: data,
		})
	} catch (error) {
		const message = error.response.data.message
			? error.response.data.message
			: error.message
		dispatch({
			type: DISCUSSION_GET_FAIL,
			payload: message,
		})
	}
}

export const resetSelectedDiscussion = () => async (dispatch) => {
	dispatch({ type: RESET_SELECTED_DISCUSSION })
}
