import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './reducers'

const initialState = {
	auth: {
		user: localStorage.getItem('user')
			? JSON.parse(localStorage.getItem('user'))
			: null,
	},
	admin: {
		admin: localStorage.getItem('admin')
			? JSON.parse(localStorage.getItem('admin'))
			: null,
	},
}

const middleware = [thunk]

const store = createStore(
	rootReducer,
	initialState,
	compose(
		applyMiddleware(...middleware)
		// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	)
)

export default store
