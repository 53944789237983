import { useEffect } from 'react'
import { Container, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { BsMessenger } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import {
	getRecruteurDataAction,
	getRecruteurEmploisAction,
} from '../actions/recruteurActions'
import ProfilePhoto from '../components/ProfilePhoto'
import Adresse from '../components/Adresse'
import AddAdresse from '../modals/AddAdresse'
import Loading from '../components/Loading'
import AddEmploi from '../modals/AddEmploi'
import Emploi from '../components/Emploi'
import Entreprise from '../components/Entreprise'
import { candidatGetSavedEmploiAction } from '../actions/candidatActions'
import { RECRUTEUR_RESET } from '../constants/recruteurConstants'
import { EMPLOI_RESET } from '../constants/emploiConstants'
import { CANDIDAT_RESET } from '../constants/candidatConstants'

function RecruteurProfilePage() {
	const { recruteurId } = useParams()

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const {
		_id,
		nom,
		prenom,
		profilePhoto,
		adresse,
		socialMedia,
		emplois: recruteurEmplois,
		entreprise,
		success,
		error,
		loadingData,
		message,
	} = useSelector((state) => state.recruteur)
	const {
		emplois,
		success: successEmploi,
		error: errorEmploi,
		message: messageEmploi,
	} = useSelector((state) => state.emploi)
	const { user } = useSelector((state) => state.auth)
	const { admin } = useSelector((state) => state.admin)
	const {
		emplois_saved,
		success: successCandidat,
		error: errorCandidat,
		message: messageCandidat,
	} = useSelector((state) => state.candidat)

	useEffect(() => {
		if (!user && !admin) {
			navigate('/signin')
		}
	}, [navigate, user, admin])

	useEffect(() => {
		dispatch(getRecruteurDataAction(recruteurId))
		dispatch(getRecruteurEmploisAction(recruteurId))
	}, [dispatch, recruteurId, emplois])

	useEffect(() => {
		if (user && user.user === 'Candidat') {
			dispatch(candidatGetSavedEmploiAction(user._id))
		}
	}, [dispatch, user])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
		}
		if (success && message) {
			toast.success(message)
		}
		if (success || error) {
			dispatch({ type: RECRUTEUR_RESET })
		}
		if (errorEmploi && messageEmploi) {
			toast.error(messageEmploi)
		}
		if (successEmploi && messageEmploi) {
			toast.success(messageEmploi)
		}
		if (successEmploi || errorEmploi) {
			dispatch({ type: EMPLOI_RESET })
		}
		if (errorCandidat && messageCandidat) {
			toast.error(messageCandidat)
		}
		if (successCandidat && messageCandidat) {
			toast.success(messageCandidat)
		}
		if (successCandidat || errorCandidat) {
			dispatch({ type: CANDIDAT_RESET })
		}
	}, [
		dispatch,
		success,
		error,
		message,
		successEmploi,
		errorEmploi,
		messageEmploi,
		successCandidat,
		errorCandidat,
		messageCandidat,
	])

	if (loadingData) {
		return <Loading />
	}

	return (
		<Container className='profile w-75 mx-auto width-100'>
			<div className='bg-dark my-4 p-3 d-flex flex-column justify-content-center align-items-center position-relative'>
				<ProfilePhoto profilePhoto={profilePhoto} nom={nom} prenom={prenom} />
				<h1>{`${prenom} ${nom}`}</h1>
				<Adresse adresse={adresse} socialMedia={socialMedia} />
				<div className='position-absolute top-0 end-0 translate-middle'>
					{user && user._id === _id ? (
						<AddAdresse
							nom={nom}
							prenom={prenom}
							adresse={adresse}
							socialMedia={socialMedia}
						/>
					) : user && user._id !== _id ? (
						<Button
							className='rounded-circle m-0'
							onClick={() => {
								navigate(`/contact/recruteur/${recruteurId}/discussions`)
							}}
						>
							<BsMessenger />
							{/* Contacter */}
						</Button>
					) : (
						''
					)}
				</div>
			</div>
			<Entreprise entreprise={entreprise} />
			{user && user._id === _id && (
				<div className='d-flex align-items-center'>
					<h5>Ajouter une offre d'emploi</h5> <AddEmploi />
				</div>
			)}
			{recruteurEmplois.length > 0 &&
				recruteurEmplois.map((emploi) => (
					<Emploi
						key={emploi._id}
						emploi={emploi}
						emplois_saved={emplois_saved}
					/>
				))}
		</Container>
	)
}

export default RecruteurProfilePage
