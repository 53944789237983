import { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { FaPen } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
// import moment from 'moment'
// import 'moment/locale/fr'

import { updateCandidatStageAction } from '../actions/candidatActions'
import Loading from '../components/Loading'

function UpdateStage({ stage }) {
	const { candidatId } = useParams()

	const [values, setValues] = useState({
		stage_titre: stage.stage_titre,
		etablissement: stage.etablissement,
		from: stage.duree.from,
		to: stage.duree.to,
		description: stage.description,
	})
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const dispatch = useDispatch()

	const { success, loading, message } = useSelector((state) => state.candidat)

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const onClick = () => {
		const updatedStage = {
			stage_titre: values.stage_titre,
			etablissement: values.etablissement,
			from: values.from,
			to: values.to,
			description: values.description,
		}

		dispatch(updateCandidatStageAction(candidatId, stage._id, updatedStage))
	}

	useEffect(() => {
		if (success && message) {
			handleClose()
		}
	}, [success, message])

	return (
		<>
			<Button className='rounded-circle m-0 ms-1' onClick={handleShow}>
				<FaPen />
			</Button>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Metter à jour</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Titre'
							name='stage_titre'
							value={values.stage_titre}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Etablissement'
							name='etablissement'
							value={values.etablissement}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Control
							type='date'
							name='from'
							value={values.from}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Control
							type='date'
							name='to'
							value={values.to}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<small className='text-muted'>(optionnel)</small>
						<Form.Control
							as='textarea'
							placeholder='Description'
							rows={10}
							name='description'
							value={values.description}
							onChange={onChange}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleClose}>
						Annuler
					</Button>
					<Button onClick={onClick} disabled={loading && true}>
						Enregistrer {loading && <Loading sm='sm' />}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default UpdateStage
