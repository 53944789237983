import { useEffect } from 'react'
import { Table, Button, Container } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Loading from '../components/Loading'
import { getCandidatAllAction } from '../actions/candidatActions'
import { CANDIDAT_RESET } from '../constants/candidatConstants'
import Search from '../components/Search'
import PaginationComponent from '../components/PaginationComponent'

function AdminCandidatsPage() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { page = 1, name } = useParams()

	const {
		candidats,
		success,
		error,
		loading,
		message,
		pagesCount,
		currentPage,
		itemsPerPage,
	} = useSelector((state) => state.candidat)

	const searchCandidats = (value) => {
		if (value !== '') {
			navigate(`/admin-dashboard/candidats/name/${value}`)
		} else {
			navigate('/admin-dashboard/candidats')
			dispatch(getCandidatAllAction(page))
		}
	}

	useEffect(() => {
		if (name !== undefined) {
			dispatch(getCandidatAllAction(page, name))
		} else {
			dispatch(getCandidatAllAction(page))
		}
	}, [dispatch, page, name])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
			navigate('/admin-dashboard/candidats')
		}
		if (success && message) {
			toast.success(message)
		}
		if (success || error) {
			dispatch({ type: CANDIDAT_RESET })
		}
	}, [dispatch, navigate, success, error, message])

	return (
		<Container>
			<h5 className='mt-3'>Tous les candidats</h5>
			<Search searchCandidats={searchCandidats} />
			{loading ? (
				<Loading />
			) : (
				<>
					<Table striped bordered hover variant='dark'>
						<thead>
							<tr>
								<th>ID</th>
								<th>Photo</th>
								<th>Prénom</th>
								<th>Nom</th>
								<th>Email</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{candidats.map((candidat) => (
								<tr key={candidat._id}>
									<td>{candidat._id}</td>
									<td>
										<img
											src={candidat.profilePhoto}
											alt=''
											className='dropdown-image'
										/>
									</td>
									<td>{candidat.prenom}</td>
									<td>{candidat.nom}</td>
									<td>{candidat.email}</td>
									<td>
										<Button
											onClick={() => {
												navigate(`/admin-dashboard/candidats/${candidat._id}`)
											}}
										>
											Détail
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					<PaginationComponent
						pagesCount={pagesCount}
						currentPage={currentPage}
						itemsPerPage={itemsPerPage}
						name={name}
						adminDashboardCandidats='adminDashboardCandidats'
					/>
				</>
			)}
		</Container>
	)
}

export default AdminCandidatsPage
