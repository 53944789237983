export const DISCUSSION_ACCESS_REQUEST = 'DISCUSSION_ACCESS_REQUEST'
export const DISCUSSION_ACCESS_SUCCESS = 'DISCUSSION_ACCESS_SUCCESS'
export const DISCUSSION_ACCESS_FAIL = 'DISCUSSION_ACCESS_FAIL'

export const DISCUSSION_GET_REQUEST = 'DISCUSSION_GET_REQUEST'
export const DISCUSSION_GET_SUCCESS = 'DISCUSSION_GET_SUCCESS'
export const DISCUSSION_GET_FAIL = 'DISCUSSION_GET_FAIL'

export const RESET_SELECTED_DISCUSSION = 'RESET_SELECTED_DISCUSSION'

export const DISCUSSION_RESET = 'DISCUSSION_RESET'
