import { useSelector } from 'react-redux'
import { ProgressBar } from 'react-bootstrap'

import Delete from '../modals/Delete'
import UpdateLangue from '../modals/UpdateLangue'

function Langue({ langue }) {
	const { user } = useSelector((state) => state.auth)
	const { _id } = useSelector((state) => state.candidat)

	return (
		<div className='dse mb-3 p-4 position-relative' key={langue._id}>
			<div className='d-flex justify-content-between align-items-center'>
				<div className='d-flex justify-content-between align-items-center'>
					<h6>{langue.nom}</h6>
					<div className='position-absolute top-0 end-0 translate-middle float-right'>
						{user && user._id === _id && (
							<>
								<UpdateLangue langue={langue} />
								<Delete langueId={langue._id} />
							</>
						)}
					</div>
				</div>
				{/* <p>{`${moment(langue.duree.from).format('L')} - ${moment(
					langue.duree.to
				).format('L')}`}</p> */}
			</div>
			{langue.niveau === 'debutant' ? (
				<>
					<p>Niveau: Débutant</p>
					{/* <ProgressBar now={25} /> */}
				</>
			) : langue.niveau === 'intermediaire' ? (
				<>
					<p>Niveau: Intermédiaire</p>
					{/* <ProgressBar now={60} /> */}
				</>
			) : langue.niveau === 'avance' ? (
				<>
					<p>Niveau: Avancé</p>
					{/* <ProgressBar now={90} /> */}
				</>
			) : (
				<>
					<p>Niveau: Maternelle ou Bilingue</p>
					{/* <ProgressBar now={100} /> */}
				</>
			)}
		</div>
	)
}

export default Langue
