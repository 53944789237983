import { useEffect } from 'react'
import { Container, Card, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import Loading from '../components/Loading'
import CandidatProfilePage from './CandidatProfilePage'
import { getCandidatDataAction } from '../actions/candidatActions'
import { CANDIDAT_RESET } from '../constants/candidatConstants'
import Delete from '../modals/Delete'
import ProfilePhoto from '../components/ProfilePhoto'
import Adresse from '../components/Adresse'
import Titre from '../components/Titre'
import Competences from '../components/Competences'
import Diplomes from '../components/Diplomes'
import Stages from '../components/Stages'
import Experiences from '../components/Experiences'
import Langues from '../components/Langues'

function AdminCandidatPage() {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { candidatId } = useParams()
	const {
		_id,
		nom,
		prenom,
		profilePhoto,
		adresse,
		socialMedia,
		titre,
		description,
		competences,
		diplomes,
		stages,
		experiences,
		langues,
		success,
		error,
		loading,
		message,
	} = useSelector((state) => state.candidat)

	useEffect(() => {
		if (candidatId) {
			dispatch(getCandidatDataAction(candidatId))
		}
	}, [dispatch, candidatId])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
		}
		if (success && message) {
			toast.success(message)
			navigate('/admin-dashboard/candidats')
		}
		if (success || error) {
			dispatch({ type: CANDIDAT_RESET })
		}
	}, [dispatch, navigate, success, error, message])

	if (loading) {
		return <Loading />
	}

	return (
		<>
			<Container className='w-75 mx-auto width-100 mb-3'>
				<div className='bg-dark my-4 p-3 d-flex flex-column justify-content-center align-items-center position-relative'>
					<ProfilePhoto profilePhoto={profilePhoto} nom={nom} prenom={prenom} />
					<h1>{`${prenom} ${nom}`}</h1>
					<Adresse adresse={adresse} socialMedia={socialMedia} />
				</div>
				<Titre titre={titre} description={description} />
				<Competences competences={competences} />
				<div className='row aligned-row'>
					<Diplomes diplomes={diplomes} />
					<Stages stages={stages} />
					<Experiences experiences={experiences} />
					<Langues langues={langues} />
				</div>
				<Delete candidatIdAdmin={candidatId} />
			</Container>
		</>
	)
}

export default AdminCandidatPage
