import { useState, useEffect } from 'react'
import { Form, InputGroup, Button, Container, Card } from 'react-bootstrap'
import { FaUserPlus, FaUserAlt } from 'react-icons/fa'
import { MdAlternateEmail } from 'react-icons/md'
import { RiLockPasswordFill } from 'react-icons/ri'
import { BiArrowBack } from 'react-icons/bi'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Loading from '../components/Loading'
import { recruteurRegisterAction } from '../actions/authActions'
import { AUTH_RESET } from '../constants/authConstants'

function RegisterPage() {
	const [values, setValues] = useState({
		nom: '',
		prenom: '',
		email: '',
		password: '',
		passwordConfirm: '',
	})

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { user, success, error, loading, message } = useSelector(
		(state) => state.auth
	)
	const { admin } = useSelector((state) => state.admin)

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const onSubmit = (e) => {
		e.preventDefault()
		dispatch(
			recruteurRegisterAction(
				values.nom,
				values.prenom,
				values.email,
				values.password,
				values.passwordConfirm
			)
		)
	}

	useEffect(() => {
		if (admin) {
			navigate('/admin-dashboard')
		}
	}, [navigate, admin])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
		}
		if (success || user) {
			navigate('/dashboard')
		}
		if (success || error) {
			dispatch({ type: AUTH_RESET })
		}
	}, [navigate, dispatch, user, success, error, message])

	return (
		<Container>
			<Card className='w-50 mx-auto width-100 p-3 my-3'>
				<Form onSubmit={onSubmit}>
					<h2 className='mb-3'>
						<Button
							variant='secondary'
							className='me-3'
							onClick={() => navigate(`/register`)}
						>
							<BiArrowBack />
						</Button>
						<FaUserPlus /> Créer un compte
					</h2>
					<p className='lead mb-3'>
						Vous êtes recruteur, vous cherchez un employé.
					</p>
					<InputGroup className='mb-3'>
						<Button variant='secondary'>
							<FaUserAlt className='icon' />
						</Button>
						<Form.Control
							type='text'
							placeholder='Nom'
							name='nom'
							value={values.nom}
							onChange={onChange}
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<Button variant='secondary'>
							<FaUserAlt className='icon' />
						</Button>
						<Form.Control
							type='text'
							placeholder='Prénom'
							name='prenom'
							value={values.prenom}
							onChange={onChange}
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<Button variant='secondary'>
							<MdAlternateEmail className='icon' />
						</Button>
						<Form.Control
							type='email'
							placeholder='Adresse e-mail'
							name='email'
							value={values.email}
							onChange={onChange}
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<Button variant='secondary'>
							<RiLockPasswordFill className='icon' />
						</Button>
						<Form.Control
							type='password'
							placeholder='Mot de passe'
							name='password'
							value={values.password}
							onChange={onChange}
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<Button variant='secondary'>
							<RiLockPasswordFill className='icon' />
						</Button>
						<Form.Control
							type='password'
							placeholder='Confirmez le mot de passe'
							name='passwordConfirm'
							value={values.passwordConfirm}
							onChange={onChange}
						/>
					</InputGroup>
					<Button
						variant='primary'
						type='submit'
						className='mb-3 btn w-100'
						disabled={loading && true}
					>
						S'inscrire {loading && <Loading sm='sm' />}
					</Button>
					<Form.Group className='mb-3'>
						Vous avez déjà un compte ? <Link to='/signin'>Se connecter</Link>
					</Form.Group>
				</Form>
			</Card>
		</Container>
	)
}

export default RegisterPage
