import { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { BsPlusLg } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { addCandidatDiplomeAction } from '../actions/candidatActions'
import Loading from '../components/Loading'

function AddDiplome() {
	const { candidatId } = useParams()

	const [values, setValues] = useState({
		domaine: '',
		etablissement: '',
		annee_obtention: '',
	})
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const dispatch = useDispatch()

	const { success, loading, message } = useSelector((state) => state.candidat)

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const onClick = () => {
		const diplome = {
			domaine: values.domaine,
			etablissement: values.etablissement,
			annee_obtention: values.annee_obtention,
		}

		dispatch(addCandidatDiplomeAction(candidatId, diplome))
	}

	useEffect(() => {
		if (success && message) {
			setValues({
				domaine: '',
				etablissement: '',
				annee_obtention: '',
			})
			handleClose()
		}
	}, [success, message])

	return (
		<>
			<Button className='rounded-circle m-0 ms-1' onClick={handleShow}>
				<BsPlusLg />
			</Button>{' '}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Ajouter un diplôme</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Domaine'
							name='domaine'
							value={values.domaine}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Etablissement'
							name='etablissement'
							value={values.etablissement}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder="Année d'obtention"
							name='annee_obtention'
							value={values.annee_obtention}
							onChange={onChange}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleClose}>
						Annuler
					</Button>
					<Button type='submit' onClick={onClick} disabled={loading && true}>
						Ajouter {loading && <Loading sm='sm' />}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default AddDiplome
