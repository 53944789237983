export const ADMIN_SIGNIN_REQUEST = 'ADMIN_SIGNIN_REQUEST'
export const ADMIN_SIGNIN_SUCCESS = 'ADMIN_SIGNIN_SUCCESS'
export const ADMIN_SIGNIN_FAIL = 'ADMIN_SIGNIN_FAIL'

export const ADMIN_CANDIDAT_COUNT_REQUEST = 'ADMIN_CANDIDAT_COUNT_REQUEST'
export const ADMIN_CANDIDAT_COUNT_SUCCESS = 'ADMIN_CANDIDAT_COUNT_SUCCESS'
export const ADMIN_CANDIDAT_COUNT_FAIL = 'ADMIN_CANDIDAT_COUNT_FAIL'

export const ADMIN_RECRUTEUR_COUNT_REQUEST = 'ADMIN_RECRUTEUR_COUNT_REQUEST'
export const ADMIN_RECRUTEUR_COUNT_SUCCESS = 'ADMIN_RECRUTEUR_COUNT_SUCCESS'
export const ADMIN_RECRUTEUR_COUNT_FAIL = 'ADMIN_RECRUTEUR_COUNT_FAIL'

export const ADMIN_EMPLOI_COUNT_REQUEST = 'ADMIN_EMPLOI_COUNT_REQUEST'
export const ADMIN_EMPLOI_COUNT_SUCCESS = 'ADMIN_EMPLOI_COUNT_SUCCESS'
export const ADMIN_EMPLOI_COUNT_FAIL = 'ADMIN_EMPLOI_COUNT_FAIL'

export const ADMIN_SIGNOUT = 'ADMIN_SIGNOUT'

export const ADMIN_RESET = 'ADMIN_RESET'
