import axios from 'axios'
import {
	ADMIN_SIGNIN_REQUEST,
	ADMIN_SIGNIN_SUCCESS,
	ADMIN_SIGNIN_FAIL,
	ADMIN_CANDIDAT_COUNT_REQUEST,
	ADMIN_CANDIDAT_COUNT_SUCCESS,
	ADMIN_CANDIDAT_COUNT_FAIL,
	ADMIN_RECRUTEUR_COUNT_REQUEST,
	ADMIN_RECRUTEUR_COUNT_SUCCESS,
	ADMIN_RECRUTEUR_COUNT_FAIL,
	ADMIN_EMPLOI_COUNT_REQUEST,
	ADMIN_EMPLOI_COUNT_SUCCESS,
	ADMIN_EMPLOI_COUNT_FAIL,
	ADMIN_SIGNOUT,
} from '../constants/adminConstants'

export const adminSigninAction = (email, password) => async (dispatch) => {
	dispatch({ type: ADMIN_SIGNIN_REQUEST })
	try {
		const { data } = await axios.post('/admin/signin', {
			email,
			password,
		})

		dispatch({
			type: ADMIN_SIGNIN_SUCCESS,
			payload: data,
		})

		localStorage.setItem('admin', JSON.stringify(data))
	} catch (error) {
		const message = error.response.data.message
			? error.response.data.message
			: error.message
		dispatch({
			type: ADMIN_SIGNIN_FAIL,
			payload: message,
		})
	}
}

export const getCandidatsCountAction = () => async (dispatch, getState) => {
	dispatch({ type: ADMIN_CANDIDAT_COUNT_REQUEST })
	try {
		const {
			admin: { token },
		} = getState().admin

		const { data } = await axios.get('/admin/candidats', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})

		dispatch({
			type: ADMIN_CANDIDAT_COUNT_SUCCESS,
			payload: data,
		})
	} catch (error) {
		const message = error.response.data.message
			? error.response.data.message
			: error.message
		dispatch({
			type: ADMIN_CANDIDAT_COUNT_FAIL,
			payload: message,
		})
	}
}

export const getRecruteursCountAction = () => async (dispatch, getState) => {
	dispatch({ type: ADMIN_RECRUTEUR_COUNT_REQUEST })
	try {
		const {
			admin: { token },
		} = getState().admin

		const { data } = await axios.get('/admin/recruteurs', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})

		dispatch({
			type: ADMIN_RECRUTEUR_COUNT_SUCCESS,
			payload: data,
		})
	} catch (error) {
		const message = error.response.data.message
			? error.response.data.message
			: error.message
		dispatch({
			type: ADMIN_RECRUTEUR_COUNT_FAIL,
			payload: message,
		})
	}
}

export const getEmploisCountAction = () => async (dispatch, getState) => {
	dispatch({ type: ADMIN_EMPLOI_COUNT_REQUEST })
	try {
		const {
			admin: { token },
		} = getState().admin

		const { data } = await axios.get('/admin/emplois', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})

		dispatch({
			type: ADMIN_EMPLOI_COUNT_SUCCESS,
			payload: data,
		})
	} catch (error) {
		const message = error.response.data.message
			? error.response.data.message
			: error.message
		dispatch({
			type: ADMIN_EMPLOI_COUNT_FAIL,
			payload: message,
		})
	}
}

export const adminSignoutAction = () => async (dispatch) => {
	try {
		dispatch({
			type: ADMIN_SIGNOUT,
		})

		localStorage.removeItem('admin')
	} catch (error) {
		console.log(error)
	}
}
