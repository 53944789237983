export const RECRUTEUR_DATA_REQUEST = 'RECRUTEUR_DATA_REQUEST'
export const RECRUTEUR_DATA_SUCCESS = 'RECRUTEUR_DATA_SUCCESS'
export const RECRUTEUR_DATA_FAIL = 'RECRUTEUR_DATA_FAIL'

export const RECRUTEUR_ADD_PROFILE_PHOTO_REQUEST =
	'RECRUTEUR_ADD_PROFILE_PHOTO_REQUEST'
export const RECRUTEUR_ADD_PROFILE_PHOTO_SUCCESS =
	'RECRUTEUR_ADD_PROFILE_PHOTO_SUCCESS'
export const RECRUTEUR_ADD_PROFILE_PHOTO_FAIL =
	'RECRUTEUR_ADD_PROFILE_PHOTO_FAIL'

export const RECRUTEUR_DELETE_PROFILE_PHOTO_REQUEST =
	'RECRUTEUR_DELETE_PROFILE_PHOTO_REQUEST'
export const RECRUTEUR_DELETE_PROFILE_PHOTO_SUCCESS =
	'RECRUTEUR_DELETE_PROFILE_PHOTO_SUCCESS'
export const RECRUTEUR_DELETE_PROFILE_PHOTO_FAIL =
	'RECRUTEUR_DELETE_PROFILE_PHOTO_FAIL'

export const RECRUTEUR_ADD_ADRESSE_REQUEST = 'RECRUTEUR_ADD_ADRESSE_REQUEST'
export const RECRUTEUR_ADD_ADRESSE_SUCCESS = 'RECRUTEUR_ADD_ADRESSE_SUCCESS'
export const RECRUTEUR_ADD_ADRESSE_FAIL = 'RECRUTEUR_ADD_ADRESSE_FAIL'

export const RECRUTEUR_GET_ALL_REQUEST = 'RECRUTEUR_GET_ALL_REQUEST'
export const RECRUTEUR_GET_ALL_SUCCESS = 'RECRUTEUR_GET_ALL_SUCCESS'
export const RECRUTEUR_GET_ALL_FAIL = 'RECRUTEUR_GET_ALL_FAIL'

export const RECRUTEUR_EMPLOIS_REQUEST = 'RECRUTEUR_EMPLOIS_REQUEST'
export const RECRUTEUR_EMPLOIS_SUCCESS = 'RECRUTEUR_EMPLOIS_SUCCESS'
export const RECRUTEUR_EMPLOIS_FAIL = 'RECRUTEUR_EMPLOIS_FAIL'

export const RECRUTEUR_DELETE_REQUEST = 'RECRUTEUR_DELETE_REQUEST'
export const RECRUTEUR_DELETE_SUCCESS = 'RECRUTEUR_DELETE_SUCCESS'
export const RECRUTEUR_DELETE_FAIL = 'RECRUTEUR_DELETE_FAIL'

export const RECRUTEUR_ADD_ENTREPRISE_REQUEST =
	'RECRUTEUR_ADD_ENTREPRISE_REQUEST'
export const RECRUTEUR_ADD_ENTREPRISE_SUCCESS =
	'RECRUTEUR_ADD_ENTREPRISE_SUCCESS'
export const RECRUTEUR_ADD_ENTREPRISE_FAIL = 'RECRUTEUR_ADD_ENTREPRISE_FAIL'

export const RECRUTEUR_UPDATE_ENTREPRISE_REQUEST =
	'RECRUTEUR_UPDATE_ENTREPRISE_REQUEST'
export const RECRUTEUR_UPDATE_ENTREPRISE_SUCCESS =
	'RECRUTEUR_UPDATE_ENTREPRISE_SUCCESS'
export const RECRUTEUR_UPDATE_ENTREPRISE_FAIL =
	'RECRUTEUR_UPDATE_ENTREPRISE_FAIL'

export const RECRUTEUR_DELETE_ENTREPRISE_REQUEST =
	'RECRUTEUR_DELETE_ENTREPRISE_REQUEST'
export const RECRUTEUR_DELETE_ENTREPRISE_SUCCESS =
	'RECRUTEUR_DELETE_ENTREPRISE_SUCCESS'
export const RECRUTEUR_DELETE_ENTREPRISE_FAIL =
	'RECRUTEUR_DELETE_ENTREPRISE_FAIL'

export const RECRUTEUR_RESET = 'RECRUTEUR_RESET'
