import {
	EMPLOI_LIST_REQUEST,
	EMPLOI_LIST_SUCCESS,
	EMPLOI_LIST_FAIL,
	EMPLOI_ADD_REQUEST,
	EMPLOI_ADD_SUCCESS,
	EMPLOI_ADD_FAIL,
	EMPLOI_UPDATE_REQUEST,
	EMPLOI_UPDATE_SUCCESS,
	EMPLOI_UPDATE_FAIL,
	EMPLOI_DELETE_REQUEST,
	EMPLOI_DELETE_SUCCESS,
	EMPLOI_DELETE_FAIL,
	EMPLOI_DETAILS_REQUEST,
	EMPLOI_DETAILS_SUCCESS,
	EMPLOI_DETAILS_FAIL,
	EMPLOI_POSTULER_REQUEST,
	EMPLOI_POSTULER_SUCCESS,
	EMPLOI_POSTULER_FAIL,
	EMPLOI_CANDIDATURE_ACCEPTER_REQUEST,
	EMPLOI_CANDIDATURE_ACCEPTER_SUCCESS,
	EMPLOI_CANDIDATURE_ACCEPTER_FAIL,
	EMPLOI_CANDIDATURE_REFUSER_REQUEST,
	EMPLOI_CANDIDATURE_REFUSER_SUCCESS,
	EMPLOI_CANDIDATURE_REFUSER_FAIL,
	EMPLOI_RESET,
} from '../constants/emploiConstants'

export const emploiReducer = (
	state = { emplois: [], emploi: {}, candidature: {} },
	action
) => {
	switch (action.type) {
		case EMPLOI_LIST_REQUEST:
			return {
				...state,
				loadingEmplois: true,
			}
		case EMPLOI_LIST_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loadingEmplois: false,
				emplois: action.payload.emplois,
				pagesCount: action.payload.pagesCount,
				currentPage: action.payload.currentPage,
				itemsPerPage: action.payload.itemsPerPage,
			}
		case EMPLOI_LIST_FAIL:
			return {
				...state,
				success: false,
				error: true,
				loadingEmplois: false,
				message: action.payload,
			}
		case EMPLOI_ADD_REQUEST:
		case EMPLOI_UPDATE_REQUEST:
		case EMPLOI_DELETE_REQUEST:
		case EMPLOI_DETAILS_REQUEST:
		case EMPLOI_POSTULER_REQUEST:
		case EMPLOI_CANDIDATURE_ACCEPTER_REQUEST:
		case EMPLOI_CANDIDATURE_REFUSER_REQUEST:
			return {
				...state,
				loading: true,
			}
		case EMPLOI_ADD_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				emplois: [action.payload.emploi, ...state.emplois],
			}
		case EMPLOI_ADD_FAIL:
		case EMPLOI_UPDATE_FAIL:
		case EMPLOI_DELETE_FAIL:
		case EMPLOI_DETAILS_FAIL:
		case EMPLOI_POSTULER_FAIL:
		case EMPLOI_CANDIDATURE_ACCEPTER_FAIL:
		case EMPLOI_CANDIDATURE_REFUSER_FAIL:
			return {
				...state,
				success: false,
				error: true,
				loading: false,
				message: action.payload,
			}
		case EMPLOI_UPDATE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				emplois: state.emplois.map((emploi) =>
					emploi._id === action.payload.emploi._id
						? {
								...emploi,
								titre: action.payload.emploi.titre,
								description: action.payload.emploi.description,
								etat: action.payload.emploi.etat,
								adresse: action.payload.emploi.adresse,
								salaire: action.payload.emploi.salaire,
								nombre_heure: action.payload.emploi.nombre_heure,
						  }
						: emploi
				),
			}
		case EMPLOI_DELETE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				emplois: state.emplois.filter(
					(emploi) => emploi._id !== action.payload._id
				),
				recruteur_id: action.payload.recruteur_id,
			}
		case EMPLOI_DETAILS_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				emploi: action.payload,
			}
		case EMPLOI_POSTULER_SUCCESS:
		case EMPLOI_CANDIDATURE_ACCEPTER_SUCCESS:
		case EMPLOI_CANDIDATURE_REFUSER_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				// candidatures: [action.payload.candidature, ...state.candidatures],
				candidature: action.payload.candidature,
				emplois: state.emplois.map((emploi) =>
					emploi._id === action.payload.emploi._id
						? {
								...emploi,
								candidatures: action.payload.emploi.candidatures,
						  }
						: emploi
				),
				emploi: action.payload.emploi,
			}
		case EMPLOI_RESET:
			return {
				...state,
				success: false,
				error: false,
				loading: false,
				loadingEmplois: false,
				message: '',
			}
		default:
			return state
	}
}
