import {
	CANDIDAT_REGISTER_REQUEST,
	CANDIDAT_REGISTER_SUCCESS,
	CANDIDAT_REGISTER_FAIL,
	RECRUTEUR_REGISTER_REQUEST,
	RECRUTEUR_REGISTER_SUCCESS,
	RECRUTEUR_REGISTER_FAIL,
	USER_SIGNIN_REQUEST,
	USER_SIGNIN_SUCCESS,
	USER_SIGNIN_FAIL,
	USER_SIGNOUT,
	USER_UPDATE_REQUEST,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_FAIL,
	USER_UPDATE_INFO,
	AUTH_RESET,
} from '../constants/authConstants'

export const authReducer = (state = {}, action) => {
	switch (action.type) {
		case CANDIDAT_REGISTER_REQUEST:
		case RECRUTEUR_REGISTER_REQUEST:
		case USER_SIGNIN_REQUEST:
		case USER_UPDATE_REQUEST:
			return {
				...state,
				loading: true,
			}
		case CANDIDAT_REGISTER_SUCCESS:
		case RECRUTEUR_REGISTER_SUCCESS:
		case USER_SIGNIN_SUCCESS:
		case USER_UPDATE_SUCCESS:
			return {
				...state,
				success: true,
				loading: false,
				user: action.payload,
			}
		case CANDIDAT_REGISTER_FAIL:
		case RECRUTEUR_REGISTER_FAIL:
		case USER_SIGNIN_FAIL:
		case USER_UPDATE_FAIL:
			return {
				...state,
				success: false,
				error: true,
				loading: false,
				message: action.payload,
			}
		case AUTH_RESET:
			return {
				...state,
				success: false,
				error: false,
				loading: false,
				message: '',
			}
		case USER_UPDATE_INFO:
			state.user.nom = action.payload.nom
			state.user.prenom = action.payload.prenom
			state.user.profilePhoto = action.payload.profilePhoto
			return {
				...state,
			}
		case USER_SIGNOUT:
			return {}
		default:
			return state
	}
}
