import { ExternalLink } from 'react-external-link'
import {
	FaFacebook,
	FaTwitter,
	FaInstagram,
	FaLinkedin,
	FaYoutube,
	FaGithub,
} from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md'

function Adresse({ adresse, socialMedia }) {
	return (
		<div className='d-flex flex-column justify-content-center align-items-center'>
			<p className='mb-2'>
				{adresse && (
					<>
						<MdLocationOn /> {adresse}
					</>
				)}
			</p>
			<div>
				{socialMedia.facebook && (
					<ExternalLink href={socialMedia.facebook} className='social-links'>
						<FaFacebook size={28} />
					</ExternalLink>
				)}
				{socialMedia.twitter && (
					<ExternalLink href={socialMedia.twitter} className='social-links'>
						<FaTwitter size={28} />
					</ExternalLink>
				)}
				{socialMedia.instagram && (
					<ExternalLink href={socialMedia.instagram} className='social-links'>
						<FaInstagram size={28} />
					</ExternalLink>
				)}
				{socialMedia.linkedin && (
					<ExternalLink href={socialMedia.linkedin} className='social-links'>
						<FaLinkedin size={28} />
					</ExternalLink>
				)}
				{socialMedia.youtube && (
					<ExternalLink href={socialMedia.youtube} className='social-links'>
						<FaYoutube size={28} />
					</ExternalLink>
				)}
				{socialMedia.github && (
					<ExternalLink href={socialMedia.github} className='social-links'>
						<FaGithub size={28} />
					</ExternalLink>
				)}
			</div>
		</div>
	)
}

export default Adresse
