import { useEffect } from 'react'
import { Container, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { BsMessenger } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import { getCandidatDataAction } from '../actions/candidatActions'
import ProfilePhoto from '../components/ProfilePhoto'
import Adresse from '../components/Adresse'
import Titre from '../components/Titre'
import Competences from '../components/Competences'
import Diplomes from '../components/Diplomes'
import Stages from '../components/Stages'
import Experiences from '../components/Experiences'
import Langues from '../components/Langues'
import AddAdresse from '../modals/AddAdresse'
import Loading from '../components/Loading'
import { CANDIDAT_RESET } from '../constants/candidatConstants'

function CandidatProfilePage() {
	const { candidatId } = useParams()

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const {
		_id,
		nom,
		prenom,
		profilePhoto,
		adresse,
		socialMedia,
		titre,
		description,
		competences,
		diplomes,
		stages,
		experiences,
		langues,
		success,
		error,
		loadingData,
		message,
	} = useSelector((state) => state.candidat)

	const { user } = useSelector((state) => state.auth)
	const { admin } = useSelector((state) => state.admin)

	useEffect(() => {
		if (!user && !admin) {
			navigate('/signin')
		}
	}, [navigate, user, admin])

	useEffect(() => {
		dispatch(getCandidatDataAction(candidatId))
	}, [dispatch, candidatId])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
		}
		if (success && message) {
			toast.success(message)
		}
		if (success || error) {
			dispatch({ type: CANDIDAT_RESET })
		}
	}, [dispatch, success, error, message])

	if (loadingData) {
		return <Loading />
	}

	return (
		<Container className='profile w-75 mx-auto width-100'>
			<div className='bg-dark my-4 p-3 d-flex flex-column justify-content-center align-items-center position-relative'>
				<ProfilePhoto profilePhoto={profilePhoto} nom={nom} prenom={prenom} />
				<h1>{`${prenom} ${nom}`}</h1>
				<Adresse adresse={adresse} socialMedia={socialMedia} />
				<div className='position-absolute top-0 end-0 translate-middle'>
					{user && user._id === _id ? (
						<AddAdresse
							nom={nom}
							prenom={prenom}
							adresse={adresse}
							socialMedia={socialMedia}
						/>
					) : user && user._id !== _id ? (
						<Button
							className='rounded-circle m-0'
							onClick={() => {
								navigate(`/contact/candidat/${candidatId}/discussions`)
							}}
						>
							<BsMessenger />
						</Button>
					) : (
						''
					)}
				</div>
			</div>
			<Titre titre={titre} description={description} />
			<Competences competences={competences} />
			<div className='row aligned-row'>
				<Diplomes diplomes={diplomes} />
				<Stages stages={stages} />
				<Experiences experiences={experiences} />
				<Langues langues={langues} />
			</div>
		</Container>
	)
}

export default CandidatProfilePage
