import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

function AdminRoute({ children }) {
	const { admin } = useSelector((state) => state.admin)

	if (admin) {
		return children
	}

	return <Navigate to='/admin' />
}

export default AdminRoute
