import { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { FaPen } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
// import moment from 'moment'
// import 'moment/locale/fr'

import { updateCandidatDiplomeAction } from '../actions/candidatActions'
import Loading from '../components/Loading'

function UpdateDiplome({ diplome }) {
	const { candidatId } = useParams()

	const [values, setValues] = useState({
		domaine: diplome.domaine,
		etablissement: diplome.etablissement,
		annee_obtention: diplome.annee_obtention,
	})
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const dispatch = useDispatch()

	const { success, loading, message } = useSelector((state) => state.candidat)

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const onClick = () => {
		const updatedDiplome = {
			domaine: values.domaine,
			etablissement: values.etablissement,
			annee_obtention: values.annee_obtention,
		}

		dispatch(
			updateCandidatDiplomeAction(candidatId, diplome._id, updatedDiplome)
		)
	}

	useEffect(() => {
		if (success && message) {
			handleClose()
		}
	}, [success, message])

	return (
		<>
			<Button className='rounded-circle m-0 ms-1' onClick={handleShow}>
				<FaPen />
			</Button>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Metter à jour</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Domaine'
							name='domaine'
							value={values.domaine}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Etablissement'
							name='etablissement'
							value={values.etablissement}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder="Année d'obtention"
							name='annee_obtention'
							// value={`${moment(diplome.duree.annee_obtention).locale('fr').format('L')}`}
							value={values.annee_obtention}
							onChange={onChange}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleClose}>
						Annuler
					</Button>
					<Button onClick={onClick} disabled={loading && true}>
						Enregistrer {loading && <Loading sm='sm' />}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default UpdateDiplome
