import axios from 'axios'
import {
	MESSAGE_SEND_REQUEST,
	MESSAGE_SEND_SUCCESS,
	MESSAGE_SEND_FAIL,
	MESSAGE_GET_REQUEST,
	MESSAGE_GET_SUCCESS,
	MESSAGE_GET_FAIL,
	MESSAGE_ADD,
} from '../constants/messageConstants'
import { getReciever } from '../utils/discussion'

export const sendMessageAction =
	(userId, text, user2, socket) => async (dispatch, getState) => {
		dispatch({ type: MESSAGE_SEND_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.post(
				'/messages',
				{ text, userId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			socket.emit('new message', data)
			dispatch({
				type: MESSAGE_SEND_SUCCESS,
				payload: data,
			})

			const userId2 = getReciever(user2, data?.discussion?.users)._id
			const res = await axios.post(
				`/notifications/${userId2}/notification-message`,
				{
					text: `Nouveau message de ${data.sender?.prenom} ${data.sender?.nom}`,
					discussion: data.discussion._id,
				}
				// {
				// 	headers: {
				// 		Authorization: `Bearer ${token}`,
				// 	},
				// }
			)
			socket.emit('new message notification', res.data, userId2)
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: MESSAGE_SEND_FAIL,
				payload: message,
			})
		}
	}

export const sendMessageAction2 =
	(discussionId, text, user2, socket) => async (dispatch, getState) => {
		dispatch({ type: MESSAGE_SEND_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.post(
				`/messages/${discussionId}`,
				{ text },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			socket.emit('new message', data)
			dispatch({
				type: MESSAGE_SEND_SUCCESS,
				payload: data,
			})

			const userId2 = getReciever(user2, data?.discussion?.users)._id
			const res = await axios.post(
				`/notifications/${userId2}/notification-message`,
				{
					text: `Nouveau message de ${data.sender?.prenom} ${data.sender?.nom}`,
					discussion: data.discussion._id,
				}
				// {
				// 	headers: {
				// 		Authorization: `Bearer ${token}`,
				// 	},
				// }
			)
			socket.emit('new message notification', res.data, userId2)
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: MESSAGE_SEND_FAIL,
				payload: message,
			})
		}
	}

export const getMessagesAction = (userId) => async (dispatch, getState) => {
	dispatch({ type: MESSAGE_GET_REQUEST })
	try {
		const {
			user: { token },
		} = getState().auth

		const { data } = await axios.post(
			'/messages/message',
			{ userId },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)
		dispatch({
			type: MESSAGE_GET_SUCCESS,
			payload: data,
		})
	} catch (error) {
		const message = error.response.data.message
			? error.response.data.message
			: error.message
		dispatch({
			type: MESSAGE_GET_FAIL,
			payload: message,
		})
	}
}

export const getMessagesAction2 =
	(discussionId) => async (dispatch, getState) => {
		dispatch({ type: MESSAGE_GET_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.get(`/messages/${discussionId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			dispatch({
				type: MESSAGE_GET_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: MESSAGE_GET_FAIL,
				payload: message,
			})
		}
	}

export const addMessageAction = (message) => async (dispatch) => {
	if (message) {
		dispatch({ type: MESSAGE_ADD, payload: message })
		return
	}
}
