import { useState } from 'react'
import { Form, Container, Button, InputGroup, ListGroup } from 'react-bootstrap'
import { MdClose, MdSearch } from 'react-icons/md'

function Search({ searchEmplois, emplois, searchCandidats, searchRecruteurs }) {
	const [value, setValue] = useState('')

	// const [filteredData, setFilteredData] = useState([])

	// const onChange = (e) => {
	// 	const searchWord = e.target.value
	// 	setValue(searchWord)
	// 	const newFilter = emplois.filter((emploi) => {
	// 		return emploi.titre.toLowerCase().includes(searchWord.toLowerCase())
	// 	})

	// 	if (searchWord === '') {
	// 		setFilteredData([])
	// 	} else {
	// 		setFilteredData(newFilter)
	// 	}
	// }

	// const onClick = (word) => {
	// 	search(word)
	// 	setFilteredData([])
	// 	setValue(word)
	// }

	// const clearInput = () => {
	// 	setValue('')
	// 	setFilteredData([])
	// }

	const onChange = (e) => {
		setValue(e.target.value)
	}

	const onSubmit = (e) => {
		e.preventDefault()

		if (searchEmplois) {
			searchEmplois(value)
		} else if (searchCandidats) {
			searchCandidats(value)
		} else {
			searchRecruteurs(value)
		}
	}

	return (
		<Container className='p-0'>
			<Form onSubmit={onSubmit} className='mb-3 mt-4'>
				<InputGroup>
					<Form.Control
						type='value'
						placeholder='Rechercher...'
						name='value'
						value={value}
						onChange={onChange}
						autoComplete='off'
					/>
					{/* {filteredData.length > 0 ? ( */}
					{/* <MdClose className='icon' onClick={clearInput} /> */}
					{/* ) : ( */}
					{/* <MdSearch className='icon' onClick={() => onClick(value)} /> */}
					{/* )} */}
					<Button type='submit'>
						<MdSearch className='icon' />
					</Button>
				</InputGroup>
			</Form>
			{/* {filteredData.length > 0 && (
				<ListGroup>
					{filteredData.slice(0, 10).map((emploi, index) => (
						<ListGroup.Item key={index} onClick={() => onClick(emploi.titre)}>
							{emploi.titre}
						</ListGroup.Item>
					))}
				</ListGroup>
			)} */}
		</Container>
	)
}

export default Search
