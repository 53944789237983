import { useSelector } from 'react-redux'
import { Card } from 'react-bootstrap'
import { GiSkills } from 'react-icons/gi'
import { BiCheck } from 'react-icons/bi'

import Delete from '../modals/Delete'
import AddCompetences from '../modals/AddCompetences'

function Competences({ competences }) {
	const { user } = useSelector((state) => state.auth)
	const { _id } = useSelector((state) => state.candidat)

	if (user && user._id !== _id && competences.length === 0) return
	if (!user && competences.length === 0) return

	return (
		<>
			<Card className='mb-3'>
				<Card.Body>
					<Card.Title>
						<GiSkills />
						Compétences:{' '}
						{user && user._id === _id && competences.length > 0 ? (
							<div className='position-absolute top-0 end-0 translate-middle'>
								{user && user._id === _id && (
									<AddCompetences competences={competences} />
								)}
							</div>
						) : user && user._id === _id && competences.length === 0 ? (
							<AddCompetences />
						) : (
							''
						)}
					</Card.Title>
					<div className='d-flex flex-wrap'>
						{competences.map((competence, index) => (
							<div
								className='rounded-pill py-1 px-3 mx-1 mb-2 competence'
								key={index}
							>
								<BiCheck /> {competence}
								{user && user._id === _id && <Delete index={index} />}
							</div>
						))}
					</div>
				</Card.Body>
			</Card>
		</>
	)
}

export default Competences
