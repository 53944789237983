import { Spinner } from 'react-bootstrap'

function Loading({ sm }) {
	return (
		<>
			{sm ? (
				<Spinner animation='border' role='status' size={sm} />
			) : (
				<div className='d-flex justify-content-center align-items-center'>
					<Spinner animation='border' role='status' size={sm && sm} />
				</div>
			)}
		</>
	)
}

export default Loading
