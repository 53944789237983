import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FaTrash } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import io from 'socket.io-client'

import {
	deleteCandidatDiplomeAction,
	deleteCandidatStageAction,
	deleteCandidatExperienceAction,
	deleteCandidatLangueAction,
	deleteCandidatCompetencesAction,
	deleteCandidatTitreAction,
	deleteCandidatProfilePhotoAction,
	deleteCandidatAction,
} from '../actions/candidatActions'
import {
	deleteRecruteurProfilePhotoAction,
	deleteRecruteurAction,
	deleteRecruteurEntrepriseAction,
} from '../actions/recruteurActions'
import { deleteEmploiAction } from '../actions/emploiActions'
import { addNotificationEmploiDeleteAction } from '../actions/notificationActions'
import Loading from '../components/Loading'

// const ENDPOINT = 'http://localhost:5000'
const ENDPOINT = 'https://recruttech.herokuapp.com'
let socket

function Delete({
	diplomeId,
	stageId,
	experienceId,
	langueId,
	index,
	titre,
	profilePhoto,
	emploiId,
	candidatIdAdmin,
	recruteurIdAdmin,
	emploiIdAdmin,
	entreprise,
}) {
	const { candidatId, recruteurId, emploiId: empId } = useParams()

	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const dispatch = useDispatch()

	const {
		success: successCandidat,
		loading: loadingCandidat,
		message: messageCandidat,
	} = useSelector((state) => state.candidat)
	const {
		success: successRecruteur,
		loading: loadingRecruteur,
		message: messageRecruteur,
	} = useSelector((state) => state.recruteur)
	const { emploi, success, loading, message } = useSelector(
		(state) => state.emploi
	)
	const { user } = useSelector((state) => state.auth)

	useEffect(() => {
		socket = io(ENDPOINT)
		// if (user) {
		socket.emit('setup', user)
		socket.on('connected', () => {})
		// }
	}, [user])

	const onClick = () => {
		if (diplomeId) {
			dispatch(deleteCandidatDiplomeAction(candidatId, diplomeId))
		} else if (stageId) {
			dispatch(deleteCandidatStageAction(candidatId, stageId))
		} else if (experienceId) {
			dispatch(deleteCandidatExperienceAction(candidatId, experienceId))
		} else if (langueId) {
			dispatch(deleteCandidatLangueAction(candidatId, langueId))
		} else if (titre) {
			dispatch(deleteCandidatTitreAction(candidatId))
		} else if (profilePhoto && candidatId) {
			dispatch(deleteCandidatProfilePhotoAction(candidatId))
		} else if (profilePhoto && recruteurId) {
			dispatch(deleteRecruteurProfilePhotoAction(recruteurId))
		} else if (emploiId) {
			dispatch(deleteEmploiAction(emploiId))
		} else if (candidatIdAdmin) {
			dispatch(deleteCandidatAction(candidatIdAdmin))
		} else if (recruteurIdAdmin) {
			dispatch(deleteRecruteurAction(recruteurIdAdmin))
		} else if (emploiIdAdmin) {
			dispatch(deleteEmploiAction(emploiIdAdmin))
			// dispatch(
			// 	addNotificationEmploiDeleteAction(
			// 		emploi.recruteur_id,
			// 		"Votre offre d'emploi a été supprimé",
			// 		empId,
			// 		socket
			// 	)
			// )
		} else if (entreprise) {
			dispatch(deleteRecruteurEntrepriseAction(recruteurId))
		} else {
			dispatch(deleteCandidatCompetencesAction(candidatId, index))
		}
	}

	useEffect(() => {
		if (
			(successCandidat && messageCandidat) ||
			(successRecruteur && messageRecruteur) ||
			(success && message)
		) {
			handleClose()
		}
	}, [
		successCandidat,
		messageCandidat,
		successRecruteur,
		messageRecruteur,
		success,
		message,
	])

	return (
		<>
			{index >= 0 ? (
				<Button className='ms-2 rounded-circle btn-del' onClick={handleShow}>
					x
				</Button>
			) : profilePhoto ? (
				<Button variant='danger' onClick={handleShow}>
					Supprimer
				</Button>
			) : candidatIdAdmin ? (
				<Button variant='danger' onClick={handleShow}>
					Supprimer ce compte
				</Button>
			) : recruteurIdAdmin ? (
				<Button variant='danger' onClick={handleShow}>
					Supprimer ce compte
				</Button>
			) : emploiIdAdmin ? (
				<Button variant='danger' onClick={handleShow}>
					Supprimer cette offre d'emploi
				</Button>
			) : (
				<Button
					className='rounded-circle m-0 ms-1'
					variant='danger'
					onClick={handleShow}
				>
					<FaTrash />
				</Button>
			)}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Confirmation</Modal.Title>
				</Modal.Header>
				<Modal.Body>Voulez-vous vraiment supprimer ?</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleClose}>
						Annuler
					</Button>
					<Button
						onClick={onClick}
						disabled={(loadingCandidat || loadingRecruteur || loading) && true}
					>
						Confirmer{' '}
						{(loadingCandidat || loadingRecruteur || loading) && (
							<Loading sm='sm' />
						)}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default Delete
