import { Container } from 'react-bootstrap'

function NoMatchPage() {
	return (
		<Container>
			<p className='lead mt-3'>Page non trouvée</p>
		</Container>
	)
}

export default NoMatchPage
