import { useEffect } from 'react'
import { Card, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import 'moment/locale/fr'

import { removeNotificationAction } from '../actions/notificationActions'

function Candidature({ emploi }) {
	const { user } = useSelector((state) => state.auth)
	const { notifications } = useSelector((state) => state.notification)

	const dispatch = useDispatch()

	useEffect(() => {
		if (user) {
			notifications.forEach((n) => {
				if (n.emploi === emploi._id) {
					dispatch(removeNotificationAction(user._id, n._id))
				}
			})
		}
	}, [dispatch, notifications, emploi, user])

	return (
		<Card className='my-4'>
			<Card.Body>
				<Card.Title>
					<Link to={`/emploi/${emploi._id}`} className='link'>
						{emploi.titre}
					</Link>
				</Card.Title>
				<Card.Subtitle className='mb-2 text-muted'>
					{moment(emploi.createdAt).startOf('minute').fromNow()}
				</Card.Subtitle>
				{emploi.candidatures.map(
					(candidature) =>
						candidature.candidat_id === user._id && (
							<div className='border p-2' key={candidature._id}>
								<h6>Votre candidature</h6>
								<p className='small-fs text-muted mb-2'>
									{moment(candidature.createdAt).startOf('minute').fromNow()}
								</p>
								<p>{candidature.lettre_motivation}</p>
								{candidature.etat === 'Accepté' && (
									<Alert variant='success' className='mt-2'>
										Votre candidature a été accepté.
									</Alert>
								)}
								{candidature.etat === 'Refusé' && (
									<Alert variant='danger' className='mt-2'>
										Votre candidature a été refusé.
									</Alert>
								)}
							</div>
						)
				)}
			</Card.Body>
		</Card>
	)
}

export default Candidature
