import { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { FaPen, FaCamera } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'

import { addCandidatProfilePhotoAction } from '../actions/candidatActions'
import { addRecruteurProfilePhotoAction } from '../actions/recruteurActions'
import Delete from './Delete'
import Loading from '../components/Loading'
import { userUpdateInfoAction } from '../actions/authActions'

function AddDiplome({ candidatId, recruteurId }) {
	const [image, setImage] = useState('')
	const [imagePreview, setImagePreview] = useState('')
	const [selectedImage, setSelectedImage] = useState('')
	const [show, setShow] = useState(false)

	const handleClose = () => {
		setImage('')
		setImagePreview('')
		setSelectedImage('')
		setShow(false)
	}
	const handleShow = () => setShow(true)

	const dispatch = useDispatch()

	const {
		success: successCandidat,
		loading: loadingCandidat,
		message: messageCandidat,
		nom: nomCandidat,
		prenom: prenomCandidat,
		profilePhoto: profilePhotoCandidat,
	} = useSelector((state) => state.candidat)

	const {
		success: successRecruteur,
		loading: loadingRecruteur,
		message: messageRecruteur,
		nom: nomRecruteur,
		prenom: prenomRecruteur,
		profilePhoto: profilePhotoRecruteur,
	} = useSelector((state) => state.recruteur)

	const onChange = (e) => {
		setImage(e.target.value)

		const photo = e.target.files[0]
		previewImage(photo)
		setSelectedImage(photo)
	}

	const previewImage = (photo) => {
		const reader = new FileReader()
		reader.readAsDataURL(photo)
		reader.onloadend = () => {
			setImagePreview(reader.result)
		}
	}

	const onClick = () => {
		if (!selectedImage) return
		const formData = new FormData()
		formData.append('profilePhoto', selectedImage)
		if (candidatId) {
			dispatch(addCandidatProfilePhotoAction(candidatId, formData))
		} else {
			dispatch(addRecruteurProfilePhotoAction(recruteurId, formData))
		}
	}

	useEffect(() => {
		if (successCandidat && messageCandidat) {
			handleClose()
			dispatch(
				userUpdateInfoAction(nomCandidat, prenomCandidat, profilePhotoCandidat)
			)
		}
		if (successRecruteur && messageRecruteur) {
			handleClose()
			dispatch(
				userUpdateInfoAction(
					nomRecruteur,
					prenomRecruteur,
					profilePhotoRecruteur
				)
			)
		}
	}, [
		dispatch,
		successCandidat,
		messageCandidat,
		nomCandidat,
		prenomCandidat,
		profilePhotoCandidat,
		successRecruteur,
		messageRecruteur,
		nomRecruteur,
		prenomRecruteur,
		profilePhotoRecruteur,
	])

	return (
		<>
			<Button className='rounded-circle m-0 ms-1' onClick={handleShow}>
				<FaPen />
			</Button>{' '}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Ajouter la photo de profil</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/* <Form.Group className='mb-3'>
						<Form.Control
							type='file'
							id='image'
							name='image'
							value={image}
							onChange={onChange}
						/>
					</Form.Group> */}
					{/* {imagePreview && (
						<div className='profile-photo text-center'>
							<img src={imagePreview} alt='chosen' className='photo' />
						</div>
					)} */}

					<div className='position-relative profile-photo'>
						{imagePreview ? (
							<div className='profile-photo text-center'>
								<img src={imagePreview} alt='chosen' className='photo' />
							</div>
						) : (
							<div className='profile-photo text-center'>
								<img
									src={
										profilePhotoCandidat
											? profilePhotoCandidat
											: profilePhotoRecruteur
									}
									alt='chosen'
									className='position-relative photo'
								/>
							</div>
						)}
						<div className='position-absolute translate-middle photo2'>
							<Form.Group className='mb-3'>
								<Form.Label htmlFor='image'>
									<div className='rounded-circle btn icon' type='button'>
										<FaCamera />
									</div>
								</Form.Label>
								<Form.Control
									type='file'
									id='image'
									name='image'
									value={image}
									onChange={onChange}
									className='d-none'
								/>
							</Form.Group>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{profilePhotoCandidat &&
					profilePhotoCandidat !==
						'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/271deea8-e28c-41a3-aaf5-2913f5f48be6/de7834s-6515bd40-8b2c-4dc6-a843-5ac1a95a8b55.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzI3MWRlZWE4LWUyOGMtNDFhMy1hYWY1LTI5MTNmNWY0OGJlNlwvZGU3ODM0cy02NTE1YmQ0MC04YjJjLTRkYzYtYTg0My01YWMxYTk1YThiNTUuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.BopkDn1ptIwbmcKHdAOlYHyAOOACXW0Zfgbs0-6BY-E' ? (
						<Delete profilePhoto={profilePhotoCandidat} />
					) : profilePhotoRecruteur &&
					  profilePhotoRecruteur !==
							'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/271deea8-e28c-41a3-aaf5-2913f5f48be6/de7834s-6515bd40-8b2c-4dc6-a843-5ac1a95a8b55.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzI3MWRlZWE4LWUyOGMtNDFhMy1hYWY1LTI5MTNmNWY0OGJlNlwvZGU3ODM0cy02NTE1YmQ0MC04YjJjLTRkYzYtYTg0My01YWMxYTk1YThiNTUuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.BopkDn1ptIwbmcKHdAOlYHyAOOACXW0Zfgbs0-6BY-E' ? (
						<Delete profilePhoto={profilePhotoRecruteur} />
					) : (
						''
					)}
					<Button
						type='submit'
						onClick={onClick}
						disabled={(loadingCandidat || loadingRecruteur) && true}
					>
						Enregistrer{' '}
						{(loadingCandidat || loadingRecruteur) && <Loading sm='sm' />}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default AddDiplome
