export const CANDIDAT_DATA_REQUEST = 'CANDIDAT_DATA_REQUEST'
export const CANDIDAT_DATA_SUCCESS = 'CANDIDAT_DATA_SUCCESS'
export const CANDIDAT_DATA_FAIL = 'CANDIDAT_DATA_FAIL'

export const CANDIDAT_ADD_PROFILE_PHOTO_REQUEST =
	'CANDIDAT_ADD_PROFILE_PHOTO_REQUEST'
export const CANDIDAT_ADD_PROFILE_PHOTO_SUCCESS =
	'CANDIDAT_ADD_PROFILE_PHOTO_SUCCESS'
export const CANDIDAT_ADD_PROFILE_PHOTO_FAIL = 'CANDIDAT_ADD_PROFILE_PHOTO_FAIL'

export const CANDIDAT_DELETE_PROFILE_PHOTO_REQUEST =
	'CANDIDAT_DELETE_PROFILE_PHOTO_REQUEST'
export const CANDIDAT_DELETE_PROFILE_PHOTO_SUCCESS =
	'CANDIDAT_DELETE_PROFILE_PHOTO_SUCCESS'
export const CANDIDAT_DELETE_PROFILE_PHOTO_FAIL =
	'CANDIDAT_DELETE_PROFILE_PHOTO_FAIL'

export const CANDIDAT_ADD_ADRESSE_REQUEST = 'CANDIDAT_ADD_ADRESSE_REQUEST'
export const CANDIDAT_ADD_ADRESSE_SUCCESS = 'CANDIDAT_ADD_ADRESSE_SUCCESS'
export const CANDIDAT_ADD_ADRESSE_FAIL = 'CANDIDAT_ADD_ADRESSE_FAIL'

export const CANDIDAT_ADD_TITRE_REQUEST = 'CANDIDAT_ADD_TITRE_REQUEST'
export const CANDIDAT_ADD_TITRE_SUCCESS = 'CANDIDAT_ADD_TITRE_SUCCESS'
export const CANDIDAT_ADD_TITRE_FAIL = 'CANDIDAT_ADD_TITRE_FAIL'

export const CANDIDAT_UPDATE_TITRE_REQUEST = 'CANDIDAT_UPDATE_TITRE_REQUEST'
export const CANDIDAT_UPDATE_TITRE_SUCCESS = 'CANDIDAT_UPDATE_TITRE_SUCCESS'
export const CANDIDAT_UPDATE_TITRE_FAIL = 'CANDIDAT_UPDATE_TITRE_FAIL'

export const CANDIDAT_DELETE_TITRE_REQUEST = 'CANDIDAT_DELETE_TITRE_REQUEST'
export const CANDIDAT_DELETE_TITRE_SUCCESS = 'CANDIDAT_DELETE_TITRE_SUCCESS'
export const CANDIDAT_DELETE_TITRE_FAIL = 'CANDIDAT_DELETE_TITRE_FAIL'

export const CANDIDAT_ADD_COMPETENCES_REQUEST =
	'CANDIDAT_ADD_COMPETENCES_REQUEST'
export const CANDIDAT_ADD_COMPETENCES_SUCCESS =
	'CANDIDAT_ADD_COMPETENCES_SUCCESS'
export const CANDIDAT_ADD_COMPETENCES_FAIL = 'CANDIDAT_ADD_COMPETENCES_FAIL'

export const CANDIDAT_DELETE_COMPETENCES_REQUEST =
	'CANDIDAT_DELETE_COMPETENCES_REQUEST'
export const CANDIDAT_DELETE_COMPETENCES_SUCCESS =
	'CANDIDAT_DELETE_COMPETENCES_SUCCESS'
export const CANDIDAT_DELETE_COMPETENCES_FAIL =
	'CANDIDAT_DELETE_COMPETENCES_FAIL'

export const CANDIDAT_ADD_DIPLOME_REQUEST = 'CANDIDAT_ADD_DIPLOME_REQUEST'
export const CANDIDAT_ADD_DIPLOME_SUCCESS = 'CANDIDAT_ADD_DIPLOME_SUCCESS'
export const CANDIDAT_ADD_DIPLOME_FAIL = 'CANDIDAT_ADD_DIPLOME_FAIL'

export const CANDIDAT_UPDATE_DIPLOME_REQUEST = 'CANDIDAT_UPDATE_DIPLOME_REQUEST'
export const CANDIDAT_UPDATE_DIPLOME_SUCCESS = 'CANDIDAT_UPDATE_DIPLOME_SUCCESS'
export const CANDIDAT_UPDATE_DIPLOME_FAIL = 'CANDIDAT_UPDATE_DIPLOME_FAIL'

export const CANDIDAT_DELETE_DIPLOME_REQUEST = 'CANDIDAT_DELETE_DIPLOME_REQUEST'
export const CANDIDAT_DELETE_DIPLOME_SUCCESS = 'CANDIDAT_DELETE_DIPLOME_SUCCESS'
export const CANDIDAT_DELETE_DIPLOME_FAIL = 'CANDIDAT_DELETE_DIPLOME_FAIL'

export const CANDIDAT_ADD_STAGE_REQUEST = 'CANDIDAT_ADD_STAGE_REQUEST'
export const CANDIDAT_ADD_STAGE_SUCCESS = 'CANDIDAT_ADD_STAGE_SUCCESS'
export const CANDIDAT_ADD_STAGE_FAIL = 'CANDIDAT_ADD_STAGE_FAIL'

export const CANDIDAT_UPDATE_STAGE_REQUEST = 'CANDIDAT_UPDATE_STAGE_REQUEST'
export const CANDIDAT_UPDATE_STAGE_SUCCESS = 'CANDIDAT_UPDATE_STAGE_SUCCESS'
export const CANDIDAT_UPDATE_STAGE_FAIL = 'CANDIDAT_UPDATE_STAGE_FAIL'

export const CANDIDAT_DELETE_STAGE_REQUEST = 'CANDIDAT_DELETE_STAGE_REQUEST'
export const CANDIDAT_DELETE_STAGE_SUCCESS = 'CANDIDAT_DELETE_STAGE_SUCCESS'
export const CANDIDAT_DELETE_STAGE_FAIL = 'CANDIDAT_DELETE_STAGE_FAIL'

export const CANDIDAT_ADD_EXPERIENCE_REQUEST = 'CANDIDAT_ADD_EXPERIENCE_REQUEST'
export const CANDIDAT_ADD_EXPERIENCE_SUCCESS = 'CANDIDAT_ADD_EXPERIENCE_SUCCESS'
export const CANDIDAT_ADD_EXPERIENCE_FAIL = 'CANDIDAT_ADD_EXPERIENCE_FAIL'

export const CANDIDAT_UPDATE_EXPERIENCE_REQUEST =
	'CANDIDAT_UPDATE_EXPERIENCE_REQUEST'
export const CANDIDAT_UPDATE_EXPERIENCE_SUCCESS =
	'CANDIDAT_UPDATE_EXPERIENCE_SUCCESS'
export const CANDIDAT_UPDATE_EXPERIENCE_FAIL = 'CANDIDAT_UPDATE_EXPERIENCE_FAIL'

export const CANDIDAT_DELETE_EXPERIENCE_REQUEST =
	'CANDIDAT_DELETE_EXPERIENCE_REQUEST'
export const CANDIDAT_DELETE_EXPERIENCE_SUCCESS =
	'CANDIDAT_DELETE_EXPERIENCE_SUCCESS'
export const CANDIDAT_DELETE_EXPERIENCE_FAIL = 'CANDIDAT_DELETE_EXPERIENCE_FAIL'

export const CANDIDAT_ADD_LANGUE_REQUEST = 'CANDIDAT_ADD_LANGUE_REQUEST'
export const CANDIDAT_ADD_LANGUE_SUCCESS = 'CANDIDAT_ADD_LANGUE_SUCCESS'
export const CANDIDAT_ADD_LANGUE_FAIL = 'CANDIDAT_ADD_LANGUE_FAIL'

export const CANDIDAT_UPDATE_LANGUE_REQUEST = 'CANDIDAT_UPDATE_LANGUE_REQUEST'
export const CANDIDAT_UPDATE_LANGUE_SUCCESS = 'CANDIDAT_UPDATE_LANGUE_SUCCESS'
export const CANDIDAT_UPDATE_LANGUE_FAIL = 'CANDIDAT_UPDATE_LANGUE_FAIL'

export const CANDIDAT_DELETE_LANGUE_REQUEST = 'CANDIDAT_DELETE_LANGUE_REQUEST'
export const CANDIDAT_DELETE_LANGUE_SUCCESS = 'CANDIDAT_DELETE_LANGUE_SUCCESS'
export const CANDIDAT_DELETE_LANGUE_FAIL = 'CANDIDAT_DELETE_LANGUE_FAIL'

export const CANDIDAT_GET_ALL_REQUEST = 'CANDIDAT_GET_ALL_REQUEST'
export const CANDIDAT_GET_ALL_SUCCESS = 'CANDIDAT_GET_ALL_SUCCESS'
export const CANDIDAT_GET_ALL_FAIL = 'CANDIDAT_GET_ALL_FAIL'

export const CANDIDAT_SAVE_EMPLOI_GET_ALL_REQUEST =
	'CANDIDAT_SAVE_EMPLOI_GET_ALL_REQUEST'
export const CANDIDAT_SAVE_EMPLOI_GET_ALL_SUCCESS =
	'CANDIDAT_SAVE_EMPLOI_GET_ALL_SUCCESS'
export const CANDIDAT_SAVE_EMPLOI_GET_ALL_FAIL =
	'CANDIDAT_SAVE_EMPLOI_GET_ALL_FAIL'

export const CANDIDAT_SAVE_EMPLOI_REQUEST = 'CANDIDAT_SAVE_EMPLOI_REQUEST'
export const CANDIDAT_SAVE_EMPLOI_SUCCESS = 'CANDIDAT_SAVE_EMPLOI_SUCCESS'
export const CANDIDAT_SAVE_EMPLOI_FAIL = 'CANDIDAT_SAVE_EMPLOI_FAIL'

export const CANDIDAT_CANDIDATURES_GET_ALL_REQUEST =
	'CANDIDAT_CANDIDATURES_GET_ALL_REQUEST'
export const CANDIDAT_CANDIDATURES_GET_ALL_SUCCESS =
	'CANDIDAT_CANDIDATURES_GET_ALL_SUCCESS'
export const CANDIDAT_CANDIDATURES_GET_ALL_FAIL =
	'CANDIDAT_CANDIDATURES_GET_ALL_FAIL'

export const CANDIDAT_DELETE_REQUEST = 'CANDIDAT_DELETE_REQUEST'
export const CANDIDAT_DELETE_SUCCESS = 'CANDIDAT_DELETE_SUCCESS'
export const CANDIDAT_DELETE_FAIL = 'CANDIDAT_DELETE_FAIL'

export const CANDIDAT_RESET = 'CANDIDAT_RESET'
