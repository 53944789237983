import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import WelcomeMessage from '../components/WelcomeMessage'
import Candidat from '../components/Candidat'
import Switch from '../components/Switch'
import Search from '../components/Search'
import Loading from '../components/Loading'
import PaginationComponent from '../components/PaginationComponent'
import RightSide from '../components/RightSide'
import { getCandidatAllAction } from '../actions/candidatActions'
import { CANDIDAT_RESET } from '../constants/candidatConstants'

function CandidatsPage() {
	const { page = 1, name } = useParams()

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const {
		candidats,
		success,
		error,
		loading,
		message,
		pagesCount,
		currentPage,
		itemsPerPage,
	} = useSelector((state) => state.candidat)
	const { user } = useSelector((state) => state.auth)

	const searchCandidats = (value) => {
		if (value !== '') {
			navigate(`/dashboard/candidats/name/${value}`)
		} else {
			navigate('/dashboard/candidats')
			dispatch(getCandidatAllAction(page))
		}
	}

	useEffect(() => {
		if (name !== undefined) {
			dispatch(getCandidatAllAction(page, name))
		} else {
			dispatch(getCandidatAllAction(page))
		}
	}, [dispatch, page, name])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
			navigate('/dashboard/candidats')
		}
		if (success && message) {
			toast.success(message)
		}
		if (success || error) {
			dispatch({ type: CANDIDAT_RESET })
		}
	}, [dispatch, navigate, success, error, message])

	// if (loading) {
	// 	return <Loading />
	// }

	return (
		<Container>
			<div className={user ? 'row' : 'w-75 mx-auto width-100'}>
				{user && <RightSide user={user} />}

				<div className={user ? 'col-md-9 order-md-first mt-3' : ''}>
					{user && <WelcomeMessage user={user} />}
					{user && <Switch candidats='candidats' />}
					<Search searchCandidats={searchCandidats} />
					{loading && <Loading />}

					<h5>Candidats:</h5>
					{candidats.map((candidat) => (
						<Candidat key={candidat._id} candidat={candidat} />
					))}
				</div>
				<PaginationComponent
					pagesCount={pagesCount}
					currentPage={currentPage}
					itemsPerPage={itemsPerPage}
					name={name}
					dashboardCandidats='dashboardCandidats'
				/>
			</div>
		</Container>
	)
}

export default CandidatsPage
