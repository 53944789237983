import { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { FaPen } from 'react-icons/fa'
import { BsPlusLg } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
// import moment from 'moment'
// import 'moment/locale/fr'

import { addCandidatCompetencesAction } from '../actions/candidatActions'
import Loading from '../components/Loading'

function AddCompetences({ competences }) {
	const { candidatId } = useParams()

	const competences2 = competences !== undefined ? competences.join(', ') : ''

	const [competences3, setCompetences3] = useState(competences2)
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const dispatch = useDispatch()

	const { success, loading, message } = useSelector((state) => state.candidat)

	const onChange = (e) => {
		setCompetences3(e.target.value)
	}

	const onClick = () => {
		dispatch(addCandidatCompetencesAction(candidatId, competences3))
	}

	useEffect(() => {
		if (success && message) {
			handleClose()
		}
	}, [success, message])

	return (
		<>
			<Button className='rounded-circle m-0 ms-1' onClick={handleShow}>
				{competences2 !== '' ? <FaPen /> : <BsPlusLg />}
			</Button>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Ajouter des compétences</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<small className='text-muted'>
							Les compétences doivent être séparées par une virgule espace ", "
						</small>
						<Form.Control
							as='textarea'
							placeholder='Compétences'
							rows={10}
							name='competences'
							value={competences3}
							onChange={onChange}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleClose}>
						Annuler
					</Button>
					<Button onClick={onClick} disabled={loading && true}>
						Enregistrer {loading && <Loading sm='sm' />}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default AddCompetences
