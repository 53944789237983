import { useState, useEffect } from 'react'
import { Button, Form, Modal, Collapse, InputGroup } from 'react-bootstrap'
import {
	FaPen,
	FaFacebook,
	FaTwitter,
	FaInstagram,
	FaLinkedin,
	FaYoutube,
	FaGithub,
} from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { addCandidatAdresseAction } from '../actions/candidatActions'
import { addRecruteurAdresseAction } from '../actions/recruteurActions'
import Loading from '../components/Loading'

function AddAdresse({ nom, prenom, adresse, socialMedia }) {
	const { candidatId, recruteurId } = useParams()

	const [values, setValues] = useState({
		nom,
		prenom,
		adresse: adresse ? adresse : '',
		facebook: socialMedia.facebook ? socialMedia.facebook : '',
		twitter: socialMedia.twitter ? socialMedia.twitter : '',
		instagram: socialMedia.instagram ? socialMedia.instagram : '',
		linkedin: socialMedia.linkedin ? socialMedia.linkedin : '',
		youtube: socialMedia.youtube ? socialMedia.youtube : '',
		github: socialMedia.github ? socialMedia.github : '',
	})
	const [show, setShow] = useState(false)
	const [showLinks, setShowLinks] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const dispatch = useDispatch()

	const {
		success: successCandidat,
		loading: loadingCandidat,
		message: messageCandidat,
	} = useSelector((state) => state.candidat)
	const {
		success: successRecruteur,
		loading: loadingRecruteur,
		message: messageRecruteur,
	} = useSelector((state) => state.recruteur)

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const onClick = () => {
		const Ladresse = {
			nom: values.nom,
			prenom: values.prenom,
			adresse: values.adresse,
			facebook: values.facebook,
			twitter: values.twitter,
			instagram: values.instagram,
			linkedin: values.linkedin,
			youtube: values.youtube,
			github: values.github,
		}
		if (candidatId) {
			dispatch(addCandidatAdresseAction(candidatId, Ladresse))
		} else {
			dispatch(addRecruteurAdresseAction(recruteurId, Ladresse))
		}
	}

	useEffect(() => {
		if (
			(successCandidat && messageCandidat) ||
			(successRecruteur && messageRecruteur)
		) {
			handleClose()
		}
	}, [successCandidat, messageCandidat, successRecruteur, messageRecruteur])

	return (
		<>
			<Button className='rounded-circle m-0 ms-1' onClick={handleShow}>
				<FaPen />
			</Button>{' '}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Vos informations personnelles:</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Prénom'
							name='prenom'
							value={values.prenom}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Nom'
							name='nom'
							value={values.nom}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Control
							type='text'
							placeholder='Adresse'
							name='adresse'
							value={values.adresse}
							onChange={onChange}
						/>
					</Form.Group>
					<Button
						className='mb-3'
						variant='secondary'
						onClick={() => setShowLinks(!showLinks)}
						aria-controls='collapse-links'
						aria-expanded={showLinks}
					>
						Ajouter vos réseaux sociaux
					</Button>
					<Collapse in={showLinks}>
						<div id='collapse-links'>
							<InputGroup className='mb-3'>
								<Button className='facebook'>
									<FaFacebook size={28} />
								</Button>
								<Form.Control
									type='url'
									placeholder='Facebook'
									name='facebook'
									value={values.facebook}
									onChange={onChange}
								/>
							</InputGroup>
							<InputGroup className='mb-3'>
								<Button className='twitter'>
									<FaTwitter size={28} />
								</Button>
								<Form.Control
									type='url'
									placeholder='Twitter'
									name='twitter'
									value={values.twitter}
									onChange={onChange}
								/>
							</InputGroup>
							<InputGroup className='mb-3'>
								<Button className='instagram'>
									<FaInstagram size={28} />
								</Button>
								<Form.Control
									type='url'
									placeholder='Instagram'
									name='instagram'
									value={values.instagram}
									onChange={onChange}
								/>
							</InputGroup>
							<InputGroup className='mb-3'>
								<Button className='linkedin'>
									<FaLinkedin size={28} />
								</Button>
								<Form.Control
									type='url'
									placeholder='Linkedin'
									name='linkedin'
									value={values.linkedin}
									onChange={onChange}
								/>
							</InputGroup>
							<InputGroup className='mb-3'>
								<Button className='youtube'>
									<FaYoutube size={28} />
								</Button>
								<Form.Control
									type='url'
									placeholder='Youtube'
									name='youtube'
									value={values.youtube}
									onChange={onChange}
								/>
							</InputGroup>
							<InputGroup className='mb-3'>
								<Button className='github'>
									<FaGithub size={28} />
								</Button>
								<Form.Control
									type='url'
									placeholder='Github'
									name='github'
									value={values.github}
									onChange={onChange}
								/>
							</InputGroup>
						</div>
					</Collapse>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleClose}>
						Annuler
					</Button>
					<Button
						type='submit'
						onClick={onClick}
						disabled={(loadingCandidat || loadingRecruteur) && true}
					>
						Enregistrer{' '}
						{(loadingCandidat || loadingRecruteur) && <Loading sm='sm' />}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default AddAdresse
