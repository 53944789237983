import {
	CANDIDAT_DATA_REQUEST,
	CANDIDAT_DATA_SUCCESS,
	CANDIDAT_DATA_FAIL,
	CANDIDAT_ADD_PROFILE_PHOTO_REQUEST,
	CANDIDAT_ADD_PROFILE_PHOTO_SUCCESS,
	CANDIDAT_ADD_PROFILE_PHOTO_FAIL,
	CANDIDAT_DELETE_PROFILE_PHOTO_REQUEST,
	CANDIDAT_DELETE_PROFILE_PHOTO_SUCCESS,
	CANDIDAT_DELETE_PROFILE_PHOTO_FAIL,
	CANDIDAT_ADD_ADRESSE_REQUEST,
	CANDIDAT_ADD_ADRESSE_SUCCESS,
	CANDIDAT_ADD_ADRESSE_FAIL,
	CANDIDAT_ADD_TITRE_REQUEST,
	CANDIDAT_ADD_TITRE_SUCCESS,
	CANDIDAT_ADD_TITRE_FAIL,
	CANDIDAT_UPDATE_TITRE_REQUEST,
	CANDIDAT_UPDATE_TITRE_SUCCESS,
	CANDIDAT_UPDATE_TITRE_FAIL,
	CANDIDAT_DELETE_TITRE_REQUEST,
	CANDIDAT_DELETE_TITRE_SUCCESS,
	CANDIDAT_DELETE_TITRE_FAIL,
	CANDIDAT_ADD_COMPETENCES_REQUEST,
	CANDIDAT_ADD_COMPETENCES_SUCCESS,
	CANDIDAT_ADD_COMPETENCES_FAIL,
	CANDIDAT_DELETE_COMPETENCES_REQUEST,
	CANDIDAT_DELETE_COMPETENCES_SUCCESS,
	CANDIDAT_DELETE_COMPETENCES_FAIL,
	CANDIDAT_ADD_DIPLOME_REQUEST,
	CANDIDAT_ADD_DIPLOME_SUCCESS,
	CANDIDAT_ADD_DIPLOME_FAIL,
	CANDIDAT_UPDATE_DIPLOME_REQUEST,
	CANDIDAT_UPDATE_DIPLOME_SUCCESS,
	CANDIDAT_UPDATE_DIPLOME_FAIL,
	CANDIDAT_DELETE_DIPLOME_REQUEST,
	CANDIDAT_DELETE_DIPLOME_SUCCESS,
	CANDIDAT_DELETE_DIPLOME_FAIL,
	CANDIDAT_ADD_STAGE_REQUEST,
	CANDIDAT_ADD_STAGE_SUCCESS,
	CANDIDAT_ADD_STAGE_FAIL,
	CANDIDAT_UPDATE_STAGE_REQUEST,
	CANDIDAT_UPDATE_STAGE_SUCCESS,
	CANDIDAT_UPDATE_STAGE_FAIL,
	CANDIDAT_DELETE_STAGE_REQUEST,
	CANDIDAT_DELETE_STAGE_SUCCESS,
	CANDIDAT_DELETE_STAGE_FAIL,
	CANDIDAT_ADD_EXPERIENCE_REQUEST,
	CANDIDAT_ADD_EXPERIENCE_SUCCESS,
	CANDIDAT_ADD_EXPERIENCE_FAIL,
	CANDIDAT_UPDATE_EXPERIENCE_REQUEST,
	CANDIDAT_UPDATE_EXPERIENCE_SUCCESS,
	CANDIDAT_UPDATE_EXPERIENCE_FAIL,
	CANDIDAT_DELETE_EXPERIENCE_REQUEST,
	CANDIDAT_DELETE_EXPERIENCE_SUCCESS,
	CANDIDAT_DELETE_EXPERIENCE_FAIL,
	CANDIDAT_ADD_LANGUE_REQUEST,
	CANDIDAT_ADD_LANGUE_SUCCESS,
	CANDIDAT_ADD_LANGUE_FAIL,
	CANDIDAT_UPDATE_LANGUE_REQUEST,
	CANDIDAT_UPDATE_LANGUE_SUCCESS,
	CANDIDAT_UPDATE_LANGUE_FAIL,
	CANDIDAT_DELETE_LANGUE_REQUEST,
	CANDIDAT_DELETE_LANGUE_SUCCESS,
	CANDIDAT_DELETE_LANGUE_FAIL,
	CANDIDAT_GET_ALL_REQUEST,
	CANDIDAT_GET_ALL_SUCCESS,
	CANDIDAT_GET_ALL_FAIL,
	CANDIDAT_SAVE_EMPLOI_GET_ALL_REQUEST,
	CANDIDAT_SAVE_EMPLOI_GET_ALL_SUCCESS,
	CANDIDAT_SAVE_EMPLOI_GET_ALL_FAIL,
	CANDIDAT_SAVE_EMPLOI_REQUEST,
	CANDIDAT_SAVE_EMPLOI_SUCCESS,
	CANDIDAT_SAVE_EMPLOI_FAIL,
	CANDIDAT_CANDIDATURES_GET_ALL_REQUEST,
	CANDIDAT_CANDIDATURES_GET_ALL_SUCCESS,
	CANDIDAT_CANDIDATURES_GET_ALL_FAIL,
	CANDIDAT_DELETE_REQUEST,
	CANDIDAT_DELETE_SUCCESS,
	CANDIDAT_DELETE_FAIL,
	CANDIDAT_RESET,
} from '../constants/candidatConstants'

export const candidatReducer = (
	state = {
		_id: '',
		nom: '',
		prenom: '',
		profilePhoto: '',
		adresse: '',
		socialMedia: {},
		titre: '',
		description: '',
		competences: [],
		diplomes: [],
		stages: [],
		experiences: [],
		langues: [],
		candidats: [],
		emplois_saved: [],
		emplois: [],
		messages: [],
	},
	action
) => {
	switch (action.type) {
		case CANDIDAT_DATA_REQUEST:
			return {
				...state,
				loadingData: true,
			}
		case CANDIDAT_DATA_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loadingData: false,
				_id: action.payload._id,
				nom: action.payload.nom,
				prenom: action.payload.prenom,
				profilePhoto: action.payload.profilePhoto,
				adresse: action.payload.adresse,
				socialMedia: action.payload.socialMedia,
				titre: action.payload.titre,
				description: action.payload.description,
				competences: action.payload.competences,
				diplomes: action.payload.diplomes,
				stages: action.payload.stages,
				experiences: action.payload.experiences,
				langues: action.payload.langues,
			}
		case CANDIDAT_DATA_FAIL:
			return {
				...state,
				success: false,
				error: true,
				loadingData: false,
				message: action.payload,
			}
		case CANDIDAT_ADD_PROFILE_PHOTO_REQUEST:
		case CANDIDAT_DELETE_PROFILE_PHOTO_REQUEST:
		case CANDIDAT_ADD_ADRESSE_REQUEST:
		case CANDIDAT_ADD_TITRE_REQUEST:
		case CANDIDAT_UPDATE_TITRE_REQUEST:
		case CANDIDAT_DELETE_TITRE_REQUEST:
		case CANDIDAT_ADD_COMPETENCES_REQUEST:
		case CANDIDAT_DELETE_COMPETENCES_REQUEST:
		case CANDIDAT_ADD_DIPLOME_REQUEST:
		case CANDIDAT_UPDATE_DIPLOME_REQUEST:
		case CANDIDAT_DELETE_DIPLOME_REQUEST:
		case CANDIDAT_ADD_STAGE_REQUEST:
		case CANDIDAT_UPDATE_STAGE_REQUEST:
		case CANDIDAT_DELETE_STAGE_REQUEST:
		case CANDIDAT_ADD_EXPERIENCE_REQUEST:
		case CANDIDAT_UPDATE_EXPERIENCE_REQUEST:
		case CANDIDAT_DELETE_EXPERIENCE_REQUEST:
		case CANDIDAT_ADD_LANGUE_REQUEST:
		case CANDIDAT_UPDATE_LANGUE_REQUEST:
		case CANDIDAT_DELETE_LANGUE_REQUEST:
		case CANDIDAT_GET_ALL_REQUEST:
		case CANDIDAT_CANDIDATURES_GET_ALL_REQUEST:
		case CANDIDAT_SAVE_EMPLOI_GET_ALL_REQUEST:
		case CANDIDAT_SAVE_EMPLOI_REQUEST:
		case CANDIDAT_DELETE_REQUEST:
			return {
				...state,
				loading: true,
			}
		case CANDIDAT_ADD_PROFILE_PHOTO_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				profilePhoto: action.payload.profilePhoto,
			}
		case CANDIDAT_ADD_PROFILE_PHOTO_FAIL:
		case CANDIDAT_DELETE_PROFILE_PHOTO_FAIL:
		case CANDIDAT_ADD_ADRESSE_FAIL:
		case CANDIDAT_ADD_TITRE_FAIL:
		case CANDIDAT_UPDATE_TITRE_FAIL:
		case CANDIDAT_DELETE_TITRE_FAIL:
		case CANDIDAT_ADD_COMPETENCES_FAIL:
		case CANDIDAT_DELETE_COMPETENCES_FAIL:
		case CANDIDAT_ADD_DIPLOME_FAIL:
		case CANDIDAT_UPDATE_DIPLOME_FAIL:
		case CANDIDAT_DELETE_DIPLOME_FAIL:
		case CANDIDAT_ADD_STAGE_FAIL:
		case CANDIDAT_UPDATE_STAGE_FAIL:
		case CANDIDAT_DELETE_STAGE_FAIL:
		case CANDIDAT_ADD_EXPERIENCE_FAIL:
		case CANDIDAT_UPDATE_EXPERIENCE_FAIL:
		case CANDIDAT_DELETE_EXPERIENCE_FAIL:
		case CANDIDAT_ADD_LANGUE_FAIL:
		case CANDIDAT_UPDATE_LANGUE_FAIL:
		case CANDIDAT_DELETE_LANGUE_FAIL:
		case CANDIDAT_GET_ALL_FAIL:
		case CANDIDAT_CANDIDATURES_GET_ALL_FAIL:
		case CANDIDAT_SAVE_EMPLOI_GET_ALL_FAIL:
		case CANDIDAT_SAVE_EMPLOI_FAIL:
		case CANDIDAT_DELETE_FAIL:
			return {
				...state,
				success: false,
				error: true,
				loading: false,
				message: action.payload,
			}
		case CANDIDAT_DELETE_PROFILE_PHOTO_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				profilePhoto: action.payload.profilePhoto,
			}
		case CANDIDAT_ADD_ADRESSE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				nom: action.payload.nom,
				prenom: action.payload.prenom,
				adresse: action.payload.adresse,
				socialMedia: action.payload.socialMedia,
			}
		case CANDIDAT_ADD_TITRE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				titre: action.payload.titre,
				description: action.payload.description,
			}
		case CANDIDAT_UPDATE_TITRE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				titre: action.payload.titre,
				description: action.payload.description,
			}
		case CANDIDAT_DELETE_TITRE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				titre: '',
				description: '',
			}
		case CANDIDAT_ADD_COMPETENCES_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				competences: action.payload.competences,
			}
		case CANDIDAT_DELETE_COMPETENCES_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				competences: state.competences.filter(
					(c) => c !== state.competences[action.payload.index]
				),
			}
		case CANDIDAT_ADD_DIPLOME_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				diplomes: [action.payload.diplome, ...state.diplomes],
			}
		case CANDIDAT_UPDATE_DIPLOME_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				diplomes: action.payload.diplomes,
			}
		case CANDIDAT_DELETE_DIPLOME_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				diplomes: state.diplomes.filter(
					(diplome) => diplome._id !== action.payload._id
				),
			}
		case CANDIDAT_ADD_STAGE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				stages: [action.payload.stage, ...state.stages],
			}
		case CANDIDAT_UPDATE_STAGE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				stages: action.payload.stages,
			}
		case CANDIDAT_DELETE_STAGE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				stages: state.stages.filter(
					(stage) => stage._id !== action.payload._id
				),
			}
		case CANDIDAT_ADD_EXPERIENCE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				experiences: [action.payload.experience, ...state.experiences],
			}
		case CANDIDAT_UPDATE_EXPERIENCE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				experiences: action.payload.experiences,
			}
		case CANDIDAT_DELETE_EXPERIENCE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				experiences: state.experiences.filter(
					(experience) => experience._id !== action.payload._id
				),
			}
		case CANDIDAT_ADD_LANGUE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				langues: [action.payload.langue, ...state.langues],
			}
		case CANDIDAT_UPDATE_LANGUE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				langues: action.payload.langues,
			}
		case CANDIDAT_DELETE_LANGUE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				langues: state.langues.filter(
					(langue) => langue._id !== action.payload._id
				),
			}
		case CANDIDAT_GET_ALL_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				candidats: action.payload.candidats,
				pagesCount: action.payload.pagesCount,
				currentPage: action.payload.currentPage,
				itemsPerPage: action.payload.itemsPerPage,
			}
		case CANDIDAT_SAVE_EMPLOI_GET_ALL_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				emplois_saved: action.payload,
			}
		case CANDIDAT_SAVE_EMPLOI_SUCCESS:
			let emplois_saved
			if (action.payload.emploi) {
				emplois_saved = [action.payload.emploi, ...state.emplois_saved]
			} else {
				emplois_saved = state.emplois_saved.filter(
					(e) => e.emploi_id !== action.payload._id
				)
			}
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
				emplois_saved,
			}
		case CANDIDAT_CANDIDATURES_GET_ALL_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				emplois: action.payload,
			}
		case CANDIDAT_DELETE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: action.payload.message,
			}
		case CANDIDAT_RESET:
			return {
				...state,
				success: false,
				error: false,
				loading: false,
				loadingData: false,
				message: '',
			}
		default:
			return state
	}
}
