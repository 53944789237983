import { Card } from 'react-bootstrap'
import { GrLanguage } from 'react-icons/gr'
import { useSelector } from 'react-redux'

import Langue from './Langue'
import AddLangue from '../modals/AddLangue'

function Langues({ langues }) {
	const { user } = useSelector((state) => state.auth)
	const { _id } = useSelector((state) => state.candidat)

	if (user && user._id !== _id && langues.length === 0) return
	if (!user && langues.length === 0) return

	return (
		<>
			<div className='col-md-6'>
				<Card className='mb-3'>
					<Card.Body>
						<Card.Title>
							<GrLanguage /> Langues:{' '}
							{user && user._id === _id && <AddLangue />}
						</Card.Title>
						{langues.length > 0 &&
							langues.map((langue) => (
								<Langue key={langue._id} langue={langue} />
							))}
					</Card.Body>
				</Card>
			</div>
		</>
	)
}

export default Langues
