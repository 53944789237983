import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import WelcomeMessage from '../components/WelcomeMessage'
import Recruteur from '../components/Recruteur'
import Switch from '../components/Switch'
import Search from '../components/Search'
import Loading from '../components/Loading'
import PaginationComponent from '../components/PaginationComponent'
import RightSide from '../components/RightSide'
import { getRecruteurAllAction } from '../actions/recruteurActions'
import { RECRUTEUR_RESET } from '../constants/recruteurConstants'

function RecruteursPage() {
	const { page = 1, name } = useParams()

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const {
		recruteurs,
		success,
		error,
		loading,
		message,
		pagesCount,
		currentPage,
		itemsPerPage,
	} = useSelector((state) => state.recruteur)

	const searchRecruteurs = (value) => {
		if (value !== '') {
			navigate(`/dashboard/recruteurs/name/${value}`)
		} else {
			navigate('/dashboard/recruteurs')
			dispatch(getRecruteurAllAction(page))
		}
	}

	useEffect(() => {
		if (name !== undefined) {
			dispatch(getRecruteurAllAction(page, name))
		} else {
			dispatch(getRecruteurAllAction(page))
		}
	}, [dispatch, page, name])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
			navigate('/dashboard/recruteurs')
		}
		if (success && message) {
			toast.success(message)
		}
		if (success || error) {
			dispatch({ type: RECRUTEUR_RESET })
		}
	}, [dispatch, navigate, success, error, message])

	return (
		<Container>
			<div className='row'>
				<RightSide />

				<div className='col-md-9 order-md-first mt-3'>
					<WelcomeMessage />
					<Switch recruteurs='recruteurs' />
					<Search searchRecruteurs={searchRecruteurs} />
					{loading && <Loading />}

					<h5>Recruteurs:</h5>
					{recruteurs.map((recruteur) => (
						<Recruteur key={recruteur._id} recruteur={recruteur} />
					))}
				</div>
			</div>
			<PaginationComponent
				pagesCount={pagesCount}
				currentPage={currentPage}
				itemsPerPage={itemsPerPage}
				name={name}
				dashboardRecruteurs='dashboardRecruteurs'
			/>
		</Container>
	)
}

export default RecruteursPage
