import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import AddProfilePhoto from '../modals/AddProfilePhoto'

function ProfilePhoto({ profilePhoto, nom, prenom }) {
	const { candidatId, recruteurId } = useParams()

	const { user } = useSelector((state) => state.auth)

	return (
		<div className='position-relative profile-photo'>
			<img
				src={profilePhoto}
				alt={`${prenom} ${nom}`}
				className='position-relative photo'
			/>
			{user && (
				<div className='position-absolute top-0 end-0 translate-middle'>
				{user._id === candidatId && <AddProfilePhoto candidatId={candidatId} />}
				{user._id === recruteurId && (
					<AddProfilePhoto recruteurId={recruteurId} />
				)}
			</div>
			)}
		</div>
	)
}

export default ProfilePhoto
