import { useEffect } from 'react'
import { Container, Card, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import Loading from '../components/Loading'
import {
	getRecruteurDataAction,
	getRecruteurEmploisAction,
} from '../actions/recruteurActions'
import { RECRUTEUR_RESET } from '../constants/recruteurConstants'
import Delete from '../modals/Delete'
import ProfilePhoto from '../components/ProfilePhoto'
import Adresse from '../components/Adresse'
import Emploi from '../components/Emploi'

function AdminRecruteurPage() {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { recruteurId } = useParams()
	const {
		nom,
		prenom,
		profilePhoto,
		adresse,
		socialMedia,
		emplois: recruteurEmplois,
		success,
		error,
		loading,
		message,
	} = useSelector((state) => state.recruteur)

	useEffect(() => {
		if (recruteurId) {
			dispatch(getRecruteurDataAction(recruteurId))
			dispatch(getRecruteurEmploisAction(recruteurId))
		}
	}, [dispatch, recruteurId])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
		}
		if (success && message) {
			toast.success(message)
			navigate('/admin-dashboard/recruteurs')
		}
		if (success || error) {
			dispatch({ type: RECRUTEUR_RESET })
		}
	}, [dispatch, navigate, success, error, message])

	if (loading) {
		return <Loading />
	}

	return (
		<>
			<Container className='w-75 mx-auto width-100 mb-3'>
				<div className='bg-dark my-4 p-3 d-flex flex-column justify-content-center align-items-center position-relative'>
					<ProfilePhoto profilePhoto={profilePhoto} nom={nom} prenom={prenom} />
					<h1>{`${prenom} ${nom}`}</h1>
					<Adresse adresse={adresse} socialMedia={socialMedia} />
				</div>
				{recruteurEmplois.length > 0 &&
					recruteurEmplois.map((emploi) => (
						<Emploi key={emploi._id} emploi={emploi} />
					))}
				<Delete recruteurIdAdmin={recruteurId} />
			</Container>
		</>
	)
}

export default AdminRecruteurPage
