import axios from 'axios'
import {
	NOTIFICATION_MESSAGE_GET_REQUEST,
	NOTIFICATION_MESSAGE_GET_SUCCESS,
	NOTIFICATION_MESSAGE_GET_FAIL,
	NOTIFICATION_MESSAGE_ADD_REQUEST,
	NOTIFICATION_MESSAGE_ADD_SUCCESS,
	NOTIFICATION_MESSAGE_ADD_FAIL,
	NOTIFICATION_MESSAGE_REMOVE_REQUEST,
	NOTIFICATION_MESSAGE_REMOVE_SUCCESS,
	NOTIFICATION_MESSAGE_REMOVE_FAIL,
	NOTIFICATION_GET_REQUEST,
	NOTIFICATION_GET_SUCCESS,
	NOTIFICATION_GET_FAIL,
	NOTIFICATION_ADD_REQUEST,
	NOTIFICATION_ADD_SUCCESS,
	NOTIFICATION_ADD_FAIL,
	NOTIFICATION_REMOVE_REQUEST,
	NOTIFICATION_REMOVE_SUCCESS,
	NOTIFICATION_REMOVE_FAIL,
	NOTIFICATION_MESSAGE_ADD_TO_STORE,
	NOTIFICATION_ADD_TO_STORE,
} from '../constants/notificationConstants'

// export const addNotificationAction = (notification) => async (dispatch) => {
// 	dispatch({ type: NOTIFICATION_ADD_SUCCESS, payload: notification })
// }

// export const removeNotificationAction = (notification) => async (dispatch) => {
// 	dispatch({ type: NOTIFICATION_REMOVE_SUCCESS, payload: notification })
// }

export const getNotificationMessageAction =
	(userId) => async (dispatch, getState) => {
		dispatch({ type: NOTIFICATION_MESSAGE_GET_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.get(
				`/notifications/${userId}/notification-message`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: NOTIFICATION_MESSAGE_GET_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: NOTIFICATION_MESSAGE_GET_FAIL,
				payload: message,
			})
		}
	}

export const addNotificationMessageAction =
	(userId, text, discussionId, socket) => async (dispatch, getState) => {
		// dispatch({ type: NOTIFICATION_MESSAGE_ADD_REQUEST })
		try {
			// const {
			// 	user: { token },
			// } = getState().auth
			const { data } = await axios.post(
				`/notifications/${userId}/notification-message`,
				{ text, discussion: discussionId }
				// {
				// 	headers: {
				// 		Authorization: `Bearer ${token}`,
				// 	},
				// }
			)

			socket.emit('new message notification', data, userId)
			// if (data.message) return
			// dispatch({
			// 	type: NOTIFICATION_MESSAGE_ADD_SUCCESS,
			// 	payload: data,
			// })
		} catch (error) {
			// const message = error.response.data.message
			// 	? error.response.data.message
			// 	: error.message
			// dispatch({
			// 	type: NOTIFICATION_MESSAGE_ADD_FAIL,
			// 	payload: message,
			// })
			// console.log(error)
		}
	}

export const removeNotificationMessageAction =
	(userId, notificationMessageId) => async (dispatch, getState) => {
		dispatch({ type: NOTIFICATION_MESSAGE_REMOVE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.delete(
				`/notifications/${userId}/notification-message/${notificationMessageId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: NOTIFICATION_MESSAGE_REMOVE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: NOTIFICATION_MESSAGE_REMOVE_FAIL,
				payload: message,
			})
		}
	}

export const getNotificationAction = (userId) => async (dispatch, getState) => {
	dispatch({ type: NOTIFICATION_GET_REQUEST })
	try {
		const {
			user: { token },
		} = getState().auth

		const { data } = await axios.get(`/notifications/${userId}/notification`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		dispatch({
			type: NOTIFICATION_GET_SUCCESS,
			payload: data,
		})
	} catch (error) {
		const message = error.response.data.message
			? error.response.data.message
			: error.message
		dispatch({
			type: NOTIFICATION_GET_FAIL,
			payload: message,
		})
	}
}

export const addNotificationAction =
	(userId, text, emploiId, socket) => async (dispatch, getState) => {
		// dispatch({ type: NOTIFICATION_ADD_REQUEST })
		try {
			// const {
			// 	user: { token },
			// } = getState().auth

			const { data } = await axios.post(
				`/notifications/${userId}/notification`,
				{ text, emploi: emploiId }
				// {
				// 	headers: {
				// 		Authorization: `Bearer ${token}`,
				// 	},
				// }
			)
			if (data.message) return
			dispatch({
				type: NOTIFICATION_ADD_SUCCESS,
				payload: data,
			})
		} catch (error) {
			// const message = error.response.data.message
			// 	? error.response.data.message
			// 	: error.message
			// dispatch({
			// 	type: NOTIFICATION_ADD_FAIL,
			// 	payload: message,
			// })
			// console.log(error)
		}
	}

export const addNotificationAccepteAction =
	(userId, text, emploiId, socket) => async (dispatch, getState) => {
		try {
			const { data } = await axios.post(
				`/notifications/${userId}/notification`,
				{ text, emploi: emploiId }
			)
			socket.emit('accepter candidature', data, userId)
		} catch (error) {}
	}

export const addNotificationRefuseAction =
	(userId, text, emploiId, socket) => async (dispatch, getState) => {
		try {
			const { data } = await axios.post(
				`/notifications/${userId}/notification`,
				{ text, emploi: emploiId }
			)
			socket.emit('refuser candidature', data, userId)
		} catch (error) {}
	}

export const addNotificationPostulerAction =
	(userId, text, emploiId, socket) => async (dispatch, getState) => {
		try {
			const { data } = await axios.post(
				`/notifications/${userId}/notification`,
				{ text, emploi: emploiId }
			)
			socket.emit('new candidature', data, userId)
		} catch (error) {}
	}

export const addNotificationEmploiDeleteAction =
	(userId, text, emploiId, socket) => async (dispatch, getState) => {
		// try {
		// 	const { data } = await axios.post(
		// 		`/notifications/${userId}/notification`,
		// 		{ text, emploi: emploiId }
		// 	)
		// 	socket.emit('emploi delete', data, userId)
		// } catch (error) {}
	}

export const removeNotificationAction =
	(userId, notificationId) => async (dispatch, getState) => {
		dispatch({ type: NOTIFICATION_REMOVE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.delete(
				`/notifications/${userId}/notification/${notificationId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			dispatch({
				type: NOTIFICATION_REMOVE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: NOTIFICATION_REMOVE_FAIL,
				payload: message,
			})
		}
	}

export const addNotificationAction2 =
	(userId, text, emploiId) => async (dispatch) => {
		// dispatch({ type: NOTIFICATION_ADD_REQUEST })
		try {
			await axios.post(`/notifications/${userId}/notification`, {
				text,
				emploi: emploiId,
			})
		} catch (error) {}
	}

export const addNotificationMessageToStoreAction =
	(notification) => async (dispatch) => {
		dispatch({ type: NOTIFICATION_MESSAGE_ADD_TO_STORE, payload: notification })
	}

export const addNotificationToStoreAction =
	(notification) => async (dispatch) => {
		dispatch({ type: NOTIFICATION_ADD_TO_STORE, payload: notification })
	}
