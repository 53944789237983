import { useEffect } from 'react'
import { Container, Card, Alert } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate, Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/fr'

import Loading from '../components/Loading'
import { emploiDetailsAction } from '../actions/emploiActions'
import { EMPLOI_RESET } from '../constants/emploiConstants'
import Delete from '../modals/Delete'

function AdminEmploiPage() {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { emploiId } = useParams()
	const { emploi, success, error, loading, message } = useSelector(
		(state) => state.emploi
	)

	useEffect(() => {
		if (emploiId) {
			dispatch(emploiDetailsAction(emploiId))
		}
	}, [dispatch, emploiId])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
		}
		if (success && message) {
			toast.success(message)
			navigate('/admin-dashboard/emplois')
		}
		if (success || error) {
			dispatch({ type: EMPLOI_RESET })
		}
	}, [dispatch, navigate, success, error, message])

	if (loading) {
		return <Loading />
	}

	return (
		<Container>
			<div className='w-75 mx-auto width-100'>
				<Card className='p-3 my-3'>
					{emploi.etat !== 'disponible' && (
						<Alert variant='warning' className='mt-2'>
							Cette offre d'emploi n'est plus disponible
						</Alert>
					)}
					<div className='d-flex justify-content-between align-items-center'>
						<h4 className='mt-3'>{emploi.titre}</h4>
					</div>
					<h6 className='text-muted mb-3'>
						{moment(emploi.createdAt).startOf('minute').fromNow()}
					</h6>
					<p>{emploi.description}</p>
					<p className='small-fs mt-3'>
						Publié par{' '}
						<Link
							to={`/recruteur/${emploi.recruteur_id}`}
							className='link recruteur'
						>{`${emploi.recruteur_prenom} ${emploi.recruteur_nom}`}</Link>
					</p>
				</Card>
				<Delete emploiIdAdmin={emploiId} />
			</div>
		</Container>
	)
}

export default AdminEmploiPage
