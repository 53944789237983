import axios from 'axios'
import {
	EMPLOI_LIST_REQUEST,
	EMPLOI_LIST_SUCCESS,
	EMPLOI_LIST_FAIL,
	EMPLOI_ADD_REQUEST,
	EMPLOI_ADD_SUCCESS,
	EMPLOI_ADD_FAIL,
	EMPLOI_UPDATE_REQUEST,
	EMPLOI_UPDATE_SUCCESS,
	EMPLOI_UPDATE_FAIL,
	EMPLOI_DELETE_REQUEST,
	EMPLOI_DELETE_SUCCESS,
	EMPLOI_DELETE_FAIL,
	EMPLOI_DETAILS_REQUEST,
	EMPLOI_DETAILS_SUCCESS,
	EMPLOI_DETAILS_FAIL,
	EMPLOI_POSTULER_REQUEST,
	EMPLOI_POSTULER_SUCCESS,
	EMPLOI_POSTULER_FAIL,
	EMPLOI_CANDIDATURE_ACCEPTER_REQUEST,
	EMPLOI_CANDIDATURE_ACCEPTER_SUCCESS,
	EMPLOI_CANDIDATURE_ACCEPTER_FAIL,
	EMPLOI_CANDIDATURE_REFUSER_REQUEST,
	EMPLOI_CANDIDATURE_REFUSER_SUCCESS,
	EMPLOI_CANDIDATURE_REFUSER_FAIL,
} from '../constants/emploiConstants'

export const emploiListAction =
	(page = '', name = '') =>
	async (dispatch, getState) => {
		dispatch({ type: EMPLOI_LIST_REQUEST })
		try {
			// const {
			// 	user: { token },
			// } = getState().auth

			const url = name
				? `/emplois?page=${page}&name=${name}`
				: `/emplois?page=${page}`

			const { data } = await axios.get(
				url
				// 	{
				// 	headers: {
				// 		Authorization: `Bearer ${token}`,
				// 	},
				// }
			)
			dispatch({
				type: EMPLOI_LIST_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: EMPLOI_LIST_FAIL,
				payload: message,
			})
		}
	}

export const addEmploiAction = (emploi) => async (dispatch, getState) => {
	dispatch({ type: EMPLOI_ADD_REQUEST })
	try {
		const {
			user: { token },
		} = getState().auth

		const { data } = await axios.post('/emplois', emploi, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		dispatch({
			type: EMPLOI_ADD_SUCCESS,
			payload: data,
		})
	} catch (error) {
		const message = error.response.data.message
			? error.response.data.message
			: error.message
		dispatch({
			type: EMPLOI_ADD_FAIL,
			payload: message,
		})
	}
}

export const updateEmploiAction =
	(emploiId, emploi) => async (dispatch, getState) => {
		dispatch({ type: EMPLOI_UPDATE_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth

			const { data } = await axios.put(`/emplois/${emploiId}`, emploi, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			dispatch({
				type: EMPLOI_UPDATE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: EMPLOI_UPDATE_FAIL,
				payload: message,
			})
		}
	}

export const deleteEmploiAction = (emploiId) => async (dispatch, getState) => {
	dispatch({ type: EMPLOI_DELETE_REQUEST })
	try {
		const { token } = getState().auth?.user
			? getState().auth?.user
			: getState().admin?.admin

		const { data } = await axios.delete(`/emplois/${emploiId}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		dispatch({
			type: EMPLOI_DELETE_SUCCESS,
			payload: data,
		})
	} catch (error) {
		const message = error.response.data.message
			? error.response.data.message
			: error.message
		dispatch({
			type: EMPLOI_DELETE_FAIL,
			payload: message,
		})
	}
}

export const emploiDetailsAction = (emploiId) => async (dispatch, getState) => {
	dispatch({ type: EMPLOI_DETAILS_REQUEST })
	try {
		// const {
		// 	user: { token },
		// } = getState().auth

		const { data } = await axios.get(
			`/emplois/${emploiId}`
			// {
			// 	headers: {
			// 		Authorization: `Bearer ${token}`,
			// 	},
			// }
		)
		dispatch({
			type: EMPLOI_DETAILS_SUCCESS,
			payload: data,
		})
	} catch (error) {
		const message = error.response.data.message
			? error.response.data.message
			: error.message
		dispatch({
			type: EMPLOI_DETAILS_FAIL,
			payload: message,
		})
	}
}

// Postuler
export const postulerCandidatAction =
	(
		candidatId,
		emploiId,
		candidature
		// socket, emploiRecruteurId
	) =>
	async (dispatch, getState) => {
		dispatch({ type: EMPLOI_POSTULER_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth
			const { data } = await axios.post(
				`/candidats/${candidatId}/emplois/${emploiId}/postuler`,
				candidature,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			// socket.emit(
			// 	'new candidature',
			// 	data?.candidature,
			// 	emploiId,
			// 	emploiRecruteurId
			// )
			dispatch({
				type: EMPLOI_POSTULER_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: EMPLOI_POSTULER_FAIL,
				payload: message,
			})
		}
	}

export const accepterCandidatureAction =
	(
		recruteurId,
		emploiId,
		candidatureId
		//  socket
	) =>
	async (dispatch, getState) => {
		dispatch({ type: EMPLOI_CANDIDATURE_ACCEPTER_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth
			const { data } = await axios.post(
				`/recruteurs/${recruteurId}/emplois/${emploiId}/candidatures/${candidatureId}/accepter`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			// socket.emit('accepter candidature', data?.candidature, emploiId)
			dispatch({
				type: EMPLOI_CANDIDATURE_ACCEPTER_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: EMPLOI_CANDIDATURE_ACCEPTER_FAIL,
				payload: message,
			})
		}
	}

export const refuserCandidatureAction =
	(
		recruteurId,
		emploiId,
		candidatureId
		// socket
	) =>
	async (dispatch, getState) => {
		dispatch({ type: EMPLOI_CANDIDATURE_REFUSER_REQUEST })
		try {
			const {
				user: { token },
			} = getState().auth
			const { data } = await axios.post(
				`/recruteurs/${recruteurId}/emplois/${emploiId}/candidatures/${candidatureId}/refuser`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			// socket.emit('refuser candidature', data?.candidature, emploiId)
			dispatch({
				type: EMPLOI_CANDIDATURE_REFUSER_SUCCESS,
				payload: data,
			})
		} catch (error) {
			const message = error.response.data.message
				? error.response.data.message
				: error.message
			dispatch({
				type: EMPLOI_CANDIDATURE_REFUSER_FAIL,
				payload: message,
			})
		}
	}
