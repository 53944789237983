import { Card, Button } from 'react-bootstrap'
import { FiHeart } from 'react-icons/fi'
import { FaHeart } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/fr'

import { candidatSaveEmploiAction } from '../actions/candidatActions'

function EmploiSaved({ emploi, emplois_saved }) {
	const dispatch = useDispatch()

	const { user } = useSelector((state) => state.auth)

	const onClick = (emploiId, emploiTitre) => {
		dispatch(candidatSaveEmploiAction(user._id, emploiId, emploiTitre))
	}

	return (
		<Card className='my-4'>
			<Card.Body>
				<div className='d-flex justify-content-between align-items-center'>
					<Card.Title>
						<Link to={`/emploi/${emploi.emploi_id}`} className='link'>
							{emploi.emploi_titre}
						</Link>
					</Card.Title>
					<div className='position-absolute top-0 end-0 translate-middle'>
						{user.user === 'Candidat' ? (
							<Button
								className='rounded-circle m-0 ms-1'
								variant='secondary'
								onClick={() => onClick(emploi.emploi_id, emploi.emploi_titre)}
							>
								{emplois_saved.some(
									(e) => e.emploi_id === emploi.emploi_id
								) && <FaHeart className='save' />}
							</Button>
						) : (
							''
						)}
					</div>
				</div>
			</Card.Body>
		</Card>
	)
}

export default EmploiSaved
