import { Link } from 'react-router-dom'

function Switch({ emplois, candidats, recruteurs }) {
	return (
		<div className='register d-flex justify-content-around align-items-center text-center mt-2 fs-3'>
			<div className={emplois ? 'active' : ''}>
				<h4>
					<Link to='/dashboard'>Offres d'emplois</Link>
				</h4>
			</div>
			<div className={candidats ? 'active' : ''}>
				<h4>
					<Link to='/dashboard/candidats'>Candidats</Link>
				</h4>
			</div>
			{/* <div className={recruteurs ? 'active' : ''}>
				<h4>
					<Link to='/dashboard/recruteurs'>Recruteurs</Link>
				</h4>
			</div> */}
		</div>
	)
}

export default Switch
