import { useState, useEffect } from 'react'
import { Container, Form, Button, InputGroup, Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { MdAlternateEmail } from 'react-icons/md'
import { RiLockPasswordFill } from 'react-icons/ri'
import { useSelector, useDispatch } from 'react-redux'

import { userUpdateAction } from '../actions/authActions'
import Loading from '../components/Loading'
import { AUTH_RESET } from '../constants/authConstants'

function AccountPage() {
	const dispatch = useDispatch()

	const { user, success, error, loading, message } = useSelector(
		(state) => state.auth
	)

	const [values, setValues] = useState({
		email: user.email,
		password: '',
		passwordConfirm: '',
	})

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const onSubmit = (e) => {
		e.preventDefault()
		dispatch(
			userUpdateAction(
				user._id,
				values.email,
				values.password,
				values.passwordConfirm
			)
		)
	}

	useEffect(() => {
		if (error) {
			toast.error(message)
		}
		if (success) {
			toast.success('Succés')
			setValues({
				email: user.email,
				password: '',
				passwordConfirm: '',
			})
		}
		if (success || error) {
			dispatch({ type: AUTH_RESET })
		}
	}, [dispatch, user, success, error, message])

	return (
		<Container>
			<Card className='w-50 mx-auto width-100 p-3 mt-5'>
				<Form onSubmit={onSubmit}>
					<h3 className='mt-2'>Vos informations:</h3>
					<p className='lead mb-3'>
						Mettre à jour vos informations personnelles.
					</p>
					<InputGroup className='mb-3'>
						<Button variant='secondary'>
							<MdAlternateEmail className='icon' />
						</Button>
						<Form.Control
							type='email'
							placeholder='Adresse e-mail'
							name='email'
							value={values.email}
							onChange={onChange}
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<Button variant='secondary'>
							<RiLockPasswordFill className='icon' />
						</Button>
						<Form.Control
							type='password'
							placeholder='Nouveau mot de passe'
							name='password'
							value={values.password}
							onChange={onChange}
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<Button variant='secondary'>
							<RiLockPasswordFill className='icon' />
						</Button>
						<Form.Control
							type='password'
							placeholder='Confirmation du nouveau mot de passe'
							name='passwordConfirm'
							value={values.passwordConfirm}
							onChange={onChange}
						/>
					</InputGroup>
					<Button
						variant='primary'
						type='submit'
						className='mb-3 btn w-100'
						disabled={loading && true}
					>
						Enregister {loading && <Loading sm='sm' />}
					</Button>
				</Form>
			</Card>
		</Container>
	)
}

export default AccountPage
