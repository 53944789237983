import { combineReducers } from 'redux'

import { authReducer } from './authReducer'
import { candidatReducer } from './candidatReducer'
import { recruteurReducer } from './recruteurReducer'
import { emploiReducer } from './emploiReducer'
import { adminReducer } from './adminReducer'
import { discussionReducer } from './discussionReducer'
import { messageReducer } from './messageReducer'
import { notificationReducer } from './notificationReducer'

const rootReducer = combineReducers({
	auth: authReducer,
	candidat: candidatReducer,
	recruteur: recruteurReducer,
	emploi: emploiReducer,
	admin: adminReducer,
	discussion: discussionReducer,
	message: messageReducer,
	notification: notificationReducer,
})

export default rootReducer
