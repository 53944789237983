import { useEffect } from 'react'
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap'
import {
	FaSignInAlt,
	FaSignOutAlt,
	FaUserAlt,
	FaUserPlus,
	FaBell,
} from 'react-icons/fa'
import { MdManageAccounts } from 'react-icons/md'
import { BsMessenger, BsChatFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Effect } from 'react-notification-badge'
import NotificationBadge from 'react-notification-badge'

import brand from '../images/brand.png'
import { userSignoutAction } from '../actions/authActions'
import { adminSignoutAction } from '../actions/adminActions'
import {
	getNotificationMessageAction,
	removeNotificationMessageAction,
	getNotificationAction,
	removeNotificationAction,
} from '../actions/notificationActions'
import { NOTIFICATION_RESET } from '../constants/notificationConstants'

function Header() {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { user } = useSelector((state) => state.auth)
	const { admin } = useSelector((state) => state.admin)
	const { notifications_messages, notifications, success, error } = useSelector(
		(state) => state.notification
	)

	const signoutUser = () => {
		dispatch(userSignoutAction())
		navigate('/')
	}

	const signoutAdmin = () => {
		dispatch(adminSignoutAction())
		navigate('/admin')
	}

	useEffect(() => {
		if (user) {
			dispatch(getNotificationMessageAction(user._id))
			dispatch(getNotificationAction(user._id))
		}
	}, [dispatch, user])

	useEffect(() => {
		if (success || error) {
			dispatch({ type: NOTIFICATION_RESET })
		}
	}, [dispatch, success, error])

	return (
		<Navbar
			bg='dark'
			// {!user && !admin && expand='md'}
			variant='dark'
			sticky='top'
			style={{
				backgroundImage: 'linear-gradient(to left, #666576, #00bfa6d0)',
			}}
		>
			<Container>
				<Navbar.Brand href='/'>
					<img
						src={brand}
						alt='recrutTech'
						style={{ height: '50px', width: '50px', borderRadius: '50%' }}
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='menu-collapse' />
				<Navbar.Collapse id='menu-collapse'>
					<Nav className='ms-auto'>
						{user && !admin ? (
							<>
								<NavDropdown
									title={
										<>
											<NotificationBadge
												count={notifications.length}
												effect={Effect.SCALE}
											/>
											<Button className='rounded-circle' variant='secondary'>
												<FaBell className='p-0 m-0' />
											</Button>
										</>
									}
								>
									{notifications.length > 0 ? (
										notifications.map((n) => (
											<NavDropdown.Item
												onClick={() => {
													if (n.text === "Votre offre d'emploi a été supprimé")
														return
													navigate(`/emploi/${n.emploi}`)
													dispatch(removeNotificationAction(user._id, n._id))
												}}
												key={n._id}
											>
												{n.text}
											</NavDropdown.Item>
										))
									) : (
										<NavDropdown.Item>
											Pas de nouvelles notifications
										</NavDropdown.Item>
									)}
								</NavDropdown>

								<NavDropdown
									title={
										<>
											<NotificationBadge
												count={notifications_messages.length}
												effect={Effect.SCALE}
											/>
											<Button className='rounded-circle' variant='secondary'>
												<BsMessenger className='p-0 m-0' />
											</Button>
										</>
									}
								>
									<NavDropdown.Item href={`/user/${user._id}/discussions`}>
										<BsChatFill /> Toutes les discussions
									</NavDropdown.Item>
									<NavDropdown.Divider />
									{notifications_messages.length > 0 ? (
										notifications_messages.map((nm) => (
											<NavDropdown.Item
												onClick={() => {
													navigate(
														// `/user/${user._id}/discussions/${nm.discussion?._id}`
														`/user/${user._id}/discussions/${nm.discussion}`
													)
													// dispatch(removeNotificationMessageAction(nm))
													dispatch(
														removeNotificationMessageAction(user._id, nm._id)
													)
												}}
												key={nm._id}
											>
												{/* {`New message from ${
													getSender(user._id, nm.discussion?.users).prenom
												} ${getSender(user._id, nm.discussion?.users).nom}`} */}
												{nm.text}
											</NavDropdown.Item>
										))
									) : (
										<NavDropdown.Item>
											Pas de nouveaux messages
										</NavDropdown.Item>
									)}
								</NavDropdown>
								<NavDropdown
									title={
										<>
											<img
												className='dropdown-image me-1'
												src={user.profilePhoto}
												alt=''
											/>
											{/* {user.prenom} */}
										</>
									}
								>
									{user.user === 'Candidat' ? (
										<NavDropdown.Item href={`/candidat/${user._id}`}>
											<FaUserAlt /> Profil
										</NavDropdown.Item>
									) : (
										<NavDropdown.Item href={`/recruteur/${user._id}`}>
											<FaUserAlt /> Profil
										</NavDropdown.Item>
									)}
									<NavDropdown.Item href={`/user/${user._id}`}>
										<MdManageAccounts /> Compte
									</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item onClick={signoutUser}>
										<FaSignOutAlt /> Se déconnecter
									</NavDropdown.Item>
								</NavDropdown>
							</>
						) : !user && !admin ? (
							<>
								{/* <Nav.Link href='/dashboard'>Chercher travail</Nav.Link> */}
								{/* <Nav.Link href='/dashboard/candidats'>Trouver talent</Nav.Link> */}
								<Button
									variant='outline-secondary'
									style={{
										color: '#fff',
										borderColor: '#fff',
										margin: '0 0.5rem',
									}}
									onClick={() => navigate('/signin')}
								>
									<FaSignInAlt /> Se connecter
								</Button>
								<Button
									variant='secondary'
									onClick={() => navigate('/register')}
								>
									<FaUserPlus /> S'inscrire
								</Button>
							</>
						) : !user && admin ? (
							<Button variant='secondary' onClick={signoutAdmin}>
								<FaSignOutAlt /> Se déconnecter
							</Button>
						) : (
							''
						)}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}

export default Header
