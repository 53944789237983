import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import WelcomeMessage from '../components/WelcomeMessage'
import Loading from '../components/Loading'
import Emploi from '../components/Emploi'
import Switch from '../components/Switch'
import Search from '../components/Search'
import AddEmploi from '../modals/AddEmploi'
import PaginationComponent from '../components/PaginationComponent'
import RightSide from '../components/RightSide'
import { emploiListAction } from '../actions/emploiActions'
import { candidatGetSavedEmploiAction } from '../actions/candidatActions'
import { EMPLOI_RESET } from '../constants/emploiConstants'
import { CANDIDAT_RESET } from '../constants/candidatConstants'

function Dashboard() {
	const { page = 1, name } = useParams()

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const {
		emplois,
		success,
		error,
		loadingEmplois,
		message,
		pagesCount,
		currentPage,
		itemsPerPage,
	} = useSelector((state) => state.emploi)
	const {
		emplois_saved,
		success: successCandidat,
		error: errorCandidat,
		message: messageCandidat,
	} = useSelector((state) => state.candidat)
	const { user } = useSelector((state) => state.auth)

	const searchEmplois = (value) => {
		if (value !== '') {
			navigate(`/dashboard/name/${value}`)
		} else {
			navigate('/dashboard')
			dispatch(emploiListAction(page))
		}
	}

	useEffect(() => {
		if (name !== undefined) {
			dispatch(emploiListAction(page, name))
		} else {
			dispatch(emploiListAction(page))
		}
	}, [dispatch, page, name])

	useEffect(() => {
		if (user && user.user === 'Candidat') {
			dispatch(candidatGetSavedEmploiAction(user._id))
		}
	}, [dispatch, user])

	useEffect(() => {
		if (error && message) {
			toast.error(message)
			navigate('/dashboard')
		}
		if (success && message) {
			toast.success(message)
		}
		if (success || error) {
			dispatch({ type: EMPLOI_RESET })
		}
		if (errorCandidat && messageCandidat) {
			toast.error(messageCandidat)
		}
		if (successCandidat && messageCandidat) {
			toast.success(messageCandidat)
		}
		if (successCandidat || errorCandidat) {
			dispatch({ type: CANDIDAT_RESET })
		}
	}, [
		dispatch,
		navigate,
		success,
		error,
		message,
		successCandidat,
		errorCandidat,
		messageCandidat,
	])

	return (
		<Container>
			<div className={user ? 'row' : 'w-75 mx-auto width-100'}>
				{user && <RightSide user={user} />}

				<div className={user ? 'col-md-9 order-md-first mt-3' : ''}>
					{user && <WelcomeMessage user={user} />}
					{user && <Switch emplois='emplois' />}
					<Search searchEmplois={searchEmplois} emplois={emplois} />
					{loadingEmplois && <Loading />}

					<div className='d-flex align-items-center'>
						<h5>Offres d'emplois:</h5>{' '}
						{user && user.user === 'Recruteur' && <AddEmploi />}
					</div>
					{emplois.length > 0 &&
						emplois.map((emploi) => (
							<Emploi
								key={emploi._id}
								emploi={emploi}
								emplois_saved={emplois_saved}
							/>
						))}
				</div>

				{/* {emplois.length > 0 &&
				emplois.map((emploi) => <Emploi key={emploi._id} emploi={emploi} />)} */}

				{/* {loadingEmplois ? (
				<Loading />
			) : recherche.length > 0 ? (
				recherche.map((emploi) => <Emploi key={emploi._id} emploi={emploi} />)
			) : emplois.length > 0 ? (
				emplois.map((emploi) => <Emploi key={emploi._id} emploi={emploi} />)
			) : (
				''
			)} */}
				<PaginationComponent
					pagesCount={pagesCount}
					currentPage={currentPage}
					itemsPerPage={itemsPerPage}
					name={name}
					dashboard='dashboard'
				/>
			</div>
		</Container>
	)
}

export default Dashboard
