import {
	ADMIN_SIGNIN_REQUEST,
	ADMIN_SIGNIN_SUCCESS,
	ADMIN_SIGNIN_FAIL,
	ADMIN_CANDIDAT_COUNT_REQUEST,
	ADMIN_CANDIDAT_COUNT_SUCCESS,
	ADMIN_CANDIDAT_COUNT_FAIL,
	ADMIN_RECRUTEUR_COUNT_REQUEST,
	ADMIN_RECRUTEUR_COUNT_SUCCESS,
	ADMIN_RECRUTEUR_COUNT_FAIL,
	ADMIN_EMPLOI_COUNT_REQUEST,
	ADMIN_EMPLOI_COUNT_SUCCESS,
	ADMIN_EMPLOI_COUNT_FAIL,
	ADMIN_SIGNOUT,
	ADMIN_RESET,
} from '../constants/adminConstants'

export const adminReducer = (state = {}, action) => {
	switch (action.type) {
		case ADMIN_SIGNIN_REQUEST:
		case ADMIN_CANDIDAT_COUNT_REQUEST:
		case ADMIN_RECRUTEUR_COUNT_REQUEST:
		case ADMIN_EMPLOI_COUNT_REQUEST:
			return {
				...state,
				loading: true,
			}
		case ADMIN_SIGNIN_SUCCESS:
			return {
				...state,
				success: true,
				loading: false,
				admin: action.payload,
			}
		case ADMIN_CANDIDAT_COUNT_SUCCESS:
			return {
				...state,
				success: true,
				loading: false,
				candidats: action.payload,
			}
		case ADMIN_RECRUTEUR_COUNT_SUCCESS:
			return {
				...state,
				success: true,
				loading: false,
				recruteurs: action.payload,
			}
		case ADMIN_EMPLOI_COUNT_SUCCESS:
			return {
				...state,
				success: true,
				loading: false,
				emplois: action.payload,
			}
		case ADMIN_SIGNIN_FAIL:
		case ADMIN_CANDIDAT_COUNT_FAIL:
		case ADMIN_RECRUTEUR_COUNT_FAIL:
		case ADMIN_EMPLOI_COUNT_FAIL:
			return {
				...state,
				success: false,
				error: true,
				loading: false,
				message: action.payload,
			}
		case ADMIN_RESET:
			return {
				...state,
				success: false,
				error: false,
				loading: false,
				message: '',
			}
		case ADMIN_SIGNOUT:
			return {}
		default:
			return state
	}
}
