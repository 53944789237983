export const getReciever = (loggedUser, users) => {
	if (users[0]._id === loggedUser._id) {
		return {
			_id: users[1]._id,
			nom: users[1].nom,
			prenom: users[1].prenom,
			profilePhoto: users[1].profilePhoto,
			__t: users[1].__t,
		}
	} else {
		return {
			_id: users[0]._id,
			nom: users[0].nom,
			prenom: users[0].prenom,
			profilePhoto: users[0].profilePhoto,
			__t: users[0].__t,
		}
	}
}

export const getSender = (loggedUser, users) => {
	if (users[0]._id === loggedUser._id) {
		return {
			_id: users[0]._id,
			nom: users[0].nom,
			prenom: users[0].prenom,
			profilePhoto: users[0].profilePhoto,
			__t: users[0].__t,
		}
	} else {
		return {
			_id: users[1]._id,
			nom: users[1].nom,
			prenom: users[1].prenom,
			profilePhoto: users[1].profilePhoto,
			__t: users[1].__t,
		}
	}
}
