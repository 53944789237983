export const NOTIFICATION_MESSAGE_GET_REQUEST =
	'NOTIFICATION_MESSAGE_GET_REQUEST'
export const NOTIFICATION_MESSAGE_GET_SUCCESS =
	'NOTIFICATION_MESSAGE_GET_SUCCESS'
export const NOTIFICATION_MESSAGE_GET_FAIL = 'NOTIFICATION_MESSAGE_GET_FAIL'

export const NOTIFICATION_MESSAGE_ADD_REQUEST =
	'NOTIFICATION_MESSAGE_ADD_REQUEST'
export const NOTIFICATION_MESSAGE_ADD_SUCCESS =
	'NOTIFICATION_MESSAGE_ADD_SUCCESS'
export const NOTIFICATION_MESSAGE_ADD_FAIL = 'NOTIFICATION_MESSAGE_ADD_FAIL'

export const NOTIFICATION_MESSAGE_REMOVE_REQUEST =
	'NOTIFICATION_MESSAGE_REMOVE_REQUEST'
export const NOTIFICATION_MESSAGE_REMOVE_SUCCESS =
	'NOTIFICATION_MESSAGE_REMOVE_SUCCESS'
export const NOTIFICATION_MESSAGE_REMOVE_FAIL =
	'NOTIFICATION_MESSAGE_REMOVE_FAIL'

export const NOTIFICATION_GET_REQUEST = 'NOTIFICATION_GET_REQUEST'
export const NOTIFICATION_GET_SUCCESS = 'NOTIFICATION_GET_SUCCESS'
export const NOTIFICATION_GET_FAIL = 'NOTIFICATION_GET_FAIL'

export const NOTIFICATION_ADD_REQUEST = 'NOTIFICATION_ADD_REQUEST'
export const NOTIFICATION_ADD_SUCCESS = 'NOTIFICATION_ADD_SUCCESS'
export const NOTIFICATION_ADD_FAIL = 'NOTIFICATION_ADD_FAIL'

export const NOTIFICATION_REMOVE_REQUEST = 'NOTIFICATION_REMOVE_REQUEST'
export const NOTIFICATION_REMOVE_SUCCESS = 'NOTIFICATION_REMOVE_SUCCESS'
export const NOTIFICATION_REMOVE_FAIL = 'NOTIFICATION_REMOVE_FAIL'

export const NOTIFICATION_MESSAGE_ADD_TO_STORE =
	'NOTIFICATION_MESSAGE_ADD_TO_STORE'
export const NOTIFICATION_ADD_TO_STORE = 'NOTIFICATION_ADD_TO_STORE'

export const NOTIFICATION_RESET = 'NOTIFICATION_RESET'
