import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/fr'

import { getDiscussionsAction } from '../actions/discussionActions'
import { getReciever } from '../utils/discussion'

function Discussions({ discussionId }) {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { discussions } = useSelector((state) => state.discussion)
	const { user } = useSelector((state) => state.auth)

	// useEffect(() => {
	// 	dispatch(getDiscussionsAction())
	// }, [dispatch])

	return (
		<div className='col-md-4'>
			<div
				className='discussion bg-dark text-white'
				style={{
					height: '75vh',
					overflow: 'hidden',
					overflowY: 'auto',
					// backgroundColor: '#d9e2ef',
				}}
			>
				<div>
					<h3 className='ms-2 mt-2'>Mes discussions</h3>
					<div className='d-flex flex-column'>
						{discussions.length > 0 ? (
							discussions.map((d) => (
								<div
									style={{
										backgroundColor:
											d._id === discussionId && window.innerWidth > 768
												? '#05929c'
												: '#fff',
										color:
											d._id === discussionId &&
											window.innerWidth > 768 &&
											'#fff',
									}}
									className='p-2 m-2 d-flex align-items-center justify-content-between single-discussion'
									key={d._id}
									onClick={() =>
										navigate(`/user/${user._id}/discussions/${d._id}`)
									}
								>
									<div className='d-flex align-items-center'>
										<img
											className='dropdown-image me-2'
											src={getReciever(user, d?.users).profilePhoto}
											alt={`${getReciever(user, d?.users).prenom} ${
												getReciever(user, d?.users).nom
											}`}
										/>
										<div>
											<h5 className='m-0'>{`${
												getReciever(user, d?.users).prenom
											} ${getReciever(user, d?.users).nom}`}</h5>

											{d.latestMessage && (
												<p className='m-0'>
													{d.latestMessage.sender === user._id && (
														<span>Vous: </span>
													)}
													{d.latestMessage.text.length > 20
														? d.latestMessage.text.slice(0, 20)
														: d.latestMessage.text}
													{d.latestMessage.text.length > 20 && '...'}
												</p>
											)}
										</div>
									</div>
									<div className='d-flex flex-column'>
										<div>
											{moment(d.latestMessage.createdAt)
												.locale('fr')
												.format('LL')}
										</div>
										<div className='align-self-end'>
											{moment(d.latestMessage.createdAt).format('LT')}
										</div>
									</div>
								</div>
							))
						) : (
							<p className='lead ms-2 mt-2'>
								Vos discussions vont s'afficher ici.
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Discussions
