import { useSelector } from 'react-redux'

import Delete from '../modals/Delete'
import UpdateDiplome from '../modals/UpdateDiplome'

function Diplome({ diplome }) {
	const { user } = useSelector((state) => state.auth)
	const { _id } = useSelector((state) => state.candidat)

	return (
		<div className='dse mb-3 p-4 position-relative' key={diplome._id}>
			{/* <div className='d-flex justify-content-between align-items-center'> */}
			<div className='d-flex justify-content-between align-items-center'>
				<h5>{diplome.domaine}</h5>
				<div className='position-absolute top-0 end-0 translate-middle float-right'>
					{user && user._id === _id && (
						<>
							<UpdateDiplome diplome={diplome} />
							<Delete diplomeId={diplome._id} />
						</>
					)}
				</div>
			</div>
			{/* <p>{diplome.annee_obtention}</p> */}
			{/* </div> */}
			<p>
				<strong>Etablissement:</strong> {diplome.etablissement}
			</p>
			<p>
				<strong>Année d'obtention:</strong> {diplome.annee_obtention}
			</p>
		</div>
	)
}

export default Diplome
