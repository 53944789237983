import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'react-bootstrap'

import AddTitre from '../modals/AddTitre'
import UpdateTitre from '../modals/UpdateTitre'
import Delete from '../modals/Delete'

function Titre({ titre, description }) {
	// const [truncated, setTruncated] = useState(true)

	const { user } = useSelector((state) => state.auth)
	const { _id } = useSelector((state) => state.candidat)

	// const truncatedDescription =
	// 	description && (truncated ? description.slice(0, 250) : description)

	if (
		user &&
		user._id !== _id &&
		titre === undefined &&
		description === undefined
	)
		return
	if (!user && titre === undefined && description === undefined) return

	return (
		<>
			<Card className='mb-4'>
				<Card.Body>
					{titre && description ? (
						<>
							<div className='d-flex justify-content-between align-items-center'>
								<Card.Title>{titre}</Card.Title>
								{/* <div className='position-absolute top-0 end-0 translate-middle'> */}
								{user && user._id === _id && (
									<>
										<div className='position-absolute top-0 end-0 translate-middle float-right'>
											<UpdateTitre titre={titre} description={description} />
											<Delete titre={titre} />
										</div>
									</>
								)}
								{/* </div> */}
							</div>
							<Card.Text>
								{description}
								{/* {truncatedDescription}
								{truncated && '...'}{' '}
								<span
									onClick={() => setTruncated(!truncated)}
									className='text-muted small-text read-more'
								>
									{truncated ? 'Read more' : 'Read less'}
								</span> */}
							</Card.Text>
						</>
					) : user && user._id === _id ? (
						<div className='text-center'>
							<AddTitre />
							<p className='lead'>Ajouter votre titre d'emploi</p>
						</div>
					) : (
						''
					)}
				</Card.Body>
			</Card>
		</>
	)
}

export default Titre
