import { Card } from 'react-bootstrap'
import { GrCertificate } from 'react-icons/gr'
import { useSelector } from 'react-redux'

import Stage from './Stage'
import AddStage from '../modals/AddStage'

function Stages({ stages }) {
	const { user } = useSelector((state) => state.auth)
	const { _id } = useSelector((state) => state.candidat)

	if (user && user._id !== _id && stages.length === 0) return
	if (!user && stages.length === 0) return

	return (
		<>
			<div className='col-md-6'>
				<Card className='mb-3'>
					<Card.Body>
						<Card.Title>
							<GrCertificate /> Stages:{' '}
							{user && user._id === _id && <AddStage />}
						</Card.Title>
						{stages.length > 0 &&
							stages.map((stage) => <Stage key={stage._id} stage={stage} />)}
					</Card.Body>
				</Card>
			</div>
		</>
	)
}

export default Stages
