import { useEffect } from 'react'
import { Container, Card } from 'react-bootstrap'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FaSignInAlt } from 'react-icons/fa'
import { IoIosPaper } from 'react-icons/io'
import { BsFillCheckCircleFill } from 'react-icons/bs'
// import {
// 	Link,
// 	Button,
// 	Element,
// 	Events,
// 	animateScroll as scroll,
// 	scrollSpy,
// 	scroller,
// } from 'react-scroll'

function HomePage() {
	const navigate = useNavigate()
	const { user } = useSelector((state) => state.auth)
	const { admin } = useSelector((state) => state.admin)

	useEffect(() => {
		if (user) {
			navigate('/dashboard')
		}
		if (admin) {
			navigate('/admin-dashboard')
		}
	}, [navigate, user, admin])

	return (
		<>
			<div className='home-page'>
				<div className='overlay'>
					<div className='d-flex flex-column justify-content-center align-items-center text-center'>
						<div className='text'>
							<h1>Bienvenue sur la plateforme</h1>
							<h1>recrutTech</h1>
							{/* <Link
								to='more'
								spy={true}
								smooth={true}
								offset={-75}
								duration={500}
								className='btn btn-primary mt-2'
								style={{ fontSize: '1.3rem' }}
							>
								En savoir plus
							</Link> */}
							<Link
								to='/dashboard'
								className='btn btn-primary mt-2'
								style={{ fontSize: '1.3rem' }}
							>
								Trouver travail
							</Link>
						</div>
					</div>
				</div>
			</div>
			<Container>
				<div id='more'></div>
				<h3 className='mt-3'>C'est quoi recrutTech ?</h3>
				<p className='bg-dark lead mb-3 p-3' style={{ borderRadius: '5px' }}>
					recrutTech est une plateforme de recherche d'emploi en ligne qui joue le rôle
					d'interface entre les recruteurs et les candidats. Elle offre aux
					recruteurs un outil gratuit de publication des offres d'emplois et aux
					candidats l'opportunité de retrouver ces offres d'emplois et envoyer
					leurs candidatures plus facilement ce qui permet de rapprocher les
					candidats ainsi que les recruteurs en leurs donnant la possibilité de
					communiquer par message.
				</p>
				<h3>Comment ça marche ?</h3>
				<div className='row mb-3'>
					<div className='col-md-4 mb-2'>
						<Card className='p-2 text-center'>
							<div>
								<FaSignInAlt size={35} />
							</div>
							<h4 className='mt-2'>S'authentifier</h4>
							<p>
								{/* Se connecter à votre compte ou s'inscrire en tant que candidat
								(si vous cherchez un travail) ou recruteur (si vous cherchez un
								employé). */}
								Se connecter à votre compte ou s'inscrire.
							</p>
						</Card>
					</div>
					<div className='col-md-4 mb-2'>
						<Card className='p-2 text-center'>
							<div>
								<IoIosPaper size={35} />
							</div>
							<h4 className='mt-2'>Postulez aux offres</h4>
							<p>
								{/* Postulez aux différents offres d'emplois par envoie de votre
								candidature (lettre de motivation). */}
								Postulez par envoie de votre candidature.
							</p>
						</Card>
					</div>
					<div className='col-md-4 mb-2'>
						<Card className='p-2 text-center'>
							<div>
								<BsFillCheckCircleFill size={35} />
							</div>
							<h4 className='mt-2'>Etre recruté</h4>
							<p>
								{/* Avoir une réponse sur votre candidature (accepté ou refusé) par
								le recruteur. */}
								Avoir une réponse sur votre candidature.
							</p>
						</Card>
					</div>
				</div>
			</Container>
		</>
	)
}

export default HomePage
