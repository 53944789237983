import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { AiOutlineCheck } from 'react-icons/ai'
import { MdClose } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import io from 'socket.io-client'

import Loading from '../components/Loading'
import {
	accepterCandidatureAction,
	refuserCandidatureAction,
	postulerCandidatAction,
} from '../actions/emploiActions'
import {
	addNotificationAccepteAction,
	addNotificationRefuseAction,
	addNotificationPostulerAction,
} from '../actions/notificationActions'

// const ENDPOINT = 'http://localhost:5000'
const ENDPOINT = 'https://recruttech.herokuapp.com'
let socket

function Confirm({ values, accepter, refuser, cand }) {
	const { emploiId } = useParams()
	const [show, setShow] = useState(false)

	const dispatch = useDispatch()

	const { emploi, success, error, loading, message } = useSelector(
		(state) => state.emploi
	)
	const { user } = useSelector((state) => state.auth)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	const handleShowPostuler = () => {
		if (values?.lettre_motivation === '') {
			toast.error('Veuillez saisir la lettre de motivation')
		} else {
			setShow(true)
		}
	}

	useEffect(() => {
		socket = io(ENDPOINT)
		socket.emit('setup', user)
		socket.on('connected', () => {})
	}, [user])

	const onClick = () => {
		if (accepter) {
			dispatch(
				accepterCandidatureAction(
					user._id,
					emploi._id,
					cand._id
					//  socket
				)
			)
			// if (!window.location.pathname.split('/').includes(emploi._id)) {
			// dispatch(
			// 	addNotificationAction2(
			// 		cand.candidat_id,
			// 		'Votre candidature a été accepté',
			// 		emploi._id
			// 	)
			// )
			dispatch(
				addNotificationAccepteAction(
					cand.candidat_id,
					'Votre candidature a été accepté',
					emploi._id,
					socket
				)
			)
			// }
		} else if (refuser) {
			dispatch(
				refuserCandidatureAction(
					user._id,
					emploi._id,
					cand._id
					//  socket
				)
			)
			dispatch(
				addNotificationRefuseAction(
					cand.candidat_id,
					'Votre candidature a été refusé',
					emploi._id,
					socket
				)
			)
		} else {
			const candidature = {
				lettre_motivation: values.lettre_motivation,
			}
			dispatch(
				postulerCandidatAction(
					user._id,
					emploiId,
					candidature
					// socket,
					// emploi.recruteur_id
				)
			)
			dispatch(
				addNotificationPostulerAction(
					emploi.recruteur_id,
					`Nouvelle candidature de ${user.prenom} ${user.nom}`,
					emploi._id,
					socket
				)
			)
		}
	}

	// useEffect(() => {
	// 	if (success && message) {
	// 		handleClose()
	// 		toast.success(message)
	// 	}
	// 	if (error && message) {
	// 		handleClose()
	// 		toast.error(message)
	// 	}
	// 	if (success || error) {
	// 		dispatch({ type: EMPLOI_RESET })
	// 	}
	// }, [dispatch, success, error, message])

	return (
		<>
			{accepter ? (
				<Button
					className='me-2'
					disabled={cand.etat === 'Accepté' && true}
					onClick={handleShow}
				>
					<AiOutlineCheck /> Accepter
				</Button>
			) : refuser ? (
				<Button
					variant='danger'
					disabled={cand.etat === 'Refusé' && true}
					onClick={handleShow}
				>
					<MdClose /> Refuser
				</Button>
			) : (
				<Button onClick={handleShowPostuler} disabled={loading && true}>
					Postuler
				</Button>
			)}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Confirmation</Modal.Title>
				</Modal.Header>
				<Modal.Body>Confirmez ?</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleClose}>
						Annuler
					</Button>
					<Button onClick={onClick} disabled={loading && true}>
						Confirmer {loading && <Loading sm='sm' />}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default Confirm
