import { useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/fr'

import Delete from '../modals/Delete'
import UpdateExperience from '../modals/UpdateExperience'

function Experience({ experience }) {
	const { user } = useSelector((state) => state.auth)
	const { _id } = useSelector((state) => state.candidat)

	return (
		<div className='dse mb-4 p-4 position-relative' key={experience._id}>
			{/* <div className='d-flex justify-content-between align-items-center'> */}
			<div className='d-flex justify-content-between align-items-center'>
				<h5>{experience.job_titre}</h5>
				<div className='position-absolute top-0 end-0 translate-middle float-right'>
					{user && user._id === _id && (
						<>
							<UpdateExperience experience={experience} />
							<Delete experienceId={experience._id} />
						</>
					)}
				</div>
			</div>
			{/* </div> */}
			<p>
				<strong>Etablissement:</strong> {experience.etablissement}
			</p>
			<p>
				<strong>Durée:</strong>{' '}
				{`${moment(experience.duree.from).format('L')} - ${moment(
					experience.duree.to
				).format('L')}`}
			</p>
			{experience.description && (
				<>
					<hr />
					<p>{experience.description}</p>
				</>
			)}
		</div>
	)
}

export default Experience
