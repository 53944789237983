import { ExternalLink } from 'react-external-link'
import { Container } from 'react-bootstrap'
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa'

function Footer() {
	return (
		<footer
			className='footer mt-auto bg-dark text-light py-3'
			style={{
				backgroundImage: 'linear-gradient(to left, #666576, #00bfa6d0)',
			}}
		>
			<Container className='d-md-flex justify-content-between align-items-center'>
				<p>Tous droits réservés.</p>
				<p className='fs-4'>recrutTech &copy; 2022</p>
				<div>
					<ExternalLink
						href='https://www.facebook.com'
						className='social-links'
					>
						<FaFacebook size={28} />
					</ExternalLink>
					<ExternalLink href='https://www.twitter.com' className='social-links'>
						<FaTwitter size={28} />
					</ExternalLink>
					<ExternalLink
						href='https://www.instagram.com'
						className='social-links'
					>
						<FaInstagram size={28} />
					</ExternalLink>
					<ExternalLink
						href='https://www.linkedin.com'
						className='social-links'
					>
						<FaLinkedin size={28} />
					</ExternalLink>
				</div>
			</Container>
		</footer>
	)
}

export default Footer
